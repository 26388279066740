/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SportsCar = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M23.845 17.67a2.694 2.694 0 10-.01 5.388 2.694 2.694 0 00.01-5.388zm-3.771 3.093v1.348h-8.03v-1.348h-.014a3.812 3.812 0 000-.4c0-2.028-1.659-3.671-3.707-3.671s-3.707 1.644-3.707 3.671c.001.13-.015 1.611 0 1.741H3.181c-3.236-1.042-3.431-3.831.959-8.575 2.75-2.265 3.056-3.108 9.326-3.108s5.605 4.16 8.433 4.137c2.828-.023 7.939 2.341 9.128 3.291 1.006.803.327 1.092.293 3.596l-3.812.701-.013-1.783c.049-1.342-.647-2.603-1.813-3.289s-2.617-.685-3.783 0a3.664 3.664 0 00-1.813 3.289 3.812 3.812 0 000 .4h-.013zm3.771 1.002a1.41 1.41 0 110-2.82 1.41 1.41 0 010 2.82zM8.241 17.67a2.694 2.694 0 11-.01 5.388 2.694 2.694 0 01.01-5.388zm0 4.095a1.41 1.41 0 100-2.82 1.41 1.41 0 000 2.82zm2.737-7.6l-.008-.012s6.448-.003 8.453.015c-1.959-1.724-2.364-2.92-7.421-3.017l-1.214.019c-.004.239-.108 2.995.19 2.995zm-4.602-.001h3.358s.084-2.903.09-2.936-.026-.033-.087-.033c-2.708.273-4.211 2.969-3.361 2.969z"
    />
  </svg>
))
export default SportsCar
