/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SvgArrowBack = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M10.478 18.007l-5.398-5H5v-.074l-1-.926 1-.909v-.091h.1l5.533-5.033L12 7.417l-3.866 3.59H20v2H8.127l3.752 3.498-1.401 1.502z"
    />
  </svg>
))
export default SvgArrowBack
