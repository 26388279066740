import { useState, useEffect } from 'react'

const getWindowWidth = () => {
  const { innerWidth: width } = window
  return {
    width,
  }
}

const mobileBreakpoint = () => getWindowWidth().width < 768
const tabletBreakpoint = () => getWindowWidth().width > 767 && getWindowWidth().width <= 1025

export const useWindowWidth2 = (): {
  isMobile: boolean
  isTablet: boolean
} => {
  const [isMobile, setIsMobile] = useState<boolean>(mobileBreakpoint())
  const [isTablet, setIsTablet] = useState<boolean>(tabletBreakpoint())

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(mobileBreakpoint())
      setIsTablet(tabletBreakpoint())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { isMobile, isTablet }
}
