'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SvgCar5 = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M14.106 14.736c3.07.039 8.091 0 11.076 0l.097.005c-2.976-1.881-6.047-3.953-12.393-3.688.013.143.07 2.837.15 3.214.083.388.046.456 1.07.469zm-12.502 0h4.807s-.004-3.702 0-3.723c.01-.058-4.224-.016-4.31 0-.753.457-1.228 3.723-.497 3.723zm5.52 0h4.986c.121-.384-.069-3.702-.066-3.723.01-.058-4.835-.016-4.921 0-.15 1.048 0 3.555 0 3.723zM32 20.624l-3.391.037c.012-.113.019-.227.019-.342 0-1.82-1.614-3.295-3.604-3.295s-3.605 1.475-3.605 3.295c0 .121.008.241.022.36l-9.472-.017a2.99 2.99 0 00.02-.342c0-1.82-1.614-3.295-3.605-3.295S4.779 18.5 4.779 20.32c0 .119.008.236.021.352H1.875c-2.787-.01-1.981-9.469 0-10.239 1.644-.639 9.199-.432 11.047-.432 6.104 0 9.887 2.036 12.615 4.753 5.076-.039 6.461 3.726 6.461 5.871zm-6.652-2.731c1.465 0 2.652 1.143 2.652 2.554s-1.187 2.554-2.652 2.554c-1.464 0-2.652-1.143-2.652-2.554s1.187-2.554 2.652-2.554zm-16.696 0c1.464 0 2.652 1.143 2.652 2.554s-1.187 2.554-2.652 2.554C7.188 23.001 6 21.858 6 20.447s1.187-2.554 2.652-2.554zm16.696 3.883c.762 0 1.38-.595 1.38-1.329s-.618-1.329-1.38-1.329c-.762 0-1.38.595-1.38 1.329s.618 1.329 1.38 1.329zm-16.696 0c.762 0 1.38-.595 1.38-1.329s-.618-1.329-1.38-1.329c-.762 0-1.38.595-1.38 1.329s.618 1.329 1.38 1.329zM2.645 5.715H16.71A3.29 3.29 0 0120 9.005H2.645a1.644 1.644 0 110-3.29z"
    />
  </svg>
))
export default SvgCar5
