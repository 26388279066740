'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SvgCarBike = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path
      fill="inherit"
      d="M9.843 19.702a1.93 1.93 0 01-1.07-3.539l.806 1.731a.292.292 0 00.529-.246l-.806-1.731a1.934 1.934 0 012.474 1.854 1.935 1.935 0 01-1.932 1.932zm-3.64-2.455l-1.285-2.758h2.761l-1.477 2.758zm-3.688 2.491c-1.065 0-1.932-.867-1.932-1.932a1.935 1.935 0 012.584-1.818l-.873 1.645a.292.292 0 00.255.428l1.879.019a1.935 1.935 0 01-1.913 1.658zm1.908-2.241l-1.388-.014.646-1.217c.387.294.661.731.742 1.231zm.015-2.659l1.244 2.671-.669-.006a2.516 2.516 0 00-1.056-1.757l.482-.908zm5.405.416c-.276 0-.542.045-.79.128L7.55 12.155h-.915c-.161 0-.291.13-.291.292s.13.292.291.292h.543l.543 1.167H4.646l-.543-1.167h.563a.292.292 0 000-.584H3.135a.292.292 0 100 .584h.325l.669 1.437-.686 1.293a2.518 2.518 0 00-3.442 2.337 2.518 2.518 0 002.515 2.515c1.293 0 2.36-.98 2.499-2.236l1.219.012a.292.292 0 00.257-.154l1.664-3.108.369.793a2.518 2.518 0 00-1.197 2.141c0 1.387 1.128 2.515 2.515 2.515s2.515-1.128 2.515-2.515a2.518 2.518 0 00-2.515-2.515z"
    />
    <path
      fill="currentColor"
      d="M27.318 19.501a.891.891 0 100-1.782.891.891 0 000 1.782zm0-2.602a1.712 1.712 0 110 3.424 1.712 1.712 0 010-3.424zm-10.777 2.602a.891.891 0 100-1.782.891.891 0 000 1.782zm0-2.602a1.712 1.712 0 110 3.424 1.712 1.712 0 010-3.424zm10.879-2.245l.063.004c-1.927-1.333-3.849-2.613-7.077-2.613-.439 0-.872.016-1.298.046.008.101.156 1.879.208 2.146.054.275-.015.38.648.389 1.988.027 5.523.027 7.456.027zm-12.032 0h3.113s-.009-2.457-.007-2.472c.007-.041-.024-.041-.079-.03-2.506.251-3.815 2.502-3.027 2.502zm16.448 4.172l-2.197.026a2.334 2.334 0 10-4.642.013l-6.134-.012a2.334 2.334 0 10-4.643.007h-1.332a2.95 2.95 0 00-1.117-3.559c.206-.384.431-.7.629-.88 2.211-1.89 4.44-3.122 7.08-3.122 3.953 0 6.403 1.443 8.17 3.368 3.288-.027 4.185 2.64 4.185 4.159z"
    />
  </svg>
))
export default SvgCarBike
