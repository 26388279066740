'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SvgCarTrailer = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M39.58 20a2.52 2.52 0 012.51 2.51 2.508 2.508 0 11-4.828-.96 2.51 2.51 0 012.319-1.549zm0 3.82a1.31 1.31 0 100-2.62 1.31 1.31 0 000 2.62zM23.78 20a2.51 2.51 0 11-.003 5.019A2.51 2.51 0 0123.781 20zm0 3.82a1.31 1.31 0 100-2.62 1.31 1.31 0 000 2.62zM-2 20a2.51 2.51 0 11-.003 5.019A2.51 2.51 0 01-1.999 20zm0 3.82v-.02a1.31 1.31 0 001.31-1.29A1.31 1.31 0 10-2 23.82zm42.07-7.11c4.82-.04 6.14 3.87 6.21 6.14h-3.22v-.37a3.43 3.43 0 00-6.85 0v.37h-9v-.36a3.43 3.43 0 00-6.85 0v.37h-2.78c-3.33 0-1.21-5.31.11-6.51 3.24-2.77 6.51-4.58 10.38-4.58 5.82 0 9.41 2.12 12 4.94zm-18 0h4.56v-3.62c0-.05-.04-.05-.12-.05-3.68.37-5.59 3.67-4.44 3.67zm17.64 0l.1.05c-2.83-1.96-5.65-3.84-10.38-3.84-.65 0-1.28.07-1.9.07.01.15.22 2.75.3 3.15s-.05.57.95.57h10.93zm-24.3 6.18l-14.02.04v-.39a3.55 3.55 0 00-7.09 0v.39h-7.37A1.922 1.922 0 01-15 21V9a3 3 0 013-2.96H1.93c5.5 0 6.46 4.25 9 10l2 4.42A.79.79 0 0113 21h1.82c-.001.423.073.843.22 1.24.089.234.214.454.37.65zm-18.24-9.51v-4a1 1 0 00-1-1h-8a1 1 0 00-1 1v4a1 1 0 001 1h8a1 1 0 001-1zm2.84 1h4a1 1 0 001-1v-4a1 1 0 00-1-1h-4a1 1 0 00-1 1v4a1 1 0 001 1z"
    />
  </svg>
))
export default SvgCarTrailer
