/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SvgPassenger = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.237 29.814l-.426-.001h-.374c-.221 0-.442-.111-.442-.333l-1.33-9.53h-.111l-.998-.443a.887.887 0 01-.554-.886l.222-6.98a2.992 2.992 0 012.992-2.771h2.88a2.992 2.992 0 012.992 2.77l.444 6.981a.996.996 0 01-.555.886l-.996.443h-.111l-1.33 9.53c-.111.222-.221.333-.443.333h-.759c-.067 0-.142-.013-.175-.039a.442.442 0 01-.107-.14l-.28-.494-.28.493a.455.455 0 01-.108.141.242.242 0 01-.15.04zM15.545 2a2.88 2.88 0 110 5.76 2.88 2.88 0 010-5.76z"
    />
  </svg>
))
export default SvgPassenger
