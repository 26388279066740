import TravelSearch from './Components/TravelSearch'
import i18n from 'i18next'
import { I18nextProvider, initReactI18next } from 'react-i18next'
import { getLocaleFromUrlOrQuery } from './Utils/common'
import { useEffect, useState } from 'react'
import '@dfds-pax/ui/styles.css'
import Content, { UrlSlug } from './Utils/content'

const AppWithContent = () => {
  const [_urlSlugs, set_urlSlugs] = useState<UrlSlug[]>()
  const [i18Instance, seti18Instance] = useState<typeof i18n>()

  const locale = getLocaleFromUrlOrQuery()

  useEffect(() => {
    ;(async () => {
      const { content, urlSlugs } = await Content()

      set_urlSlugs(urlSlugs)
      if (content) {
        const _i18Instance = i18n.createInstance()
        _i18Instance.use(initReactI18next).init({
          debug: false,
          lng: locale,
          fallbackLng: 'en',
          interpolation: {
            escapeValue: false,
          },
          resources: {},
        })
        _i18Instance.addResourceBundle(locale.substring(0, 2), 'translation', content)
        seti18Instance(_i18Instance)
      }
    })()
  }, [])

  return (
    <div id="travel-search">
      {i18Instance && <I18nextProvider i18n={i18Instance}>{i18Instance && <TravelSearch urlSlugs={_urlSlugs} />}</I18nextProvider>}
    </div>
  )
}

export default AppWithContent
