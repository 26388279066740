'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const YesNotSelectedIcon = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" ref={svgRef} {...props}>
    <circle cx="12" cy="12" r="10" fill="#EEF0F1" />
  </svg>
))

export default YesNotSelectedIcon
