import { fetcher, getBaseApi } from '../Utils/common'
import useSWR from 'swr'
import { UseRoutesResult } from './useRoutes'
import { LoadDataFromParams } from '../Utils/url-helpers'
import { isValidOfferDomain } from '../Components/OfferField'

export const useVehicles = (
  selectedVehicle?: SelectedVehicle,
  selectedPassengers?: SelectedPassengers,
  useRoutesResult?: UseRoutesResult,
  offercode?: string
): UseVehiclesResult => {
  const baseApi = getBaseApi()
  const { queryData } = LoadDataFromParams()
  const salesOwner = useRoutesResult?.selectedRoute?.salesOwner
  let offerOrProductCode = useRoutesResult?.routesApiData?.offerOrProductCode

  // using offercode variable is only for localhost and none dfds domains except for app-travel-search
  if (!offerOrProductCode && isValidOfferDomain() && offercode !== '') offerOrProductCode = offercode

  const route = useRoutesResult?.selectedUnloCode
  const { data, isLoading } = useSWR(
    salesOwner && route
      ? `${baseApi}/vehicle-types?salesOwner=${salesOwner}&route=${route}&offerOrProductCode=${offerOrProductCode}`
      : null,
    { fetcher }
  )

  const vehicleTypesdata = data as VehicleTypesResponse

  const selectedVehicleCode =
    selectedVehicle?.code ??
    queryData.vehicleCode ??
    (useRoutesResult?.routesApiData?.defaultToNoVehicle ? 'NCAR' : vehicleTypesdata?.defaultVehicleCode)
  let petsAllowed = false

  if (selectedVehicleCode === 'NCAR') petsAllowed = vehicleTypesdata?.isPetsAllowedForFootPassenger ?? false
  else if (vehicleTypesdata?.vehicleTypes.find((x) => x.code === selectedVehicleCode)?.petsAllowed) petsAllowed = true
  else if (!vehicleTypesdata?.vehicleTypes) petsAllowed = true

  const selectedVehicleStillValid =
    (selectedVehicleCode === 'NCAR' && !vehicleTypesdata?.isVehicleMandatory) ||
    vehicleTypesdata?.vehicleTypes.find((x) => x.code === selectedVehicleCode)

  const _selectedVehicle = {
    code: selectedVehicleStillValid
      ? selectedVehicleCode
      : useRoutesResult?.routesApiData?.defaultToNoVehicle
        ? 'NCAR'
        : vehicleTypesdata?.defaultVehicleCode ?? 'NCAR',
    selectedTrailer: selectedVehicle?.trailer
      ? selectedVehicle.trailer
      : queryData.trailerCode
        ? vehicleTypesdata?.vehicleTypes
            .find((x) => x.code === selectedVehicleCode)
            ?.availableTrailers.find((t) => t.code === queryData.trailerCode)
        : undefined,
  }

  let adults = queryData?.adults ?? 1

  if (selectedPassengers?.adults) adults = selectedPassengers?.adults
  else if (useRoutesResult?.campaignPassengers && useRoutesResult?.campaignPassengers > 0) adults = useRoutesResult?.campaignPassengers

  let children = selectedPassengers?.children ?? queryData?.childrenAges.length ?? 0
  let childAges = selectedPassengers?.childrenAges ?? queryData?.childrenAges ?? []

  let numberOfPassengers = adults + children

  let selectedNumberOfPets = selectedPassengers?.pets ?? queryData?.pets ?? 0

  if (selectedNumberOfPets && selectedNumberOfPets > vehicleTypesdata?.maxNumberOfPets) {
    selectedNumberOfPets = vehicleTypesdata?.maxNumberOfPets
  }

  const _numberOfPassengersFromApi = vehicleTypesdata?.vehicleTypes.find(
    (x: any) => x.code === _selectedVehicle.code
  )?.maxNumberOfPassengers

  if (_numberOfPassengersFromApi && numberOfPassengers > _numberOfPassengersFromApi) {
    const _children = _numberOfPassengersFromApi - adults
    children = _children > -1 ? _children : 0
    adults = _numberOfPassengersFromApi - children
    numberOfPassengers = adults + children
    childAges = childAges.slice(0, children) ?? []
  }

  let maxNumberOfPassengers: number

  if (typeof vehicleTypesdata?.vehicleTypes.find((x: any) => x.code === _selectedVehicle.code)?.maxNumberOfPassengers === 'number')
    maxNumberOfPassengers = Number(vehicleTypesdata?.vehicleTypes.find((x: any) => x.code === _selectedVehicle.code)?.maxNumberOfPassengers)
  else if (_selectedVehicle?.code === 'NCAR') maxNumberOfPassengers = 9
  else {
    maxNumberOfPassengers = 9
  }

  return {
    isLoading,
    vehicleApiData: vehicleTypesdata,
    selectedVehicle: _selectedVehicle,
    petsAllowed,
    maxNumberOfPassengers,
    selectedPassengers: {
      adults,
      children,
      pets: selectedNumberOfPets,
      childrenAges: childAges,
    },
    numberOfPassengers,
  }
}

export type SelectedVehicle = {
  code: string
  trailer?: AvailableTrailer
}

export type SelectedPassengers = {
  adults: number
  children: number
  childrenAges: number[]
  pets: number
}

export interface UseVehiclesResult {
  isLoading: boolean
  vehicleApiData: VehicleTypesResponse
  selectedVehicle: {
    code: string
    selectedTrailer?: AvailableTrailer | undefined
  }
  selectedPassengers?: {
    adults?: number
    children?: number
    pets?: number
    childrenAges?: number[]
  }

  petsAllowed: boolean
  maxNumberOfPassengers: number
  numberOfPassengers: number
}

export interface VehicleTypesResponse {
  defaultVehicleCode: string
  isVehicleMandatory: boolean
  isPetsAllowedForFootPassenger: boolean
  maxNumberOfPets: number
  vehicleTypes: VehicleType[]
}

export interface VehicleType {
  code: string
  categoryCode: string
  height: number
  length: number
  maxNumberOfPassengers: number
  petsAllowed: boolean
  registrationNumberMandatory: boolean
  availableTrailers: AvailableTrailer[]
}

export interface AvailableTrailer {
  code: string
  height: number
  length: number
}
