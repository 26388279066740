import ReturnJourney from '../../Icons/ReturnJourney'
import {Button} from '@dfds-pax/ui'
import Typograhy from '../Shared/Typograhy'
import { useTranslation } from 'react-i18next'
import MultiOfferModal from './MultiOfferModal'
import { useWindowWidth } from '../../Hooks'
import { ProductListSections } from '../../../api/travel-search-config'
import { unlockScroll } from '../../Utils/scrolling'
import { Theme } from '../../Utils/theme'

interface Props {
  offerModalShown: boolean
  route: string
  description?: string
  theme: Theme | null
  productLists: ProductListSections[]
  inView: boolean
  onProductClicked: (minibookId: string) => void
  onRequestOfferModal: (show: boolean) => void
}

const MultiOffer: React.FC<Props> = ({
  route,
  theme,
  productLists,
  offerModalShown,
  onProductClicked,
  description,
  inView,
  onRequestOfferModal,
}) => {
  const { t } = useTranslation()
  const { widthSmallerThan1025 } = useWindowWidth()

  return (
    <>
      <div className={`ts-flex ts-bg-transparent ${inView ? 'ts-w-full ts-max-w-[1200px] ts-py-dfds-s' : 'ts-w-fit'}`}>
        {!widthSmallerThan1025 ? (
          <div className="ts-flex ts-w-full ">
            <div className={`ts-border-solid  ts-z-5000 ts-flex ts-w-[370px] ${inView ? 'ts-w-full ts-bg-transparent' : 'ts-bg-dfds-surface-light/30 ts-px-dfds-s ts-py-dfds-xs'}`}>
              <div className={`ts-flex ts-w-full ts-gap-dfds-xs`}>
                <div className="ts-flex ts-w-full ts-flex-row ts-items-center ts-text-dfds-text-light-primary mobile:ts-w-full">
                  <ReturnJourney color={theme?.iconColor} height={35} width={35} />
                  <div className="ts-ml-dfds-xs ts-flex ts-flex-col">
                    {!description ? (
                      <div style={{ color: `${theme && theme.textColor}` }}>
                        <Typograhy as="labelBold">{t('GENERAL-ROUTE')}</Typograhy>
                        <Typograhy as="label">{route}</Typograhy>
                      </div>
                    ) : (
                      <div style={{ color: `${theme && theme.textColor}` }}>
                        <Typograhy as="labelBold">{description}</Typograhy>
                      </div>
                    )}
                  </div>
                  <div className="ts-ml-auto">
                    <Button data-e2e="multiofferButton" variation="Primary" bold onClick={() => onRequestOfferModal(true)}>
                      {t('SEE-OFFERS')}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`ts-border-solid ts-h-0 ts-w-0 ts-border-b-[64px] ts-border-l-[36px] ts-border-t-[0px] ts-border-b-transparent ts-border-t-transparent ${
                inView ? 'ts-border-none' : 'ts-border-l-dfds-surface-light/30'
              }`}
            ></div>
          </div>
        ) : (
          <div data-e2e="multiofferButton" className="ts-p-dfds-s md:ts-right-0 mobile:ts-left-0">
            <Button variation="Primary" bold onClick={() => onRequestOfferModal(true)}>
              {t('SEE-OFFERS')}
            </Button>
          </div>
        )}
      </div>
      <MultiOfferModal
        productLists={productLists}
        show={offerModalShown}
        onClose={() => {
          unlockScroll()
          onRequestOfferModal(false)
        }}
        onProductClicked={(id) => {
          unlockScroll()
          onProductClicked(id)
        }}
      />
    </>
  )
}

export default MultiOffer

export interface ProductList {
  description: string
  products: Product[]
}
export interface Product {
  title: string
  productDetails: string
  offerDetails: string
  minibookId: string
}
