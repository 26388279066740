/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SvgOneWay = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="1em" height="1em" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M30 18.5l-2.38 4.55h-.36c-3.68 0-4.94 1.57-8.66 1.57-3.72 0-6.86-2.3-10.31-2.3-3.46 0-5.07.79-5.07.79A9.06 9.06 0 012 18.53l28-.03zM16.27 8l1.37 5.44h7.33l.72 1.25h-3.16s-.26 0-.26.21c0 .2.02.38.05.6.03.21.13.44.53.54l4.13.9.4.68h-21l1.93-4.18h2.6L13.85 8h2.42zm3.53 7h-.6c-.32 0-.57.2-.57.45v.85c0 .18.14.34.34.41a.7.7 0 00.23.04h.6c.09 0 .16-.01.23-.04.2-.07.34-.23.34-.4v-.86c0-.25-.25-.45-.57-.45zm-3.5 0h-.6c-.32 0-.57.2-.57.45v.85c0 .18.14.34.34.41a.7.7 0 00.23.04h.6c.09 0 .16-.01.23-.04.2-.07.34-.23.34-.4v-.86c0-.25-.25-.45-.57-.45zm-3.49 0h-.62c-.31 0-.56.2-.56.45v.85c0 .18.13.34.33.41.07.03.14.04.23.04h.62c.09 0 .16-.01.23-.04.2-.07.34-.23.34-.4v-.86c0-.17-.12-.3-.3-.38a.65.65 0 00-.27-.07z"
    />
  </svg>
))
export default SvgOneWay
