import { fetcher, getBaseApi } from '../Utils/common'
import useSWR from 'swr'
import { UseRoutesResult } from './useRoutes'
import { UseVehiclesResult } from './useVehicles'
import { Dispatch, SetStateAction } from 'react'
import { format } from 'date-fns'
import { LoadDataFromParams } from '../Utils/url-helpers'
import { isFrsRoute } from '../Utils/frs-helpers'
import { isValidOfferDomain } from '../Components/OfferField'

export const useTravelDates = (
  selectedDates: selectedDates,
  setDateState: Dispatch<SetStateAction<selectedDates>>,
  useRoutesResult: UseRoutesResult,
  useVehiclesResult: UseVehiclesResult,
  currency: string,
  offercode: string
): UseTravelDatesResult => {
  const baseApi = getBaseApi()
  const { queryData } = LoadDataFromParams()

  const numberOfAdults = useVehiclesResult?.selectedPassengers?.adults?.toString() ?? useRoutesResult?.campaignPassengers?.toString() ?? '1'
  const vehicleType = useVehiclesResult?.selectedVehicle?.code

  const travelDatesParams = new URLSearchParams()
  travelDatesParams.append('direction', 'outbound')
  useRoutesResult?.selectedRoute?.salesOwner &&
    travelDatesParams.append('salesOwner', useRoutesResult?.selectedRoute?.salesOwner?.toString())
  travelDatesParams.append('numberOfAdults', numberOfAdults)
  useRoutesResult?.routesApiData?.offerOrProductCode &&
    travelDatesParams.append('offerOrProductCode', useRoutesResult?.routesApiData?.offerOrProductCode)

  // using offercode variable is only for localhost and none dfds domains except for app-travel-search
  if (!useRoutesResult?.routesApiData?.offerOrProductCode && isValidOfferDomain() && offercode !== '')
    travelDatesParams.append('offerOrProductCode', offercode)

  travelDatesParams.append('numberOfAdults', numberOfAdults)
  travelDatesParams.append('vehicleType', vehicleType)
  useVehiclesResult?.selectedVehicle?.selectedTrailer?.code &&
    travelDatesParams.append('trailerType', useVehiclesResult?.selectedVehicle?.selectedTrailer?.code)
  useVehiclesResult?.selectedPassengers?.pets &&
    travelDatesParams.append('numberOfPets', useVehiclesResult.selectedPassengers.pets.toString())
  useVehiclesResult?.selectedPassengers?.childrenAges &&
    useVehiclesResult.selectedPassengers.childrenAges.map((age) => travelDatesParams.append('chdAges', age.toString()))
  useRoutesResult?.selectedUnloCode && travelDatesParams.append('route', useRoutesResult?.selectedUnloCode)

  let outboundResponse

  if (useRoutesResult?.selectedRoute?.routeCode && isFrsRoute(useRoutesResult?.selectedRoute?.routeCode)) {
    outboundResponse = useSWR(
      useRoutesResult?.selectedRoute?.routeCode
        ? `${baseApi}/available-travel-dates-frs?routeCode=${useRoutesResult?.selectedRoute?.routeCode}&currencyCode=${currency}`
        : null,
      { fetcher }
    )
  } else {
    outboundResponse = useSWR(
      numberOfAdults && vehicleType && useRoutesResult?.selectedRoute?.salesOwner && useRoutesResult?.selectedRoute?.routeCode
        ? `${baseApi}/available-travel-dates?${travelDatesParams.toString()}`
        : null,
      { fetcher }
    )
  }
  const outboundTravelDates = outboundResponse.data as TravelDatesResponse
  const outboundIsLoading = outboundResponse.isLoading

  if (outboundTravelDates?.offerDates?.length > 0 && useRoutesResult?.routesApiData?.offerOrProductText)
    outboundTravelDates.offerText = useRoutesResult?.routesApiData?.offerOrProductText

  travelDatesParams.set('direction', 'homebound')

  travelDatesParams.append(
    'outboundDate',
    selectedDates?.outboundDate ? format(selectedDates?.outboundDate, 'yyyy-MM-dd') : outboundTravelDates?.dates?.fromDate
  )

  let homeboundResponse
  //Log a warning if there is no traveldates at all, which indicates that the product/offercode is invalid.
  if (
    outboundTravelDates &&
    outboundTravelDates.dates &&
    !outboundTravelDates?.dates?.fromDate &&
    !outboundTravelDates?.defaultDate &&
    useRoutesResult?.routesApiData?.offerOrProductCode
  ) {
    homeboundResponse = outboundResponse
  }
  if (useRoutesResult?.selectedRoute?.routeCode && isFrsRoute(useRoutesResult?.selectedRoute?.routeCode)) {
    homeboundResponse = useSWR(
      !useRoutesResult.isOneway && outboundTravelDates?.dates && outboundTravelDates.dates?.fromDate && outboundTravelDates?.defaultDate
        ? `${baseApi}/available-travel-dates-frs?routeCode=${useRoutesResult?.selectedRoute?.routeCode}&currencyCode=${currency}`
        : null,
      { fetcher }
    )
  } else {
    homeboundResponse = useSWR(
      numberOfAdults &&
        vehicleType &&
        useRoutesResult?.selectedRoute?.salesOwner &&
        useRoutesResult?.selectedUnloCode &&
        !useRoutesResult.isOneway &&
        outboundTravelDates?.dates &&
        outboundTravelDates.dates?.fromDate &&
        outboundTravelDates?.defaultDate
        ? `${baseApi}/available-travel-dates?${travelDatesParams.toString()}`
        : null,
      { fetcher }
    )
  }
  const homeboundTravelDates = homeboundResponse.data as TravelDatesResponse
  const homeboundIsLoading = homeboundResponse.isLoading

  if (homeboundTravelDates?.offerDates?.length > 0 && useRoutesResult?.routesApiData?.offerOrProductText)
    homeboundTravelDates.offerText = useRoutesResult?.routesApiData?.offerOrProductText

  let selectedOutboundDate = selectedDates?.outboundDate
  let selectedHomeboundDate = selectedDates?.homeboundDate

  var _homeboundTravelDates = getDisabledHomeboundDates(homeboundTravelDates, selectedOutboundDate)

  if (!selectedOutboundDate && queryData.outboundDate && outboundTravelDates) {
    selectedOutboundDate = new Date(queryData.outboundDate)
    if (selectedOutboundDate < new Date()) selectedOutboundDate = new Date(outboundTravelDates?.defaultDate)
  }
  // validate outbound date
  if (selectedOutboundDate) {
    const selectedOutboundDateStr = format(selectedOutboundDate, 'yyyy-MM-dd')
    if (
      outboundTravelDates?.disabledDates?.includes(selectedOutboundDateStr) ||
      new Date(outboundTravelDates?.dates?.toDate) < selectedOutboundDate
    ) {
      selectedOutboundDate = getNextAvailableDate(
        format(selectedOutboundDate, 'yyyy-MM-dd'),
        outboundTravelDates.disabledDates,
        true,
        outboundTravelDates?.defaultDate
      )
      _homeboundTravelDates = getDisabledHomeboundDates(homeboundTravelDates, selectedOutboundDate)
    }
  }

  if (!selectedOutboundDate && outboundTravelDates?.defaultDate) {
    selectedOutboundDate = new Date(outboundTravelDates?.defaultDate as string)
    _homeboundTravelDates = getDisabledHomeboundDates(homeboundTravelDates, selectedOutboundDate)
  }

  // validate homebound date
  if (!selectedHomeboundDate && queryData.homeboundDate && selectedOutboundDate && _homeboundTravelDates?.disabledDates) {
    selectedHomeboundDate = new Date(queryData.homeboundDate)
    if (selectedHomeboundDate < selectedOutboundDate)
      selectedHomeboundDate = getNextAvailableDate(format(selectedOutboundDate, 'yyyy-MM-dd'), _homeboundTravelDates.disabledDates)
  }
  if (selectedHomeboundDate) {
    const selectedHomeboundDateStr = format(selectedHomeboundDate, 'yyyy-MM-dd')
    if (
      selectedOutboundDate &&
      (_homeboundTravelDates?.disabledDates?.includes(selectedHomeboundDateStr) ||
        new Date(_homeboundTravelDates?.dates?.toDate) < selectedHomeboundDate)
    ) {
      selectedHomeboundDate = getNextAvailableDate(format(selectedOutboundDate, 'yyyy-MM-dd'), _homeboundTravelDates.disabledDates)
    }
  }

  if (!selectedHomeboundDate && _homeboundTravelDates?.defaultDate && selectedOutboundDate) {
    const selectedOutboundDateStr = format(selectedOutboundDate, 'yyyy-MM-dd')

    selectedHomeboundDate = getNextAvailableDate(selectedOutboundDateStr, _homeboundTravelDates.disabledDates)
  }

  if (!selectedDates && !useRoutesResult?.isOneway && outboundTravelDates?.defaultDate && _homeboundTravelDates?.defaultDate) {
    setDateState({
      outboundDate: new Date(outboundTravelDates?.defaultDate as string),
      homeboundDate: getNextAvailableDate(outboundTravelDates?.defaultDate as string, _homeboundTravelDates.disabledDates),
    })
  } else if (!selectedDates && useRoutesResult?.isOneway && outboundTravelDates?.defaultDate) {
    setDateState({
      outboundDate: new Date(outboundTravelDates?.defaultDate as string),
      homeboundDate: undefined,
    })
  }

  return {
    isLoading: useRoutesResult.isOneway ? outboundIsLoading : outboundIsLoading || homeboundIsLoading,
    outboundTravelDates,
    homeboundTravelDates: _homeboundTravelDates,
    vehicleType,
    selectedOutboundDate,
    selectedHomeboundDate,
  }
}

function getNextAvailableDate(startDate: string, unavailableDates: string[], invers?: boolean, endDate?: string) {
  let currentDate = new Date(startDate)
  !invers && currentDate.setDate(currentDate.getDate() + 1)

  while (true) {
    let isUnavailable = false
    const formattedCurrentDate = format(currentDate, 'yyyy-MM-dd')

    for (const unavailableDate of unavailableDates) {
      if (formattedCurrentDate === unavailableDate) {
        isUnavailable = true
        break
      }
    }

    // If the current date is not unavailable, return it as the next available date
    if (!isUnavailable) {
      return new Date(currentDate)
    }

    // When no homebound date is available before a fixed enddate. Currently it just returns the enddate.
    if (endDate && (formattedCurrentDate === endDate || currentDate < new Date())) {
      return new Date(endDate)
    }
    // Move to the next date
    currentDate.setDate(currentDate.getDate() + (invers ? -1 : 1))
  }
}

function getDisabledHomeboundDates(homeboundTravelDates: TravelDatesResponse, selectedOutboundDate: Date | undefined) {
  const _homeboundTravelDates: TravelDatesResponse | undefined =
    homeboundTravelDates && selectedOutboundDate
      ? { ...homeboundTravelDates, disabledDates: homeboundTravelDates.disabledDates }
      : homeboundTravelDates
  return _homeboundTravelDates
}
export interface UseTravelDatesResult {
  isLoading: boolean
  outboundTravelDates: TravelDatesResponse
  homeboundTravelDates?: TravelDatesResponse
  vehicleType: string
  selectedOutboundDate?: Date
  selectedHomeboundDate?: Date
}

export interface selectedDates {
  outboundDate?: Date
  homeboundDate?: Date
}

export interface TravelDatesResponse {
  defaultDate: string
  dates: {
    fromDate: string
    toDate: string
  }
  disabledDates: string[]
  offerDates: string[]
  offerText?: string
}
