import { useEffect, useRef, useState } from 'react'
import SelectField from './Shared/SelectField'
import Routes from './Routes/Routes'
import Vehicles from './Vehicles/Vehicles'
import Passengers from './Passengers/Passengers'
import { useWindowWidth } from '../Hooks/useWindowWidth'
import DateField from './Date/DateField'
import { getAppMinibookId, getDatepickerLocale, toShortDateString } from '../Utils'
import OnewayReturn from './OnewayReturn/OnewayReturn'
import OnewayReturnDesktop from './OnewayReturn/OnewayReturnDesktop'
import { useTranslation } from 'react-i18next'
import VehicleIcon from './Vehicles/VehicleIcon'
import OneWay from '../Icons/OneWay'
import Passenger from '../Icons/Passenger'
import { motion } from 'framer-motion'
import {
  useRoutes,
  useVehicles,
  useTravelDates,
  Route,
  SelectedVehicle,
  SelectedPassengers,
  selectedDates,
  useKeepScrollPosition,
  AvailableTrailer,
} from '../Hooks'
import { LoadDataFromParams } from '../Utils/url-helpers'
import StickyButton from './StickyButton'
import { useWindowWidth2 } from '../Hooks/useWindowWidth2'
import { format } from 'date-fns'
import Modal from './Shared/Modal/Modal'
import Typograhy from './Shared/Typograhy'
import {
  TrackDates,
  TrackOneWay,
  TrackPassengers,
  TrackRoute,
  TrackVehicle,
  TrackingCommand,
  TravelSearchTrackingTypes,
  getTrackingSession,
} from '../Utils/tracking'
import MultiOffer from './MultiOffer/MultiOffer'
import { useTravelSearchConfig } from '../Hooks/useTravelSearchConfig'
import { Theme, getThemeColors } from '../Utils/theme'
import { isFrsRoute, mapToCurrencyToContentfulResource } from '../Utils/frs-helpers'
import Currency from './Currency/Currency'
import { DatePicker, DateRangePicker } from '@dfds-pax/ui'
import { useBookingUrl } from '../Hooks/useBookingUrl'
import clsx from 'clsx'
import React from 'react'
import OfferField from './OfferField'
import { UrlSlug } from '../Utils/content'

interface Props {
  urlSlugs?: UrlSlug[]
}

const TravelSearch: React.FC<Props> = ({ urlSlugs }) => {
  const { queryData } = LoadDataFromParams()
  const [activeSelectField, setActiveSelectField] = useState<ActiveSelectField>(ActiveSelectField.NONE)
  const [oneway, setOneway] = useState<boolean | undefined>(queryData.oneWay)
  const [returnFocused, setReturnFocused] = useState<boolean>(false)
  const travelSearchRef = useRef<HTMLDivElement>(null)
  const selectorContainer = useRef<HTMLDivElement>(null)
  const [inView, setInView] = useState<boolean>(false)
  const [selectedCategory, setSelectedCategory] = useState<string | undefined>()
  const [selectedRoute, setSelectedRoute] = useState<Route>()
  const [disableOneWay, setDisableOneWay] = useState<boolean>(false)
  const [selectedVehicle, setSelectedVehicle] = useState<SelectedVehicle>()
  const [selectedPassengers, setSelectedPassengers] = useState<SelectedPassengers>()
  const [selectedOffercode, setSelectedOffercode] = useState<string>(queryData.offerCode ?? '')
  const [selectedDates, setSelectedDates] = useState<selectedDates>({
    outboundDate: queryData.outboundDate ? new Date(queryData.outboundDate) : undefined,
    homeboundDate: queryData.homeboundDate ? new Date(queryData.homeboundDate) : undefined,
  })
  const [showReturnChanged, setShowReturnChanged] = useState<boolean>(false)
  const [showMultiOffer, setShowMultiOffer] = useState<boolean | undefined>()
  const [showMultiOfferModal, setShowMultiOfferModal] = useState<boolean>(false)
  const [selectedCurrency, setSelectedCurrency] = useState<'EUR' | 'MAD'>('EUR')
  const [minibookId, setMinibookId] = useState<string>(queryData.minibookId ?? getAppMinibookId(window.location.host) ?? '')
  const [selectedTrailerNoLongerAvailable, setSelectedTrailerNoLongerAvailable] = useState<AvailableTrailer | undefined>(undefined)

  const { isMobile, isTablet } = useWindowWidth2()
  const { widthLargerThan1200 } = useWindowWidth()

  const { t } = useTranslation()

  const travelSearchConfigResult = useTravelSearchConfig()

  const dotcomTheme: Theme | null = getThemeColors(travelSearchConfigResult.theme) ?? null
  const routesResult = useRoutes(selectedRoute, oneway, minibookId)
  const vehicleTypesResult = useVehicles(selectedVehicle, selectedPassengers, routesResult, selectedOffercode)
  const travelDatesResult = useTravelDates(
    selectedDates,
    setSelectedDates,
    routesResult,
    vehicleTypesResult,
    selectedCurrency,
    selectedOffercode
  )
  useKeepScrollPosition(isMobile || isTablet, activeSelectField !== ActiveSelectField.NONE)

  useEffect(() => {
    if (
      !travelSearchConfigResult?.isMultiOffer &&
      typeof travelSearchConfigResult?.availableMinibooks === 'string' &&
      !queryData.minibookId
    )
      setMinibookId(getAppMinibookId(window.location.host) ?? travelSearchConfigResult.availableMinibooks)
    setShowMultiOffer(travelSearchConfigResult?.isMultiOffer)
  }, [travelSearchConfigResult?.isMultiOffer])

  useEffect(() => {
    const onScroll = () => {
      const rect = travelSearchRef.current?.getBoundingClientRect()

      if (rect) {
        setInView(rect.bottom < 0)
      }
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.key === 'Escape') {
        setActiveSelectField(ActiveSelectField.NONE)
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  //tracking
  useEffect(() => {
    if (
      travelSearchConfigResult.isLoading === false &&
      travelDatesResult.isLoading === false &&
      routesResult.isLoading === false &&
      vehicleTypesResult.isLoading === false
    ) {
      TrackingCommand(
        TravelSearchTrackingTypes.INITIALIZE,
        routesResult.selectedRoute,
        {
          outboundDate: travelDatesResult.outboundTravelDates?.defaultDate
            ? new Date(travelDatesResult.outboundTravelDates?.defaultDate)
            : undefined,
          homeboundDate:
            !routesResult.isOneway && travelDatesResult?.homeboundTravelDates && travelDatesResult.homeboundTravelDates?.defaultDate
              ? new Date(travelDatesResult.homeboundTravelDates.defaultDate)
              : undefined,
        },
        vehicleTypesResult,
        {
          adults: vehicleTypesResult.selectedPassengers?.adults || 0,
          children: vehicleTypesResult.selectedPassengers?.children || 0,
          childrenAges: vehicleTypesResult.selectedPassengers?.childrenAges || [],
          pets: vehicleTypesResult.selectedPassengers?.pets || 0,
        },
        selectedCurrency,
        routesResult.isOneway
      )
    }
  }, [travelSearchConfigResult.isLoading, routesResult.isLoading, vehicleTypesResult.isLoading, travelDatesResult.isLoading])

  useEffect(() => {
    getTrackingSession() && oneway != undefined && TrackOneWay(oneway)
  }, [oneway])

  useEffect(() => {
    getTrackingSession() && selectedPassengers && TrackPassengers(selectedPassengers)
  }, [selectedPassengers?.adults, selectedPassengers?.children, selectedPassengers?.pets])

  useEffect(() => {
    if (selectedRoute?.routeCode && !vehicleTypesResult.isLoading && getTrackingSession()) {
      TrackRoute(selectedRoute, vehicleTypesResult)
    }
  }, [selectedRoute, vehicleTypesResult.isLoading])

  useEffect(() => {
    getTrackingSession() &&
      !isFrsRoute(routesResult?.selectedRoute?.routeCode ?? '') &&
      selectedVehicle &&
      vehicleTypesResult &&
      !vehicleTypesResult.isLoading &&
      TrackVehicle(vehicleTypesResult)
  }, [selectedVehicle?.code, selectedVehicle?.trailer])

  useEffect(() => {
    getTrackingSession() && selectedDates && TrackDates(selectedDates, false)
  }, [selectedDates])

  useEffect(() => {
    if (
      (selectedRoute && selectedRoute?.returnRequired) ??
      (routesResult.routesApiData && routesResult.routesApiData.categories[0].routes[0].returnRequired) ??
      false
    ) {
      setOneway(false)
      setDisableOneWay(true)
    } else {
      setDisableOneWay(false)
    }
  }, [selectedRoute, routesResult])

  const bookingUrlResult = useBookingUrl(urlSlugs, routesResult, vehicleTypesResult, travelDatesResult, selectedCurrency)

  const isLoading: boolean =
    travelSearchConfigResult?.isLoading ||
    travelDatesResult?.isLoading ||
    routesResult?.isLoading ||
    vehicleTypesResult?.isLoading ||
    toShortDateString(travelDatesResult?.selectedOutboundDate) === '-'

  return (
    <div ref={travelSearchRef} id="travel-search-container" className={`ts-relative`}>
      <Modal
        fullscreenMobile={true}
        headline={t('SWITCH-RETURN-HEADLINE')}
        isOpen={showReturnChanged}
        primaryButtonText={t('YES')}
        secondaryButtonText={t('NO')}
        onPrimaryButtonClicked={() => {
          setOneway(false)
          setShowReturnChanged(false)
        }}
        onSecondaryButtonClicked={() => setShowReturnChanged(false)}
        onCloseRequested={() => setShowReturnChanged(false)}
      >
        <div className="ts-flex ts-flex-1 ts-flex-col">
          <Typograhy as="body">{t('SWITCH-RETURN-DESCRIPTION')}</Typograhy>
        </div>
      </Modal>
      {!widthLargerThan1200 && (
        <StickyButton
          offersShown={showMultiOffer === true}
          theme={dotcomTheme}
          shown={inView && activeSelectField === ActiveSelectField.NONE}
          onClick={() => {
            if (showMultiOffer === true) {
              setShowMultiOfferModal(true)
              return
            }

            window.scrollTo(0, 0)
          }}
        />
      )}
      <motion.div
        initial={{
          top: inView ? 0 : -200,
        }}
        animate={{
          top: inView ? 0 : -200,
        }}
        style={{
          background: `${dotcomTheme && inView && widthLargerThan1200 ? dotcomTheme.backgroundColor : dotcomTheme?.backgroundDesktopColor}`,
        }}
        className={clsx(
          inView && widthLargerThan1200 && 'ts-bg-dfds-surface-tertiary ts-fixed ts-left-0 ts-right-0 ts-top-0 ts-flex ts-justify-center',
          !showMultiOffer && 'ts-py-dfds-s'
        )}
      >
        {!travelSearchConfigResult.isLoading && showMultiOffer && typeof travelSearchConfigResult.availableMinibooks != 'string' ? (
          <MultiOffer
            theme={dotcomTheme}
            offerModalShown={showMultiOfferModal}
            route={travelSearchConfigResult.route || ''}
            description={travelSearchConfigResult.optionalOfferTitle}
            productLists={travelSearchConfigResult.availableMinibooks}
            onProductClicked={(_minibookId: string) => {
              setMinibookId(_minibookId)
              setShowMultiOffer(false)
            }}
            onRequestOfferModal={setShowMultiOfferModal}
            inView={inView}
          />
        ) : (
          showMultiOffer !== undefined && (
            <div className="ts-flex ts-flex-col">
              <div className="ts-flex ts-flex-col">
                {!isMobile && (
                  <OnewayReturnDesktop
                    theme={dotcomTheme}
                    isLoading={false}
                    isOneway={routesResult?.isOneway}
                    onSelected={setOneway}
                    isMultiOffer={travelSearchConfigResult.isMultiOffer}
                    onMultiOfferClicked={() => {
                      setShowMultiOffer(true)
                      setShowMultiOfferModal(false)
                    }}
                    oneWayDisabled={disableOneWay}
                  />
                )}
              </div>
              <div
                ref={selectorContainer}
                className="ts-z-5000 ts-bg-dfds-surface-light/30 ts-p-dfds-s mobile:ts-m-[20px] mobile:ts-mt-0 ts-flex ts-flex-col"
              >
                <div className="ts-gap-dfds-xs ts-flex ts-flex-col lg:ts-flex-row">
                  <div className={`ts-gap-dfds-xs ts-flex ts-flex-col ts-items-center md:ts-flex-row md:ts-flex-wrap`}>
                    {isMobile && !disableOneWay && (
                      <OnewayReturn
                        theme={dotcomTheme}
                        isOneway={routesResult?.isOneway}
                        onSelected={setOneway}
                        isMultiOffer={travelSearchConfigResult.isMultiOffer}
                        onMultiOfferClicked={() => {
                          setShowMultiOffer(true)
                          setShowMultiOfferModal(false)
                        }}
                      />
                    )}
                    <div className="mobile:ts-w-full ts-grow">
                      <SelectField
                        dataAttrList={[
                          { name: 'selected-route', value: `${routesResult?.selectedRoute?.routeCode}` },
                          { name: 'selected-salesowner', value: `${routesResult?.selectedRoute?.salesOwner}` },
                        ]}
                        disabled={
                          routesResult?.routesApiData?.categories?.reduce((count, current) => count + current.routes.length, 0) === 1
                        }
                        title={t('ROUTE')}
                        theme={dotcomTheme}
                        maxWidth="lg:ts-max-w-[200px]"
                        isLoading={travelSearchConfigResult?.isLoading || routesResult?.isLoading}
                        isMobile={isMobile}
                        isOpen={activeSelectField === ActiveSelectField.ROUTE}
                        fieldName="route"
                        value={routesResult.selectedRoute?.name}
                        icon={<OneWay fill={dotcomTheme?.iconColor} width="30" height="30" />}
                        children={
                          <Routes
                            routeCategories={routesResult.routesApiData?.categories}
                            onSelectRoute={(route) => {
                              setSelectedRoute({
                                routeCode: route.routeCode,
                                salesOwner: route.salesOwner,
                                name: route?.name,
                                returnRequired: route.returnRequired,
                              })
                              setActiveSelectField(ActiveSelectField.NONE)
                            }}
                          />
                        }
                        onClick={(clickedOutside: boolean) => {
                          if (clickedOutside) {
                            setActiveSelectField(ActiveSelectField.NONE)
                          } else {
                            !routesResult.isLoading && setActiveSelectField(ActiveSelectField.ROUTE)
                          }
                        }}
                        onClose={() => {
                          activeSelectField === ActiveSelectField.ROUTE && setActiveSelectField(ActiveSelectField.NONE)
                        }}
                      />
                    </div>
                    {!isFrsRoute(routesResult?.selectedRoute?.routeCode ?? '') && (
                      <div className="mobile:ts-w-full ts-grow">
                        <SelectField
                          dataAttrList={[
                            { name: 'selected-vehicle', value: `${vehicleTypesResult?.selectedVehicle?.code}` },
                            { name: 'selected-trailer', value: `${vehicleTypesResult?.selectedVehicle?.selectedTrailer?.code}` },
                          ]}
                          theme={dotcomTheme}
                          disabled={
                            vehicleTypesResult?.vehicleApiData?.vehicleTypes?.reduce((count, current) => count + current.code.length, 0) ===
                              1 ||
                            (vehicleTypesResult?.vehicleApiData?.vehicleTypes?.reduce(
                              (count, current) => count + current.code.length,
                              0
                            ) === 0 &&
                              vehicleTypesResult.selectedVehicle.code === 'NCAR')
                          }
                          maxWidth="lg:ts-max-w-[200px]"
                          title={t('VEHICLE')}
                          isLoading={travelSearchConfigResult?.isLoading || routesResult?.isLoading || vehicleTypesResult.isLoading}
                          isMobile={isMobile}
                          isOpen={activeSelectField === ActiveSelectField.VEHICLE}
                          fieldName="vehicle"
                          value={
                            vehicleTypesResult.selectedVehicle.selectedTrailer
                              ? `${t(`VEHICLE-NAME-${vehicleTypesResult?.selectedVehicle?.code}`)} + ${t(
                                  `TRAILER-NAME-${vehicleTypesResult?.selectedVehicle?.selectedTrailer?.code}`
                                )}`
                              : t(`VEHICLE-NAME-${vehicleTypesResult?.selectedVehicle?.code}`)
                          }
                          icon={
                            vehicleTypesResult.selectedVehicle?.code && (
                              <VehicleIcon code={vehicleTypesResult?.selectedVehicle?.code} height="30" width="30" />
                            )
                          }
                          visibleRows={vehicleTypesResult.selectedVehicle && vehicleTypesResult.selectedVehicle.selectedTrailer ? 2 : 1}
                          children={
                            <Vehicles
                              dataAttrList={[
                                { name: 'selected-vehicle', value: `${vehicleTypesResult?.selectedVehicle?.code}` },
                                { name: 'selected-trailer', value: `${vehicleTypesResult?.selectedVehicle?.selectedTrailer?.code}` },
                              ]}
                              isLoading={travelSearchConfigResult?.isLoading || routesResult?.isLoading || vehicleTypesResult.isLoading}
                              isVehicleMandatory={vehicleTypesResult.vehicleApiData?.isVehicleMandatory || false}
                              vehicleTypes={vehicleTypesResult.vehicleApiData?.vehicleTypes}
                              selectedVehicle={vehicleTypesResult.selectedVehicle?.code}
                              selectedTrailer={vehicleTypesResult?.selectedVehicle?.selectedTrailer}
                              selectedTrailerNoLongerAvailable={selectedTrailerNoLongerAvailable}
                              selectedCategory={selectedCategory}
                              availableTrailers={
                                vehicleTypesResult.vehicleApiData?.vehicleTypes.find(
                                  (x: any) => x.code === vehicleTypesResult.selectedVehicle.code
                                )?.availableTrailers || []
                              }
                              onSelectCategory={(categoryCode) => {
                                if (categoryCode === 'NCAR') {
                                  setSelectedVehicle({
                                    code: 'NCAR',
                                    trailer: undefined,
                                  })
                                } else setSelectedCategory(categoryCode)
                              }}
                              onSelectVehicle={(vehicle) => {
                                const availableTrailers =
                                  vehicleTypesResult.vehicleApiData?.vehicleTypes.find((x: any) => x.code === vehicle)?.availableTrailers ||
                                  []
                                let trailer = vehicleTypesResult?.selectedVehicle?.selectedTrailer
                                if (
                                  availableTrailers?.length > 0 &&
                                  !availableTrailers.some((x) => x.code === vehicleTypesResult?.selectedVehicle?.selectedTrailer?.code)
                                ) {
                                  setSelectedTrailerNoLongerAvailable(vehicleTypesResult?.selectedVehicle?.selectedTrailer)
                                  trailer = undefined
                                } else setSelectedTrailerNoLongerAvailable(undefined)
                                setSelectedVehicle({
                                  code: vehicle,
                                  trailer: trailer,
                                })
                              }}
                              onSelectTrailer={(trailerCode) => {
                                setSelectedTrailerNoLongerAvailable(undefined)
                                setSelectedVehicle({
                                  code: vehicleTypesResult?.selectedVehicle?.code,
                                  trailer: trailerCode,
                                })
                              }}
                              onFooterClick={() => setActiveSelectField(ActiveSelectField.NONE)}
                            />
                          }
                          onClick={(clickedOutside: boolean) => {
                            if (clickedOutside) {
                              setActiveSelectField(ActiveSelectField.NONE)
                            } else {
                              !routesResult?.isLoading && !vehicleTypesResult.isLoading && setActiveSelectField(ActiveSelectField.VEHICLE)
                            }
                          }}
                          onClose={() => {
                            activeSelectField === ActiveSelectField.VEHICLE && setActiveSelectField(ActiveSelectField.NONE)
                          }}
                        />
                      </div>
                    )}

                    {!isFrsRoute(routesResult?.selectedRoute?.routeCode ?? '') && (
                      <div className="mobile:ts-w-full ts-grow">
                        <SelectField
                          dataAttrList={[
                            { name: 'selected-adults', value: `${vehicleTypesResult?.selectedPassengers?.adults}` },
                            { name: 'selected-children', value: `${vehicleTypesResult?.selectedPassengers?.children}` },
                            { name: 'child-ages', value: `${vehicleTypesResult?.selectedPassengers?.childrenAges?.join(',')}` },
                            { name: 'selected-pets', value: `${vehicleTypesResult?.selectedPassengers?.pets}` },
                          ]}
                          title={t('PASSENGERS')}
                          maxWidth="lg:ts-max-w-[80px]"
                          theme={dotcomTheme}
                          isLoading={travelSearchConfigResult?.isLoading || routesResult?.isLoading || vehicleTypesResult.isLoading}
                          isMobile={isMobile}
                          isOpen={activeSelectField === ActiveSelectField.PASSENGER}
                          fieldName="passenger"
                          value={
                            vehicleTypesResult?.selectedPassengers?.pets && vehicleTypesResult?.selectedPassengers?.pets > 0
                              ? `${vehicleTypesResult.numberOfPassengers} + ${vehicleTypesResult?.selectedPassengers?.pets} ${t('PETS')}`
                              : vehicleTypesResult.numberOfPassengers
                          }
                          icon={<Passenger width="30" height="30" />}
                          visibleRows={vehicleTypesResult.selectedPassengers?.adults && vehicleTypesResult.selectedPassengers.pets ? 2 : 1}
                          children={
                            <Passengers
                              dataAttrList={[
                                { name: 'selected-adults', value: `${vehicleTypesResult?.selectedPassengers?.adults}` },
                                { name: 'selected-children', value: `${vehicleTypesResult?.selectedPassengers?.children}` },
                                { name: 'child-ages', value: `${vehicleTypesResult?.selectedPassengers?.childrenAges?.join(',')}` },
                                { name: 'selected-pets', value: `${vehicleTypesResult?.selectedPassengers?.pets}` },
                              ]}
                              isLoading={travelSearchConfigResult?.isLoading || routesResult?.isLoading || vehicleTypesResult.isLoading}
                              adults={vehicleTypesResult?.selectedPassengers?.adults ?? 1}
                              children={vehicleTypesResult?.selectedPassengers?.children ?? 0}
                              childrenAges={vehicleTypesResult?.selectedPassengers?.childrenAges}
                              pets={vehicleTypesResult?.selectedPassengers?.pets ?? 0}
                              maxPax={vehicleTypesResult?.maxNumberOfPassengers}
                              maxPets={vehicleTypesResult?.vehicleApiData?.maxNumberOfPets}
                              petsAllowed={vehicleTypesResult.petsAllowed}
                              selectedRoute={routesResult?.selectedRoute?.name ?? ''}
                              onQuantityChange={(adults: number, children: number, pets: number, childrenAges: number[]) => {
                                if (childrenAges && childrenAges.length < children) {
                                  childrenAges = [...childrenAges, 8]
                                } else if (childrenAges && childrenAges.length > children) {
                                  childrenAges = childrenAges.slice(0, children)
                                }
                                setSelectedPassengers({
                                  adults,
                                  children,
                                  pets,
                                  childrenAges: childrenAges || [],
                                })
                              }}
                              onFooterClick={() => setActiveSelectField(ActiveSelectField.NONE)}
                            />
                          }
                          onClick={(clickedOutside: boolean) => {
                            if (clickedOutside) {
                              setSelectedTrailerNoLongerAvailable(undefined)
                              setActiveSelectField(ActiveSelectField.NONE)
                            } else {
                              !routesResult?.isLoading && !vehicleTypesResult.isLoading && setActiveSelectField(ActiveSelectField.PASSENGER)
                            }
                          }}
                          onClose={() => {
                            activeSelectField === ActiveSelectField.PASSENGER && setActiveSelectField(ActiveSelectField.NONE)
                          }}
                        />
                      </div>
                    )}

                    {isFrsRoute(routesResult?.selectedRoute?.routeCode ?? '') && (
                      <div className="mobile:ts-w-full ts-grow">
                        <SelectField
                          dataAttrList={[{ name: 'selected-currency', value: `${selectedCurrency}` }]}
                          disabled={!isFrsRoute(routesResult.selectedRoute?.routeCode ?? '')}
                          title={t('CURRENCY')}
                          theme={dotcomTheme}
                          maxWidth="lg:ts-max-w-[140px]"
                          isLoading={travelSearchConfigResult?.isLoading || routesResult?.isLoading}
                          isMobile={isMobile}
                          isOpen={activeSelectField === ActiveSelectField.CURRENCY}
                          fieldName="currency"
                          value={mapToCurrencyToContentfulResource(selectedCurrency, t)}
                          icon={
                            <div className="ts-h-[30px] ts-w-[30px] ts-flex ts-justify-center ts-items-center ts-text-dfds-secondary-main ts-text-xl ts-font-DFDSBold">
                              {selectedCurrency === 'EUR' ? '€' : 'Dh'}
                            </div>
                          }
                          children={
                            <Currency
                              onSelectCurrency={(currency) => {
                                setSelectedCurrency(currency)
                                setActiveSelectField(ActiveSelectField.NONE)
                              }}
                            />
                          }
                          onClick={(clickedOutside: boolean) => {
                            if (clickedOutside) {
                              setActiveSelectField(ActiveSelectField.NONE)
                            } else {
                              !routesResult.isLoading && setActiveSelectField(ActiveSelectField.CURRENCY)
                            }
                          }}
                          onClose={() => {
                            activeSelectField === ActiveSelectField.ROUTE && setActiveSelectField(ActiveSelectField.NONE)
                          }}
                        />
                      </div>
                    )}
                    <div className="mobile:ts-w-full ts-grow">
                      <DateField
                        theme={dotcomTheme}
                        dataAttrList={[
                          {
                            name: 'selected-outbound-date',
                            value:
                              travelDatesResult?.selectedOutboundDate && `${format(travelDatesResult?.selectedOutboundDate, 'yyyy-MM-dd')}`,
                          },
                          {
                            name: 'selected-homebound-date',
                            value:
                              travelDatesResult?.selectedHomeboundDate &&
                              `${format(travelDatesResult?.selectedHomeboundDate, 'yyyy-MM-dd')}`,
                          },
                        ]}
                        isLoading={
                          travelSearchConfigResult?.isLoading ||
                          routesResult?.isLoading ||
                          vehicleTypesResult.isLoading ||
                          travelDatesResult.isLoading
                        }
                        disabled={
                          routesResult?.isOneway
                            ? travelDatesResult?.outboundTravelDates?.dates?.fromDate ===
                              travelDatesResult?.outboundTravelDates?.dates?.toDate
                            : travelDatesResult?.outboundTravelDates?.dates?.fromDate ===
                                travelDatesResult?.outboundTravelDates?.dates?.toDate &&
                              travelDatesResult?.homeboundTravelDates?.dates?.fromDate ===
                                travelDatesResult?.homeboundTravelDates?.dates?.toDate
                        }
                        showFooter={false}
                        returnFocused={returnFocused}
                        isOpen={activeSelectField === ActiveSelectField.DATE}
                        isOneway={routesResult?.isOneway}
                        fieldName="date"
                        selectedOutboundDate={travelDatesResult.selectedOutboundDate}
                        selectedHomeboundDate={travelDatesResult.selectedHomeboundDate}
                        containerRef={selectorContainer}
                        isFrsRoute={isFrsRoute(routesResult?.selectedRoute?.routeCode ?? '')}
                        onClick={(clickedOutside: boolean, clickedReturnField?: boolean) => {
                          if (
                            routesResult?.isOneway
                              ? !(
                                  travelDatesResult?.outboundTravelDates?.dates?.fromDate ===
                                  travelDatesResult?.outboundTravelDates?.dates?.toDate
                                )
                              : !(
                                  travelDatesResult?.outboundTravelDates?.dates?.fromDate ===
                                    travelDatesResult?.outboundTravelDates?.dates?.toDate &&
                                  travelDatesResult?.homeboundTravelDates?.dates?.fromDate ===
                                    travelDatesResult?.homeboundTravelDates?.dates?.toDate
                                )
                          ) {
                            if (oneway && clickedReturnField) {
                              setShowReturnChanged(true)
                              return
                            }

                            if (clickedOutside) {
                              setActiveSelectField(ActiveSelectField.NONE)
                              setReturnFocused(false)
                            } else {
                              !routesResult?.isLoading &&
                                !vehicleTypesResult.isLoading &&
                                !travelDatesResult.isLoading &&
                                setActiveSelectField(ActiveSelectField.DATE)
                            }
                          }
                        }}
                        onClose={() => {
                          activeSelectField === ActiveSelectField.DATE && setActiveSelectField(ActiveSelectField.NONE)
                          setReturnFocused(false)
                        }}
                      >
                        {routesResult?.isOneway && (
                          <DatePicker
                            locale={getDatepickerLocale()}
                            travelDates={travelDatesResult?.outboundTravelDates}
                            selectedDate={travelDatesResult.selectedOutboundDate}
                            onDateChanged={(date: Date | undefined) => {
                              if (date) {
                                setSelectedDates({ outboundDate: date, homeboundDate: undefined })
                                setActiveSelectField(ActiveSelectField.NONE)
                              }
                            }}
                          />
                        )}

                        {!routesResult?.isOneway && (
                          <DateRangePicker
                            locale={getDatepickerLocale()}
                            returnFocused={returnFocused}
                            isLoading={travelDatesResult.isLoading}
                            outboundTravelDates={travelDatesResult?.outboundTravelDates}
                            homeboundTravelDates={travelDatesResult?.homeboundTravelDates}
                            selectedDates={{
                              from: travelDatesResult.selectedOutboundDate,
                              to: travelDatesResult.selectedHomeboundDate,
                            }}
                            outboundDateChanged={(date: Date) => {
                              setSelectedDates({ outboundDate: date, homeboundDate: date })
                              setReturnFocused(true)
                            }}
                            returnDateChanged={(date: Date) => {
                              setSelectedDates({ outboundDate: travelDatesResult.selectedOutboundDate, homeboundDate: date })
                              setReturnFocused(false)
                              setActiveSelectField(ActiveSelectField.NONE)
                            }}
                            onClose={() => {
                              setActiveSelectField(ActiveSelectField.NONE)
                              setReturnFocused(false)
                            }}
                          />
                        )}
                      </DateField>
                    </div>
                    <OfferField offercode={selectedOffercode} setOffercode={setSelectedOffercode} />
                  </div>
                  <div className="ts-ml-auto ts-mt-auto ts-w-full lg:ts-w-fit">
                    <a
                      aria-disabled={isLoading || bookingUrlResult.url.includes('undefined')}
                      href={isLoading || bookingUrlResult.url.includes('undefined') ? 'javascript:void(0)' : bookingUrlResult.url}
                      onClick={() => {
                        if (isLoading || bookingUrlResult.url.includes('undefined')) {
                          return
                        }
                        TrackingCommand(
                          TravelSearchTrackingTypes.SUBMIT,
                          routesResult.selectedRoute,
                          { outboundDate: travelDatesResult.selectedOutboundDate, homeboundDate: travelDatesResult.selectedHomeboundDate },
                          vehicleTypesResult,
                          {
                            adults: vehicleTypesResult.selectedPassengers?.adults || 0,
                            children: vehicleTypesResult.selectedPassengers?.children || 0,
                            childrenAges: vehicleTypesResult.selectedPassengers?.childrenAges || [],
                            pets: vehicleTypesResult.selectedPassengers?.pets || 0,
                          },
                          selectedCurrency,
                          routesResult.isOneway
                        )
                      }}
                      data-e2e={isLoading || bookingUrlResult.url.includes('undefined') ? 'booking-link-loading' : 'booking-link'}
                      className={`${
                        isLoading || bookingUrlResult.url.includes('undefined')
                          ? 'ts-cursor-not-allowed ts-bg-dfds-divider-light ts-text-dfds-text-light-primary'
                          : 'ts-bg-dfds-status-warning ts-cursor-pointer ts-text-dfds-text-light-primary visited:ts-text-dfds-text-light-primary hover:ts-brightness-105 active:ts-brightness-95 hover:ts-text-dfds-text-light-primary'
                      } ts-dfds-label-bold ts-flex ts-justify-center ts-text-DFDS ts-items-center ts-px-dfds-s  ts-w-full ts-transition ts-border-0 ts-h-[50px] ts-min-w-full lg:ts-min-w-[150px] ts-box-border`}
                    >
                      {t('SEE-PRICES')}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )
        )}
      </motion.div>
    </div>
  )
}

export default TravelSearch

enum ActiveSelectField {
  ROUTE,
  VEHICLE,
  PASSENGER,
  DATE,
  CURRENCY,
  NONE,
}
