'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const MinusOutlined = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef} {...props}>
    <rect x="1" y="1" width="26" height="26" rx="13" fill="white" />
    <rect x="1" y="1" width="26" height="26" rx="13" stroke="#49A2DF" strokeWidth="2" />
    <path d="M7 13H21V15H7V13Z" fill="#49A2DF" />
  </svg>
))
export default MinusOutlined
