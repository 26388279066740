import { useTranslation } from 'react-i18next'
import SvgChevronLeft from '../../Icons/ChevronLeft'
import { Theme } from '../../Utils/theme'

interface Props {
  isOneway: boolean
  theme: Theme | null
  onSelected: (oneway: boolean) => void
  isMultiOffer: boolean
  onMultiOfferClicked: () => void
}

const getSelectedStyles = (isSelected: boolean) => {
  if (isSelected) return 'ts-bg-dfds-secondary-main ts-text-dfds-text-light-primary ts-font-DFDSBold'
  return 'ts-text-dfds-text-dark-disabled ts-bg-dfds-surface-light ts-font-DFDSBold'
}

const OnewayReturn: React.FC<Props> = ({ isOneway, theme, onSelected, isMultiOffer, onMultiOfferClicked }) => {
  const { t } = useTranslation()

  return (
    <>
      {isMultiOffer && (
        <div
          style={{ color: `${theme && theme.textColor}` }}
          data-e2e="multiOfferTab"
          onClick={() => onMultiOfferClicked()}
          className={`ts-flex ts-flex-none ts-cursor-pointer ts-items-center ts-justify-center ts-truncate ts-pb-dfds-xs ts-font-DFDSBold ts-text-dfds-text-light-primary`}
        >
          <SvgChevronLeft width="20" height="20" className="mr-dfds-xxs" />

          {t('GENERAL-BACK-TO-OFFERS')}
        </div>
      )}
      <div className="ts-flex ts-h-[40px] ts-w-full ts-cursor-pointer ts-flex-row ts-border ts-border-dfds-secondary-main ts-text-dfds-text-light-disabled">
        <div
          onClick={() => onSelected(false)}
          data-e2e="returnTab"
          data-e2e-selected={!isOneway}
          className={`ts-flex ts-flex-none ts-items-center ts-justify-center ts-truncate ${getSelectedStyles(!isOneway)} ts-w-[50%]`}
        >
          {t('RETURN')}
        </div>
        <div
          onClick={() => onSelected(true)}
          data-e2e="oneWayTab"
          data-e2e-selected={isOneway}
          className={`ts-flex ts-flex-none ts-items-center ts-justify-center ${getSelectedStyles(isOneway)} ts-w-[50%]`}
        >
          {t('ONE-WAY')}
        </div>
      </div>
    </>
  )
}

export default OnewayReturn
