'use client'

import { forwardRef, SVGProps, Ref } from 'react'
const StatusSuccess = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 2a8 8 0 100 16 8 8 0 000-16zm5.814 5.743l-7.071 7.071L6.5 12.571l1.414-1.414 2.828 2.827 5.657-5.656 1.414 1.414z"
    />
  </svg>
))

export default StatusSuccess
