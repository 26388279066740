import { forwardRef, SVGProps, Ref } from 'react'
const ReturnJourney = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M30 21.78l-2.01 3.84h-.3c-3.1 0-4.17 1.33-7.31 1.33-3.14 0-5.79-1.95-8.7-1.95-2.92 0-4.28.66-4.28.66a7.64 7.64 0 01-1.03-3.86L30 21.78zm-11.59-8.86l1.16 4.59h6.19l.6 1.05H23.7s-.22.01-.22.18c0 .17.01.32.04.5.03.19.11.38.45.46l3.48.76.34.58H10.07l1.63-3.53h2.19l2.49-4.6h2.03zm2.99 5.9h-.52c-.26 0-.48.17-.48.38v.72c0 .16.12.29.29.35.06.02.12.03.2.03h.5c.08 0 .14-.01.2-.03.17-.06.29-.2.29-.35v-.72c0-.2-.22-.38-.48-.38zm-2.96 0h-.51c-.27 0-.48.17-.48.38v.72c0 .16.12.29.29.35.06.02.12.03.19.03h.51c.07 0 .14-.01.2-.03.17-.06.29-.2.29-.35v-.72c0-.2-.22-.38-.49-.38zm-2.94 0h-.53c-.26 0-.47.17-.47.38v.72c0 .16.11.29.28.35.06.02.12.03.19.03h.53c.07 0 .13-.01.19-.03.17-.06.28-.2.28-.35v-.72c0-.14-.1-.26-.24-.32a.55.55 0 00-.23-.06zm.5-5.93c-.42.65-1 1.6-1.73 2.83-1.55.44-3.07 1.19-4.79 1.19-2.44 0-3.27-1.03-5.68-1.03h-.23L2 12.89h14zM12.6 6l1.93 3.57h1.7l1.27 2.75H3.72l.26-.45c.57-.13 2.49-.54 2.7-.6.27-.06.34-.2.36-.35.02-.14.03-.26.03-.4 0-.12-.17-.13-.17-.13H4.83l.47-.82h4.81l.9-3.57h1.59zm-3.51 4.6h-.4c-.2 0-.37.13-.37.29v.56c0 .12.09.22.22.27l.15.02h.4c.05 0 .1 0 .15-.02.13-.05.22-.15.22-.27v-.56c0-.16-.16-.3-.37-.3zm2.3 0h-.4c-.2 0-.38.13-.38.29v.56c0 .12.1.22.23.27l.15.02h.4c.05 0 .1 0 .15-.02.13-.05.22-.15.22-.27v-.56c0-.16-.17-.3-.37-.3zm2.3 0h-.41a.42.42 0 00-.18.04c-.11.05-.19.14-.19.25v.56c0 .12.09.22.22.27l.15.02h.41c.05 0 .1 0 .15-.02.13-.05.22-.15.22-.27v-.56c0-.16-.17-.3-.37-.3z"
    />
  </svg>
))
export default ReturnJourney
