import { ReactNode } from 'react'

interface Props {
  as: 'body' | 'bodyBold' | 'label' | 'labelSmall' | 'labelBold' | 'smallHeadline' | 'headline' | 'caption'
  children: string | ReactNode
  className?: string
}

const getFontClasses = (as: string) => {
  if (as === 'body') return 'ts-font-verdana ts-text-sm'
  if (as === 'bodyBold') return 'ts-font-verdana ts-text-sm'
  if (as === 'label') return 'ts-font-DFDS'
  if (as === 'labelSmall') return 'ts-font-DFDS ts-text-xs'
  if (as === 'labelBold') return 'ts-font-DFDSBold'
  if (as === 'smallHeadline') return 'ts-font-DFDSBold ts-text-xl'
  if (as === 'headline') return 'ts-font-DFDSBold ts-text-2xl'
  if (as === 'caption') return 'ts-font-DFDS ts-text-xs'
}

const Typograhy: React.FC<Props> = ({ as, className, children }) => {
  return <div className={`${getFontClasses(as)} ${className}`}>{children}</div>
}

export default Typograhy
