import { isBefore, isValid, parseISO } from 'date-fns'

export const LoadDataFromParams = () => {
  const { searchParams } = new URL(window.location.href)

  const routeCode = (searchParams.get('routeCode')?.toUpperCase() as string) || undefined
  const vehicleCode = (searchParams.get('vehicleCode') as string) || undefined
  const trailerCode = (searchParams.get('trailerCode') as string) || undefined
  const adults = Number(searchParams.get('adults') as string) || undefined
  const childrenAges = searchParams.getAll('chdAges')
  const pets = Number(searchParams.get('pets') as string) || undefined
  const outboundDate = searchParams.get('outboundDate') || undefined
  const homeboundDate = searchParams.get('homeboundDate') || undefined
  const oneWay = searchParams.get('oneWay') ? (searchParams.get('oneWay') as string) === 'true' : undefined
  const minibookId = searchParams.get('minibookId') || undefined
  const offerCode = searchParams.get('offerCode') || undefined

  return {
    queryData: {
      routeCode,
      vehicleCode,
      trailerCode,
      adults,
      childrenAges: childrenAges.map((age) => Number(age)),
      pets,
      outboundDate:
        outboundDate && !isBefore(parseISO(outboundDate), new Date()) && isValid(parseISO(outboundDate)) ? outboundDate : undefined,
      homeboundDate:
        homeboundDate &&
        isValid(parseISO(homeboundDate)) &&
        !isBefore(parseISO(homeboundDate), outboundDate ? parseISO(outboundDate) : new Date())
          ? homeboundDate
          : undefined,
      oneWay: oneWay,
      minibookId,
      offerCode,
    },
  }
}
