import { useState, useEffect, RefObject } from 'react'

export const useElementHeight = (ref: RefObject<HTMLDivElement>) => {
  const [height, setHeight] = useState<number>(0)

  useEffect(() => {
    const updateHeight = () => {
      if (ref.current) {
        setHeight(ref.current.clientHeight)
      }
    }

    const observer = new ResizeObserver(updateHeight)
    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [ref])

  return height
}
