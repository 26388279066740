'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SvgPaxTrailers = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="1em" height="1em" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.56 17a2.5 2.5 0 012.52 2.5 2.51 2.51 0 11-2.52-2.5zm.01 1.2a1.3 1.3 0 100 2.6 1.3 1.3 0 000-2.6zm12.24-4.14l-.07 3.4c.07.13.1.28.09.42l-.02.12H30v1.93H16.17v-.39a3.56 3.56 0 00-7.1-.2l-.02.2v.4H3.94A1.93 1.93 0 012 18.16v-4.03c3.8-.05 11.96-.06 17.98-.07h4.83zM20.8 9a2 2 0 012 2v2.02H3.72V11c0-1.1.9-2 2-2H20.8z"
    />
  </svg>
))
export default SvgPaxTrailers
