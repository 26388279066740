import { useTranslation } from 'react-i18next'
import { motion } from 'framer-motion'
import {Button} from '@dfds-pax/ui'
import { Theme } from '../Utils/theme'

interface Props {
  shown: boolean
  theme: Theme | null
  offersShown: boolean
  onClick: () => void
}

const StickyButton: React.FC<Props> = ({ shown, theme, offersShown, onClick }) => {
  const height = 82
  const { t } = useTranslation()

  return (
    <motion.div
      style={{ backgroundColor: `${theme && theme.backgroundColor}` }}
      initial={{
        top: shown ? 0 : -height,
      }}
      animate={{
        top: shown ? 0 : -height,
      }}
      transition={{ type: 'spring', bounce: 0, duration: shown ? 0.4 : 0 }}
      className={`ts-fixed ts-left-0 ts-right-0 ts-top-0 ts-z-50 ts-flex ts-w-full ts-flex-row ts-bg-dfds-primary-main ts-p-dfds-s`}
    >
      <Button variation="Primary" className="ts-ml-auto ts-max-w-[150px]" onClick={onClick} bold={true}>
        {offersShown ? t('SEE-OFFERS') : t('SEE-PRICES')}
      </Button>
    </motion.div>
  )
}

export default StickyButton
