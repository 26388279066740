import Bicycle from '../../Icons/Bicycle'
import Car from '../../Icons/Car'
import CarHigh from '../../Icons/CarHigh'
import CarHighRoofBox from '../../Icons/CarHighRoofBox'
import Minibus from '../../Icons/Minibus'
import MotorHome from '../../Icons/MotorHome'
import Motorcycle from '../../Icons/Motorcycle'
import SvgMotorcycleSideCar from '../../Icons/MotorcycleSideCar'
import PrivateVan from '../../Icons/PrivateVan'
import SportsCar from '../../Icons/SportsCar'
import WalkOn from '../../Icons/WalkOn'

interface Props {
  code: string
  width?: string
  height?: string
}

const VehicleIcon: React.FC<Props> = ({ code, width = '36', height = '36' }) => {
  const svgProps = { width, height }
  return (
    <>
      {code === 'NCAR' && <WalkOn {...svgProps} />}
      {code === 'TWOWHEELS' && <Motorcycle {...svgProps} />}
      {['LARGEVEHICLE', 'MIBUS', 'MINBUS'].includes(code) && <Minibus {...svgProps} />}
      {code === 'CAR' && <Car {...svgProps} />}
      {code === 'BIKV' && <Bicycle {...svgProps} />}
      {code === 'MOC' && <Motorcycle {...svgProps} />}
      {code === 'MOCS' && <SvgMotorcycleSideCar {...svgProps} />}
      {['CARB', 'CARH'].includes(code) && <CarHigh {...svgProps} />}
      {['CARLW', 'CARS'].includes(code) && <SportsCar {...svgProps} />}
      {code === 'CARZ' && <CarHighRoofBox {...svgProps} />}
      {['CARX', 'PVANB', 'PVANM'].includes(code) && <PrivateVan {...svgProps} />}
      {['MCVB', 'MCVM', 'MCVX', 'MCVS'].includes(code) && <MotorHome {...svgProps} />}
    </>
  )
}

export default VehicleIcon
