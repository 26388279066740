import '@dfds-pax/ui/styles.css'
import './globals.css'

import { SWRConfig } from 'swr'
import ErrorBoundary from './ErrorBoundary'
import AppWithContent from './AppWithContent'
import { getSessionId } from './Utils/common'
import { LogProps } from '@dfds-pax/logging-provider'
import { getClientEnvironment, logger } from '@dfds-pax/common'

const App = () => {
  return (
    <ErrorBoundary>
      <SWRConfig
        value={{
          onError: (error) => {
            // Ignore network errors, i.e. frontend having problems contacting edge functions (swr throws 'failed to fetch' error often when this happens , hence lots of entries in log)
            // 'Failed to fetch' is window and linux specific
            // 'Load failed' is apple specific
            if (['Failed to fetch', 'Load failed'].includes(error.message)) return
            const logBody: LogProps = {
              message: error?.message,
              messageTemplate: 'Error from SWR config',
              level: 'Error',
              fields: {
                correlationId: error?.correlationId,
                ...{ sessionId: getSessionId() },
                ...(error?.status && { status: error?.status }),
                application: 'travel-search',
                environment: getClientEnvironment(),
                frontend: true,
                page: window.location.pathname,
                userAgent: navigator.userAgent,
                error: {
                  error: error,
                  stackTrace: error?.stack ?? error?.stackTrace ?? undefined,
                },
              },
            }
            logger('Error', getClientEnvironment(), logBody)
          },
        }}
      >
        <AppWithContent />
      </SWRConfig>
    </ErrorBoundary>
  )
}

export default App
