import Counter from './Counter'
import { useTranslation } from 'react-i18next'
import ReactMarkdown from 'react-markdown'
import Typograhy from '../Shared/Typograhy'
import debounce from 'lodash.debounce'
import { SelectedPassengers } from '../../Hooks'
import { useCallback, useEffect, useState } from 'react'
import OverlayFooter from '../Shared/OverlayFooter'

interface Props {
  adults: number
  children: number
  pets: number
  maxPax: number
  maxPets: number
  petsAllowed: boolean
  childrenAges?: number[]
  selectedRoute: string
  isLoading: boolean
  dataAttrList: { name: string; value: string }[]
  onFooterClick: () => void
  onQuantityChange: (adults: number, children: number, pets: number, childrenAges: number[]) => void
}

const Passengers: React.FC<Props> = ({
  adults,
  children,
  pets,
  maxPax,
  maxPets,
  petsAllowed,
  childrenAges,
  selectedRoute,
  isLoading,
  dataAttrList,
  onFooterClick,
  onQuantityChange,
}) => {
  const { t } = useTranslation()

  useEffect(() => {
    if (selectedPassengers.pets > maxPets) {
      debounceQuantityChange(selectedPassengers.adults, selectedPassengers.children, maxPets, selectedPassengers.childrenAges)
      setSelectedPassengers({ ...selectedPassengers, pets: maxPets })
    }
  }, [maxPets])

  useEffect(() => {
    if (!petsAllowed && selectedPassengers.pets > 0) {
      debounceQuantityChange(selectedPassengers.adults, selectedPassengers.children, 0, selectedPassengers.childrenAges)
      setSelectedPassengers({ ...selectedPassengers, pets: 0 })
    }
  }, [petsAllowed])

  useEffect(() => {
    if (maxPax && selectedPassengers.adults + selectedPassengers.children > maxPax) {
      const _children = maxPax - selectedPassengers.adults
      children = _children > -1 ? _children : 0
      childrenAges = childrenAges?.slice(0, children) ?? []
      adults = maxPax - children
      setSelectedPassengers({ ...selectedPassengers, adults, children, childrenAges })
    }
  }, [maxPax])

  const [selectedPassengers, setSelectedPassengers] = useState<SelectedPassengers>({
    adults: adults,
    children: children,
    pets: pets,
    childrenAges: childrenAges || [],
  })

  const debounceQuantityChange = useCallback(
    debounce((adults: number, children: number, pets: number, childrenAges: number[]) => {
      onQuantityChange(adults, children, pets, childrenAges)
    }, 400),
    []
  )

  function onChange(value: any, field: 'PETS' | 'ADULTS' | 'CHILDREN') {
    setSelectedPassengers({
      adults: field === 'ADULTS' ? value : selectedPassengers.adults,
      children: field === 'CHILDREN' ? value : selectedPassengers.children,
      pets: field === 'PETS' ? value : selectedPassengers.pets,
      childrenAges:
        field === 'CHILDREN'
          ? selectedPassengers.childrenAges && selectedPassengers.childrenAges.length < value
            ? [...selectedPassengers.childrenAges, ...[...Array(value - selectedPassengers.childrenAges.length)].map(() => 8)]
            : selectedPassengers.childrenAges.slice(0, value)
          : selectedPassengers.childrenAges,
    })

    if (field === 'ADULTS') {
      debounceQuantityChange(
        limit(value, 1, maxPax - selectedPassengers.children),
        selectedPassengers.children,
        selectedPassengers.pets,
        selectedPassengers.childrenAges
      )
    }
    if (field === 'CHILDREN') {
      debounceQuantityChange(
        selectedPassengers.adults,
        limit(value, 0, maxPax - selectedPassengers.adults),
        selectedPassengers.pets,
        selectedPassengers.childrenAges && selectedPassengers.childrenAges.length < value
          ? [...selectedPassengers.childrenAges, ...[...Array(value - selectedPassengers.childrenAges.length)].map(() => 8)]
          : selectedPassengers.childrenAges.slice(0, value)
      )
    }
    if (field === 'PETS') {
      debounceQuantityChange(
        selectedPassengers.adults,
        selectedPassengers.children,
        limit(value, 0, maxPets),
        selectedPassengers.childrenAges
      )
    }
  }

  function onChildrenAgeChange(value: number[]) {
    setSelectedPassengers({
      ...selectedPassengers,
      childrenAges: value,
    })
    debounceQuantityChange(selectedPassengers.adults, selectedPassengers.children, selectedPassengers.pets, value)
  }

  const limit = (value: number, min: number, max: number) => Math.min(Math.max(value, min), max)

  const getPassengerErrorMessage = (maxPax: number, route: string): string => {
    const key = `PASSENGERS-ERRORMESSAGE-${route}`
    const routeErrorMessage = t(key)
    if (routeErrorMessage && routeErrorMessage !== key) return routeErrorMessage
    return t('PASSENGERS-ERRORMESSAGE', { n1: maxPax })
  }

  let dataAttrProps = {} as {
    dataAttrName: string
    dataAttrValue: string
  }
  dataAttrList.map((dataAttr) => {
    const key = `data-e2e-${dataAttr?.name}`
    const dataAttrKey = key as keyof {
      dataAttrName: string
      dataAttrValue: string
    }
    dataAttrProps[dataAttrKey] = dataAttr.value?.toString() ?? ''
  })
  const keyLoading = `data-e2e-passenger-loading`
  const dataAttrKeyLoading = keyLoading as keyof {
    dataAttrName: string
    dataAttrValue: string
  }
  dataAttrProps[dataAttrKeyLoading] = isLoading.toString()

  return (
    <div className="ts-min-w-[260px] ts-p-dfds-s ts-text-dfds-text-dark-primary md:ts-min-w-[320px]">
      <ul className="ts-p-0 ts-m-0 ts-border-0 ts-border-solid ts-list-none">
        <li
          data-e2e="li-adults"
          className="ts-border-0 ts-border-solid ts-list-none ts-p-0 ts-m-0 ts-flex ts-h-[50px] ts-flex-row ts-items-center"
        >
          <Counter
            e2eAttribute="adults"
            title={t('ADULTS')}
            description="+16"
            value={selectedPassengers.adults}
            downDisabled={selectedPassengers.adults === 1}
            upDisabled={selectedPassengers.adults + selectedPassengers.children === maxPax}
            onValueChange={(quantity: number) => {
              quantity = Number(quantity)
              if (quantity < 1) quantity = 1
              if (quantity > maxPax - selectedPassengers.children) quantity = maxPax - selectedPassengers.children
              onChange(quantity, 'ADULTS')
            }}
          />
        </li>
        <li data-e2e="li-children" className="ts-p-0 ts-m-0 ts-list-none ts-flex ts-h-[50px] ts-flex-row ts-items-center">
          <Counter
            e2eAttribute="children"
            title={t('CHILDREN')}
            description="0-15"
            value={selectedPassengers.children}
            downDisabled={selectedPassengers.children === 0}
            upDisabled={selectedPassengers.adults + selectedPassengers.children === maxPax}
            onValueChange={(quantity: number) => {
              quantity = Number(quantity)
              if (quantity < 1) quantity = 0
              if (quantity > maxPax - selectedPassengers.adults) quantity = maxPax - selectedPassengers.adults
              onChange(quantity, 'CHILDREN')
            }}
          />
        </li>
        {selectedPassengers.childrenAges?.map((age, i) => (
          <li data-e2e="li-child-age" key={i} className="ts-p-0 ts-m-0 ts-list-none ts-flex ts-h-[50px] ts-flex-row ts-items-center">
            <Counter
              e2eAttribute={`child-age-${i + 1}`}
              key={i}
              description={t('CHILD-AGE', { n1: i + 1 })}
              value={age}
              downDisabled={age === 0}
              upDisabled={age === 15}
              onValueChange={(age) => {
                age = Number(age)
                const arr = [...selectedPassengers.childrenAges]
                arr[i] = limit(age, 0, 15)
                if (age < 1) age = 0
                if (age > 15) age = 15
                onChildrenAgeChange(arr)
              }}
            />
          </li>
        ))}
        {petsAllowed ? (
          <li data-e2e="li-pets" className="ts-p-0 ts-m-0 ts-list-none ts-flex ts-h-[50px] ts-flex-row ts-items-center">
            <Counter
              e2eAttribute="pets"
              title={t('PETS')}
              value={selectedPassengers.pets}
              downDisabled={selectedPassengers.pets === 0}
              upDisabled={selectedPassengers.pets === maxPets}
              onValueChange={(quantity: number) => {
                quantity = Number(quantity)
                if (quantity < 1) quantity = 0
                if (quantity > maxPets) quantity = maxPax
                onChange(quantity, 'PETS')
              }}
            />
          </li>
        ) : (
          <li data-e2e="pets-not-allowed" className="ts-p-0 ts-m-0 ts-list-none">
            <Typograhy as="label" className="ts-mb-dfds-s ts-pt-dfds-xs ts-text-dfds-status-alert">
              {t('PETS-ERRORMESSAGE')}
            </Typograhy>
          </li>
        )}

        {selectedPassengers.pets === maxPets && petsAllowed && (
          <li data-e2e="pets-error-message" className=" ts-p-0 ts-m-0 ts-list-none">
            <Typograhy as="label" className="ts-mb-dfds-s ts-leading-relaxed ts-text-dfds-status-alert">
              {t('NO-MORE-PETS-ERRORMESSAGE', { n1: maxPets })}
            </Typograhy>
          </li>
        )}
        {selectedPassengers.adults + selectedPassengers.children === maxPax && (
          <li data-e2e="pax-error-message" className="ts-p-0 ts-m-0 ts-list-none">
            <Typograhy as="body" className="ts-phone-number ts-mb-dfds-s ts-leading-relaxed ts-text-dfds-status-alert ">
              <ReactMarkdown>{getPassengerErrorMessage(maxPax, selectedRoute)}</ReactMarkdown>
            </Typograhy>
          </li>
        )}
      </ul>
      <OverlayFooter onClick={onFooterClick}>
        <div className="my-dfds-xs">
          <Typograhy as="label" className="ts-overflow-hidden ts-text-ellipsis ts-whitespace-nowrap">
            <span {...dataAttrProps}>{`${t('PASSENGERS').trim()}: ${
              isLoading ? '...' : selectedPassengers.adults + selectedPassengers.children
            }`}</span>
          </Typograhy>
          {selectedPassengers?.pets > 0 && (
            <Typograhy as="label" className="ts-my-dfds-xxs ts-overflow-hidden ts-text-ellipsis ts-whitespace-nowrap">
              <span>{`${t('PETS').trim()}: ${isLoading ? '...' : selectedPassengers.pets}`}</span>
            </Typograhy>
          )}
        </div>
      </OverlayFooter>
    </div>
  )
}

export default Passengers
