import useSWR from 'swr'
import { fetcher, getBaseApi, getLocaleFromUrlOrQuery, getUrlParts, isPreviewUrl } from '../Utils/common'
import { ProductListSections, TravelSearchConfigResponse } from '../../api/travel-search-config'
import { isValidOfferDomain } from '../Components/OfferField'

const baseApi = getBaseApi()

export const useTravelSearchConfig = (): UseTravelSearchConfigResult => {
  const previewId = isPreviewUrl()
  const parts = getUrlParts()
  const locale = getLocaleFromUrlOrQuery()
  let urlSlug = parts.slice(1).join('/') || '/'

  if (
    (window.location.href.includes('localhost:5001') || isValidOfferDomain()) &&
    window.location.pathname.includes('/passenger-ferries/travel')
  ) {
    urlSlug = 'passenger-ferries'
  }
  const previewIdString = previewId ? `&previewId=${previewId}` : ''
  const url = `${baseApi}/travel-search-config?locale=${locale}&urlSlug=${urlSlug}${previewIdString}`

  const { data, isLoading } = useSWR(url, { fetcher })

  const travelSearchConfigData = data as TravelSearchConfigResponse

  return {
    isLoading,
    isMultiOffer: travelSearchConfigData?.isMultiOffer,
    availableMinibooks: travelSearchConfigData?.availableMinibooks,
    optionalOfferTitle: travelSearchConfigData?.optionalOfferTitle,
    route: travelSearchConfigData?.route,
    theme: travelSearchConfigData?.theme,
  }
}

export interface UseTravelSearchConfigResult {
  isLoading: boolean
  isMultiOffer: boolean
  availableMinibooks: ProductListSections[] | string
  optionalOfferTitle?: string
  route?: string
  theme?: string
}
