import { getContentfulLocale, getClientEnvironment, logger } from '@dfds-pax/common'
import { LogProps } from '@dfds-pax/logging-provider'
import { v4 as uuidv4 } from 'uuid'
import { getLocaleFromUrlOrQuery } from './common'

const Content = async (): Promise<Result> => {
  const environment = getClientEnvironment()
  const dotcomSpace = environment === 'uat' ? 'staging' : 'master'
  const urlLocale = getLocaleFromUrlOrQuery()
  const contentfulLocale = getContentfulLocale(urlLocale)

  const contentTaskUrl = new URL('https://cdn.contentful.com/spaces/z860498abl84/environments/master/entries')
  contentTaskUrl.searchParams.append('content_type', 'paxObResource')
  contentTaskUrl.searchParams.append('locale', contentfulLocale)
  contentTaskUrl.searchParams.append('fields.applications', 'travel-search')
  contentTaskUrl.searchParams.append('limit', '300')

  const contentTask = httpClient(contentTaskUrl.toString(), {
    Authorization: 'Bearer cf3eb41c6603c064c84111798dd42d288c8e0262017997f894653da20797e665',
  })

  const urlSlugList = ['334Z2FPTYI8OMsYC6K6mg8']

  const urlSlugTaskUrl = new URL(`https://cdn.contentful.com/spaces/mivicpf5zews/environments/${dotcomSpace}/entries`)
  urlSlugTaskUrl.searchParams.append('content_type', 'urlSlug')
  urlSlugTaskUrl.searchParams.append('locale', contentfulLocale)
  urlSlugList.map((id) => urlSlugTaskUrl.searchParams.append('sys.id[in]', id))
  urlSlugTaskUrl.searchParams.append('limit', '1000')

  const urlSlugsTask = httpClient(urlSlugTaskUrl.toString(), {
    Authorization: 'Bearer ffb6d2e75bdeb11580a166b1856d1b183e180c6da95a97998ab50cd12ad9eb85',
  })

  const [contentResult, urlSlugsResult]: [ContentResult, UrlSlugsResult] = await Promise.all([contentTask, urlSlugsTask])

  const result: Result = {
    content: Object.assign(
      {},
      ...contentResult.items.map((item: ContentItem) => {
        return { [item.fields.entryTitle]: item.fields.value }
      })
    ),
    urlSlugs: urlSlugsResult.items.map((item: UrlSlugItem) => {
      return {
        id: item.sys.id,
        slug: item.fields.slug,
        locale: item.sys.locale.toLowerCase(),
      }
    }),
  }

  return result
}

export default Content

async function httpClient(url: string, headers?: HeadersInit) {
  const requestOptions: RequestInit = {
    method: 'GET',
    headers: {
      ...headers,
    },
  }

  return await fetch(url, requestOptions)
    .then(async (response: Response) => {
      if (!response.ok) {
        let responseObj
        let jsonError
        try {
          responseObj = await response.json()
        } catch (error) {
          jsonError = 'could not parse response body as json in http-client'
        }
        const errorMessage =
          responseObj?.message || responseObj?.Message || responseObj?.title || jsonError || 'Message did not exist in error response'
        const httpClientError = new Error(errorMessage)
        throw httpClientError
      }
      return response.json()
    })
    .catch((error: any) => {
      let properties: string[] = []
      let propertiesWithValues = ''

      // Get all properties, including non-enumerable ones
      const listProperties = Object.getOwnPropertyNames(error)

      // Log each property and its value
      listProperties.forEach((property) => {
        if (error[property]) propertiesWithValues += ` ${property}: ${error[property]}`
        properties.push(property)
      })

      var payload: LogProps = {
        message: `${error?.message} | props with value on error obj: ${propertiesWithValues?.trim()} | props on error obj: ${properties?.join(', ')}`,
        messageTemplate: 'Error getting content from Contentful',
        level: 'Error',
        fields: {
          correlationId: uuidv4(),
          application: 'travel-search',
          environment: getClientEnvironment(),
          frontend: true,
          path: window.location.pathname,
          referer: document.referrer,
          userAgent: navigator.userAgent,
          error: { error: error?.name, stackTrace: error?.componentStack },
        },
      }
      logger('Error', getClientEnvironment(), payload)
    })
}

export interface ContentItem {
  fields: {
    entryTitle: string
    value: any
  }
}

export interface UrlSlugItem {
  sys: {
    id: string
    locale: string
  }
  fields: {
    slug: string
  }
}

export interface ContentResult {
  items: ContentItem[]
}

export interface UrlSlugsResult {
  items: UrlSlugItem[]
}

export interface Result {
  content: { [key: string]: any }
  urlSlugs: UrlSlug[]
}

export interface UrlSlug {
  slug: string
  locale: string
  id: string
}
