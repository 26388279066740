/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const WalkOn = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="1em" height="1em" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.03 8.85a3.02 3.02 0 013.02 2.8l.45 7.05a1 1 0 01-.56.9l-1 .44h-.11l-1.36 9.62c-.1.23-.22.34-.44.34h-.75a.32.32 0 01-.18-.04.47.47 0 01-.1-.14l-.29-.5-.28.5a.47.47 0 01-.1.14.24.24 0 01-.16.04h-.81c-.23 0-.45-.11-.45-.34l-1.34-9.62-.26-1.59-.54.72c-.51.73-1.02 1.06-1.7.81l-.17-.06c-.7-.25-1.06-1.01-.81-1.7l2.26-5.4 1.28-3.1c.22-.46.37-.87 1.43-.87zM10.77 27.33c.73 0 1.31.6 1.3 1.32v.01a1.32 1.32 0 11-1.3-1.33zm-2.04-6.81c.39-.7 1.25-.95 1.95-.58l2.88 1.57c.16.09.3.21.42.36L15 20.02c.2.2.44.35.7.45l.07.03-2.2 4-1.52 2.8a1.88 1.88 0 00-3.14 1.05l-2.17-1.18a1.44 1.44 0 01-.57-1.94zM22.66 2a2.91 2.91 0 11-2.9 2.92c0-1.62 1.3-2.92 2.9-2.92z"
    />
  </svg>
))
export default WalkOn
