'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SvgCar2 = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path d="M17.7 8c0.436 0 0.812 0 1.11 0h0.35c0.259-0.001 0.506 0.108 0.68 0.3l0.39 0.47 3.58 4.37h3.19c1.657 0 3 1.343 3 3v2.19c0.533 0.032 0.946 0.476 0.94 1.010v0.080c0 0.552-0.448 1-1 1h-2c-0.195-1.853-1.757-3.259-3.62-3.259s-3.425 1.406-3.62 3.259h-9.46c-0.195-1.853-1.757-3.259-3.62-3.259s-3.425 1.406-3.62 3.259h-0.93c-0.552 0-1-0.448-1-1v-0.080c0-0.552 0.448-1 1-1h-1.070c-0.552 0-1-0.448-1-1v-5.24c0-0.552 0.448-1 1-1h0.070c0.552 0 1 0.448 1 1v-2.1c-0.001-1.078 0.853-1.962 1.93-2 0 0 0.453 0 1.2 0v-1.54c0.005-0.127 0.061-0.247 0.155-0.334s0.218-0.132 0.345-0.126h10.5c0.263-0.006 0.484 0.197 0.5 0.46v1.54zM16.7 8v-1h-9.5v1c2.536 0 6.712 0 9.5 0zM24.35 18.28c0.69-0.013 1.356 0.248 1.853 0.726s0.784 1.134 0.797 1.824c-0.001 1.395-1.123 2.53-2.518 2.548s-2.545-1.089-2.581-2.484c-0.036-1.395 1.055-2.559 2.449-2.614zM24.35 22.16c0.747 0.011 1.364-0.583 1.38-1.33-0-0.728-0.586-1.32-1.314-1.329s-1.328 0.569-1.346 1.297c-0.018 0.728 0.552 1.335 1.28 1.362zM7.65 18.28c1.408 0 2.55 1.142 2.55 2.55s-1.142 2.55-2.55 2.55c-1.408 0-2.55-1.142-2.55-2.55s1.142-2.55 2.55-2.55zM7.65 22.16c0.736-0.005 1.334-0.595 1.35-1.33-0-0.732-0.592-1.326-1.323-1.33s-1.329 0.585-1.337 1.317c-0.007 0.732 0.578 1.332 1.31 1.343zM4.1 10v2.55c0 0.55 0.38 0.59 0.93 0.59h7.35v-4.14h-7.28c-0.552 0-1 0.448-1 1zM13.41 9v4.14h10l-4.12-4.14h-5.88z"></path>
  </svg>
))
export default SvgCar2
