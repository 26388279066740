'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SvgCar3 = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M6.2 8V7H4.7a2 2 0 110-4h13a4 4 0 014 4h-4v1h1.46a.913.913 0 01.68.3l.39.47 3.58 4.37H27a3 3 0 013 3v2.19a1 1 0 01.94 1.01v.08a1 1 0 01-1 1h-2a3.64 3.64 0 00-7.24 0h-9.46a3.64 3.64 0 00-7.24 0h-.93a1 1 0 01-1-1v-.08a1 1 0 011-1H2a1 1 0 01-1-1V12.1a1 1 0 011-1h.07a1 1 0 011 1V10A2 2 0 015 8h1.2zm1 0h9.5V7H7.2v1zm17.15 10.28A2.601 2.601 0 0127 20.83a2.55 2.55 0 11-2.65-2.55zm0 3.88a1.36 1.36 0 001.38-1.33 1.33 1.33 0 10-1.38 1.33zm-16.7-3.88a2.55 2.55 0 110 5.1 2.55 2.55 0 010-5.1zm0 3.88A1.36 1.36 0 009 20.83a1.33 1.33 0 10-1.35 1.33zM4.1 10v2.55c0 .55.38.59.93.59h7.35V9H5.1a1 1 0 00-1 1zm9.31-1v4.14h10L19.29 9h-5.88z"
    />
  </svg>
))
export default SvgCar3
