'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SvgTrailer = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M31.084 18.887v2.13H15.878v-.43a3.917 3.917 0 00-7.824 0v.43H2.438a2.117 2.117 0 01-2.13-2.129v-4.245c5.477-.079 19.213-.079 25.069-.079l-.077 3.728a.867.867 0 01.077.596h5.705zm-19.167-1.104a2.745 2.745 0 012.77 2.759 2.76 2.76 0 11-2.769-2.758zm0 4.194a1.434 1.434 0 10.024-2.87 1.434 1.434 0 00-.025 2.871zM4.207 9h16.966a2 2 0 012 2v2.414H2.207V11a2 2 0 012-2z"
    />
  </svg>
))
export default SvgTrailer
