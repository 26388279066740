'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SvgBus = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M6.994 21.916a1.256 1.256 0 100-2.511 1.256 1.256 0 000 2.511zm-2.412-1.255a2.413 2.413 0 114.824 0 2.413 2.413 0 01-4.823 0zm18.848-2.413a2.413 2.413 0 110 4.826 2.413 2.413 0 010-4.826zm0 3.668a1.256 1.256 0 100-2.511 1.256 1.256 0 000 2.511zm1.599-7.606c.361.086 6.619 2.005 6.619 2.005a.42.42 0 01.328.408v3.362a.42.42 0 01-.418.418l-5.063-.062a3.076 3.076 0 00-6.143.042H10.296l-.249-.185a3.077 3.077 0 10-6.113.717l-.256.203-3.68-.374C.112 20.654-.002 8-.002 8h30.236c2.142 0 1.726 1.616 1.741 2.242h-7.698s-.587.023-.587.473.039 1.903.112 2.386c.073.483.308.991 1.226 1.208zm-3.045-.089v-3.832c0-.065-.077-.118-.171-.118H1.894c-.094 0-.171.053-.171.118v3.832c0 .065.077.118.171.118h19.919c.094 0 .171-.053.171-.118z"
    />
  </svg>
))
export default SvgBus
