/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SvgMotorcycleSideCar = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M17.702 20.987c1.28-1.153 2.041-2.597 2.041-4.163 0-2.401-1.786-4.512-4.488-5.732l2.047-2.043a.155.155 0 01.106-.042h4.649l-.917-1.928h-2.748a.154.154 0 01-.154-.154v-.771c0-.085.069-.153.154-.153h3.439c.061 0 .114.035.139.087 0 0 2.528 5.293 3.903 8.091a4.83 4.83 0 11-1.128.477l-1.148-2.412-4.757 8.666a.153.153 0 01-.134.078h-1.003zM1.964 12.186V10.32c0-.085.068-.153.153-.153l7.429-.001c.031 0 .059.009.083.024l2.203 1.403a.16.16 0 00.084.025h1.064c-2.88 3.205-6.824 3.528-10.995.553a.347.347 0 00-.02.015zm25.206 9.159a2.514 2.514 0 100-5.028 2.514 2.514 0 000 5.028z"
    />
    <path
      fill="currentColor"
      d="M3.853 21.935C1.517 20.793 0 18.949 0 16.869c0-1.502.791-2.881 2.111-3.961 1.328 1.154 3.173 1.869 5.214 1.869 2.855 0 5.329-1.4 6.537-3.444 2.96 1.058 4.973 3.141 4.973 5.536s-2.013 4.479-4.974 5.537l.001-.084a5.011 5.011 0 00-10.008-.387zm5.036-3.491a3.556 3.556 0 11-.001 7.111 3.556 3.556 0 01.001-7.111zm.053 5.445c.982 0 1.837-.796 1.837-1.778a1.778 1.778 0 10-3.556 0c0 .982.737 1.778 1.719 1.778z"
    />
  </svg>
))
export default SvgMotorcycleSideCar
