'use client'

import { forwardRef, SVGProps, Ref } from 'react'
const StatusWarning = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 34 32" ref={svgRef} {...props}>
    <path
      id="colour1"
      d="M15.5364 12.0791C15.3989 12.0791 15.305 12.1024 15.2519 12.1466C15.1978 12.1925 15.1722 12.272 15.1722 12.3886L15.4681 19.7697C15.4681 19.8862 15.4947 19.9666 15.5478 20.0116C15.6 20.0558 15.6958 20.0791 15.8314 20.0791H18.0395C18.1771 20.0791 18.2719 20.0558 18.3241 20.0116C18.3781 19.9666 18.4037 19.8862 18.4037 19.7697L18.7006 12.3886C18.7006 12.272 18.6731 12.1925 18.621 12.1466C18.5669 12.1024 18.473 12.0791 18.3364 12.0791H15.5364ZM18.3364 25.0791C18.473 25.0791 18.5669 25.0533 18.621 24.9987C18.6741 24.9441 18.7006 24.8492 18.7006 24.7104V22.1136C18.7006 21.9757 18.6741 21.8799 18.621 21.8253C18.5669 21.7736 18.473 21.7458 18.3364 21.7458H15.5364C15.3999 21.7458 15.305 21.7736 15.2519 21.8253C15.1988 21.8799 15.1722 21.9757 15.1722 22.1136V24.7104C15.1722 24.8492 15.1988 24.9441 15.2519 24.9987C15.305 25.0533 15.3999 25.0791 15.5364 25.0791H18.3364ZM17.0816 2.7021C17.1214 2.72462 17.1546 2.75604 17.1785 2.79357L33.6163 28.6753C33.6965 28.8016 33.6532 28.9654 33.5195 29.0411C33.4756 29.066 33.4254 29.0791 33.3743 29.0791H0.498582C0.342687 29.0791 0.216309 28.9597 0.216309 28.8125C0.216309 28.7641 0.230213 28.7167 0.256535 28.6753L16.6944 2.79357C16.7746 2.66728 16.948 2.62633 17.0816 2.7021ZM16.9364 7.59513L4.98498 26.4125H28.8865L16.9364 7.59513Z"
    />
  </svg>
))

export default StatusWarning
