'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const Phone = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 20 20" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M13.5897 0.833328H6.41022C5.17175 0.833328 4.16663 1.76666 4.16663 2.91666V17.0833C4.16663 18.2333 5.17175 19.1667 6.41022 19.1667H13.5897C14.8282 19.1667 15.8333 18.2333 15.8333 17.0833V2.91666C15.8333 1.76666 14.8282 0.833328 13.5897 0.833328ZM9.99996 18.3333C9.25509 18.3333 8.74996 17.775 8.74996 17.0833C8.74996 16.3917 9.25509 15.8333 9.99996 15.8333C10.7448 15.8333 11.25 16.3917 11.25 17.0833C11.25 17.775 10.7448 18.3333 9.99996 18.3333ZM14.1666 15H5.83329V3.33333H14.1666V15Z"
    />
  </svg>
))

export default Phone
