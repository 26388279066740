/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const Minibus = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M7.763 21.498a1.218 1.218 0 100-2.435 1.218 1.218 0 000 2.435zm0-3.558a2.34 2.34 0 110 4.68 2.34 2.34 0 010-4.68zm17.175 0a2.34 2.34 0 110 4.68 2.34 2.34 0 010-4.68zm0 3.558a1.218 1.218 0 100-2.435 1.218 1.218 0 000 2.435zm5.743-6.461c.182.04.319.203.319.396v4.289a.406.406 0 01-.405.405l-2.661-.061a2.985 2.985 0 00-5.957.04H10.989l-.242-.179a2.985 2.985 0 10-5.929.695l-.248.197-3.569-.363c.111-.184 0-12.457 0-12.457H19.76c2.077 0 5.29 1.718 6.108 2.174h-4.282s-.57.022-.57.459.038.818.109 1.287c.07.469.298.961 1.189 1.172.35.083 8.369 1.945 8.369 1.945zm-12.147-1.003v-3.717a.116.116 0 00-.115-.115H3.626a.116.116 0 00-.115.115v3.717c0 .063.052.115.115.115h14.793a.116.116 0 00.115-.115z"
    />
  </svg>
))
export default Minibus
