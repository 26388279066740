'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const ArrowForward = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M13.522 6l5.399 4.986H19v.073l1 .925-1 .906v.091l-.099-.001L13.367 18 12 16.561l3.867-3.581L4 12.981v-1.995h11.872l-3.751-3.488L13.522 6z"
    />
  </svg>
))
export default ArrowForward
