'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const PaxWithPet = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.1376 8.91561C27.7174 8.9121 29.0324 10.1284 29.1494 11.7047L29.5956 18.7326C29.593 19.111 29.3774 19.456 29.0376 19.6242L28.0343 20.0704H27.9225L26.5838 29.6638C26.4721 29.8873 26.3612 29.9991 26.1376 29.9991L25.3737 30C25.3059 30 25.2311 29.9868 25.1976 29.9604C25.1633 29.934 25.1272 29.8873 25.0903 29.8187L24.8077 29.3223L24.5252 29.8187C24.4874 29.8873 24.4522 29.934 24.4178 29.9604C24.3844 29.9868 24.3333 30 24.2656 30L23.4603 29.9991C23.2376 29.9991 23.0149 29.8873 23.0149 29.6638L21.6762 20.0704H21.5645L20.5602 19.6242C20.2003 19.4798 19.9749 19.119 20.0022 18.7326L20.2258 11.7047C20.3428 10.1284 21.6578 8.9121 23.2376 8.91561H26.1376ZM24.5758 2C26.1777 2 27.4758 3.29819 27.4758 4.90003C27.4758 6.50186 26.1777 7.80005 24.5758 7.80005C22.974 7.80005 21.6758 6.50186 21.6758 4.90003C21.6758 3.29819 22.974 2 24.5758 2Z"
      fill="currentColor"
    />
    <path
      d="M15.8665 19.6181L17.5158 17.8195C17.5307 17.8047 17.5508 17.7964 17.5717 17.7964C17.5927 17.7964 17.6128 17.8047 17.6276 17.8195L17.9768 18.1688C17.9916 18.1837 17.9999 18.2038 17.9999 18.2247C17.9999 18.2457 17.9916 18.2658 17.9768 18.2806L16.5323 20.411L16.4534 29.467C16.4523 29.5433 16.4059 29.6074 16.3347 29.6074L15.8165 29.6115C15.7824 29.6132 15.7489 29.602 15.7228 29.5799C15.6968 29.5579 15.68 29.5267 15.6761 29.4928L14.7324 24.2693H9.4999L9.44221 29.4933C9.44819 29.5646 9.40204 29.6079 9.33078 29.6138L8.80538 29.6111C8.77105 29.6139 8.73701 29.603 8.7107 29.5807C8.6844 29.5585 8.66796 29.5268 8.66498 29.4924L7.70916 24.2019C7.69831 24.1829 7.69253 24.1614 7.69238 24.1395L6.41067 22.0971L8.90597 19.618L15.8665 19.6181Z"
      fill="currentColor"
    />
    <path
      d="M2.03441 19.8861L3.21253 18.7079L5.03626 16.8842L4.35193 15.7292C4.33716 15.7144 4.32886 15.6943 4.32886 15.6733C4.32886 15.6524 4.33716 15.6323 4.35193 15.6174L4.5711 15.398C4.58597 15.3833 4.60606 15.375 4.627 15.375C4.64795 15.375 4.66804 15.3833 4.6829 15.398L5.69446 16.4096L8.07874 18.7906L5.60468 21.2822L5.16642 20.8454L4.93396 21.0778C4.80958 21.2022 4.66191 21.3009 4.49939 21.3682C4.33686 21.4356 4.16267 21.4702 3.98675 21.4702C3.81084 21.4702 3.63665 21.4356 3.47412 21.3682C3.3116 21.3009 3.16393 21.2022 3.03955 21.0778L2.03436 20.0534C2.01234 20.0311 1.99998 20.001 1.99999 19.9697C2 19.9384 2.01237 19.9083 2.03441 19.8861Z"
      fill="currentColor"
    />
    <path d="M12.8793 14.0002L13.6935 14.8145L8.89778 19.6102L8.0835 18.7959L12.8793 14.0002Z" fill="currentColor" />
  </svg>
))
export default PaxWithPet
