import { useEffect, useState } from 'react'
import { lockScroll, unlockScroll } from '../Utils/scrolling'

export const useKeepScrollPosition = (isMobileOrTablet: boolean, isOpen: boolean) => {
  const [currentPosition, setCurrentPosition] = useState<number>(0)

  useEffect(() => {
    if (!isMobileOrTablet) {
      unlockScroll()
      return
    }

    if (isMobileOrTablet && isOpen) {
      setCurrentPosition(window.scrollY)
      lockScroll()
      return
    }

    if (isMobileOrTablet && !isOpen) {
      const classNameOnTag: string = document.getElementsByTagName('html')[0].classList.value

      if (classNameOnTag.includes('locked')) {
        unlockScroll()
        window.scrollTo(0, currentPosition)
      }
    }
  }, [isOpen])
}
