'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const Mastercard = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg width="48" height="32" viewBox="0 0 48 32" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef} {...props}>
    <g id="mastercard-light-large">
      <rect id="card_bg" width="48" height="32" rx="4" fill="#DFE3E8" />
      <g id="mastercard">
        <g id="Group" opacity="0.01">
          <rect id="Rectangle" x="4.65" y="3.2" width="38.71" height="25.6" fill="white" />
        </g>
        <rect id="Rectangle_2" x="18.9" y="7.66" width="10.21" height="16.68" fill="#F26122" />
        <path
          id="Path"
          d="M20 16C19.9831 12.7535 21.4577 9.67905 24 7.66C19.6503 4.23929 13.402 4.73602 9.6473 8.801C5.89264 12.866 5.89264 19.134 9.6473 23.199C13.402 27.264 19.6503 27.7607 24 24.34C21.4577 22.3209 19.9831 19.2465 20 16Z"
          fill="#EA1D25"
        />
        <path
          id="Shape"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.14 22.23V22.57H40.08V22.23H39.94V22.16H40.29V22.23H40.14ZM40.82 22.16V22.57H40.75V22.26L40.64 22.53H40.56L40.44 22.26V22.57H40.37V22.16H40.47L40.6 22.45L40.72 22.16H40.82Z"
          fill="#F69E1E"
        />
        <path
          id="Path_2"
          d="M41.16 16C41.1574 20.0593 38.8389 23.7614 35.1879 25.5358C31.5369 27.3103 27.1935 26.846 24 24.34C28.6001 20.718 29.3967 14.0543 25.78 9.45C25.2616 8.78327 24.6638 8.18218 24 7.66C27.1935 5.154 31.5369 4.68974 35.1879 6.46417C38.8389 8.2386 41.1574 11.9407 41.16 16Z"
          fill="#F69E1E"
        />
      </g>
    </g>
  </svg>
))

export default Mastercard
