'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SvgCalendarReturn = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M17.5 2a1 1 0 011 1v2h.5a2 2 0 012 2v15.5H3V7a2 2 0 012-2h.5V3a1 1 0 012 0v2h9V3a1 1 0 011-1zm2 7h-15v12h15V9zm-8.002 2l1.002 1.215-2.541 2.034 7.341.001c.11 0 .2.09.2.2v1.1a.2.2 0 01-.2.2l-7.343-.001 2.543 2.036L11.498 19 6.5 15l4.998-4z"
    />
  </svg>
))
export default SvgCalendarReturn
