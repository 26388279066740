import SvgChevronRight from '../../Icons/ChevronRight'
import VehicleIcon from './VehicleIcon'
import { useTranslation } from 'react-i18next'
import Typograhy from '../Shared/Typograhy'

interface Props {
  isVehicleMandatory: boolean
  categories: string[]
  selectedCategoryCode: string | undefined
  walkonIsSelected: boolean
  onSelectCategory: (categoryCode: string) => void
}

const Categories: React.FC<Props> = ({ isVehicleMandatory, categories, selectedCategoryCode, walkonIsSelected, onSelectCategory }) => {
  const { t } = useTranslation()

  return (
    <>
      {!selectedCategoryCode && (
        <ul data-e2e="vehicle-categories" data-travel-search-child className='ts-border-0 ts-border-solid ts-list-none ts-p-0 ts-m-0'>
          {categories.map((category, index) => (
            <li
              data-e2e={`vehicle-category-${category}`}
              key={category}
              className={` ts-list-none ts-p-0 ts-m-0 ts-flex ts-h-[70px] ts-border-solid ts-border-0 ts-cursor-pointer ts-items-center  ts-bg-dfds-surface-light ts-pr-dfds-s lg:hover:ts-bg-dfds-listitem-hover ${
                index === categories.length - 1 && isVehicleMandatory ? 'ts-border-b-0' : 'ts-border-b ts-border-b-dfds-divider-dark'
              }`}
              onClick={() => {
                onSelectCategory(category)
              }}
            >
              <div className="imgBlue ts-flex ts-h-full ts-w-[70px] ts-items-center ts-justify-center">
                {<VehicleIcon width="40" height="40" code={category} />}
              </div>
              <Typograhy as="labelBold" className="ts-text-dfds-text-dark-primary">
                {t(`VEHICLE-CATEGORY-${category}`)}
              </Typograhy>
              <div className="imgBlue ts-ml-auto">
                <SvgChevronRight width="24" height="24" />
              </div>
            </li>
          ))}
          {!isVehicleMandatory && (
            <li
              data-e2e="vehicle-NCAR"
              key="walkon"
              className={`ts-border-0 ts-border-solid ts-list-none ts-p-0 ts-m-0 ts-flex ts-h-[70px] ts-cursor-pointer ts-items-center ts-border-b-dfds-divider-dark ts-pr-dfds-s lg:hover:ts-bg-dfds-listitem-hover ${
                walkonIsSelected ? 'ts-bg-dfds-listitem-selected' : 'ts-bg-dfds-surface-light'
              }`}
              onClick={() => {
                onSelectCategory('NCAR')
              }}
            >
              <div className="imgBlue ts-flex ts-h-full ts-w-[70px] ts-items-center ts-justify-center">
                <VehicleIcon width="40" height="40" code="NCAR" />
              </div>
              <Typograhy as="labelBold">{t('VEHICLE-CATEGORY-FOOTPASSENGER')}</Typograhy>
            </li>
          )}
        </ul>
      )}
    </>
  )
}

export default Categories
