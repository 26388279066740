import { useTranslation } from 'react-i18next'
import Typograhy from '../Shared/Typograhy'

interface Props {
  onSelectCurrency: (currency: 'EUR' | 'MAD') => void
}

const Currency: React.FC<Props> = ({ onSelectCurrency }) => {
    const { t } = useTranslation()

  return (
    <div className="ts-bg-dfds-surface-light ">
      <ul className="lg:ts-w-[188px] ts-border-0 ts-border-solid ts-list-none ts-p-0 ts-m-0">
      <li
              data-e2e={`currency-euro}`}
              key={'euro'}
              className={`ts-list-none ts-p-0 ts-m-0 ts-flex ts-h-[70px] ts-border-solid ts-border-0 ts-cursor-pointer ts-items-center  ts-bg-dfds-surface-light ts-pr-dfds-s lg:hover:ts-bg-dfds-listitem-hover ts-border-b ts-border-b-dfds-divider-dark
              }`}
              onClick={() => onSelectCurrency('EUR')}
            >
              <Typograhy as="labelBold" className="ts-text-dfds-text-dark-primary ts-px-dfds-xs">
                {t('EURO')}
              </Typograhy>
            </li>
            <li
              data-e2e={`currency-dirham}`}
              key={'dirham'}
              className={`ts-list-none ts-p-0 ts-m-0 ts-flex ts-h-[70px] ts-border-solid ts-border-0 ts-cursor-pointer ts-items-center  ts-bg-dfds-surface-light ts-pr-dfds-s lg:hover:ts-bg-dfds-listitem-hover
              }`}
              onClick={() => onSelectCurrency('MAD')}
            >
              <Typograhy as="labelBold" className="ts-text-dfds-text-dark-primary  ts-px-dfds-xs">
                {t('DIRHAM')}
              </Typograhy>
            </li>
      </ul>
    </div>
  )
}

export default Currency
