import { useEffect, useState } from 'react'
import { Promo } from '@dfds-pax/ui'

interface Props {
  offercode: string
  setOffercode: (offerCode: string) => void
}

const OfferField: React.FC<Props> = ({ offercode, setOffercode }) => {
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    if (isValidOfferDomain()) {
      setShow(true)
    }
  }, [])

  return (
    <>
      {show && (
        <div className="mobile:ts-w-full ts-grow">
          <div className="ts-relative ts-h-[50px] ts-grow lg:ts-w-full" id="offer-field-container">
            <div className={`ts-relative ts-mb-dfds-xs ts-flex ts-h-full ts-flex-row ts-items-center ts-bg-white md:ts-mb-0 grow`}>
              <div style={{ color: '#49a2df' }} className={`ts-flex ts-h-full ts-w-[48px] ts-flex-none ts-items-center ts-justify-center`}>
                <Promo width={25} height={25} />
              </div>
              <div className={`ts-flex ts-h-full ts-w-full ts-flex-col ts-justify-center ts-pr-dfds-s`}>
                <label className={`ts-w-full ts-truncate ts-font-DFDS ts-text-xs ts-leading-none ts-text-dfds-text-dark-primary`}>
                  Offercode
                </label>
                <input
                  type="text"
                  className={`ts-text-[100%] ts-m-0 ts-p-0 ts-flex-none ts-border-none ts-font-DFDSBold ts-leading-none focus:ts-outline-none ts-text-dfds-text-dark-primary ts-uppercase cursor-text truncate`}
                  onChange={(e) => setOffercode(e.target.value)}
                  value={offercode}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default OfferField

export const isValidOfferDomain = () => !window.location.href.includes('dfds.com') && !window.location.host.includes('app-travel-search-')
