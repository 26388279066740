/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SvgCalendarOutbound = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M17.5 2a1 1 0 011 1v2h.5a2 2 0 012 2v15.5H3V7a2 2 0 012-2h.5V3a1 1 0 012 0v2h9V3a1 1 0 011-1zm2 7h-15v12h15V9zm-6.998 2l4.998 4-4.998 4-1.002-1.215 2.542-2.036-7.342.001a.2.2 0 01-.2-.2v-1.1c0-.11.09-.2.2-.2l7.34-.001-2.54-2.034L12.502 11z"
    />
  </svg>
))
export default SvgCalendarOutbound
