import { useTranslation } from 'react-i18next'
import Typograhy from '../Shared/Typograhy'
import { toDateString } from '../../Utils'

interface Props {
  isOneway: boolean
  outboundDate: Date
  homeboundDate?: Date
  isLoading: boolean
  onClick: () => void
  children: React.ReactNode
}

const OverlayFooter: React.FC<Props> = ({ outboundDate, homeboundDate, children, isLoading, isOneway }) => {
  const { t } = useTranslation()

  return (
    <div
      id="footer-content"
      className="ts-fixed ts-bottom-0 ts-left-0 ts-right-0 ts-z-50 ts-mt-auto ts-flex ts-h-[80px] ts-min-h-[80px] ts-justify-center ts-border-solid ts-border-t ts-border-gray-300 ts-bg-white ts-px-dfds-s md:ts-relative lg:ts-hidden"
    >
      <div className="ts-flex ts-w-full ts-flex-col ts-justify-center">
        <div className="ts-flex ts-flex-row ts-items-center">{children}</div>
        <div className="ts-flex ts-flex-row ts-items-center ts-justify-center">
          <div className="ts-flex ts-w-1/2 ts-flex-none ts-flex-col ts-items-center ts-justify-center ts-overflow-hidden ts-px-dfds-s">
            <Typograhy as="caption">{t('OUTBOUND')}</Typograhy>
            {outboundDate && (
              <Typograhy as="labelBold" className="ts-text-dfds-text-dark-primary">
                {isLoading ? '...' : toDateString(outboundDate)}
              </Typograhy>
            )}
            {!outboundDate && (
              <Typograhy as="labelBold" className="ts-text-dfds-text-dark-primary">
                {'-'}
              </Typograhy>
            )}
          </div>
          <div className="ts-h-[100%] ts-border-l ts-border-gray-300 ts-border-solid"></div>
          <div className="ts-flex ts-w-1/2 ts-flex-none ts-flex-col ts-items-center ts-justify-center ts-overflow-hidden ts-px-dfds-s">
            <Typograhy as="caption" className={isOneway ? 'ts-text-dfds-text-dark-disabled' : 'ts-text-dfds-text-dark-primary'}>
              {t('INBOUND')}
            </Typograhy>
            {homeboundDate && (
              <Typograhy as="labelBold" className={isOneway ? 'ts-text-dfds-text-dark-disabled' : 'ts-text-dfds-text-dark-primary'}>
                {isLoading ? '...' : toDateString(homeboundDate)}
              </Typograhy>
            )}
            {!homeboundDate && (
              <Typograhy className={isOneway ? 'ts-text-dfds-text-dark-disabled' : 'ts-text-dfds-text-dark-primary'} as="labelBold">
                {'-'}
              </Typograhy>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default OverlayFooter
