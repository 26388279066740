'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const PaymentFailedIcon = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="113" height="78" viewBox="0 0 113 78" ref={svgRef} {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 9.48386C0 8.37929 0.895431 7.48386 2 7.48386H97.7849C98.8895 7.48386 99.7849 8.37929 99.7849 9.48386V22.4516H0V9.48386ZM0 32.4301H99.7849V75.3333C99.7849 76.4379 98.8895 77.3333 97.7849 77.3333H2C0.895434 77.3333 0 76.4379 0 75.3333V32.4301ZM89.8062 47.3979H9.97825V57.3764H89.8062V47.3979Z"
      fill="#4D4E4C"
      fillOpacity="0.3"
    />
    <path d="M0 20.4559H100.284V33.9268H0V20.4559Z" fill="#4D4E4C" fillOpacity="0.7" />
    <circle cx="94.9613" cy="17.9613" r="17.9613" fill="#BE1E2D" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M93.7141 17.9613L86.2302 25.4451L87.9942 27.2091L95.4781 19.7252L102.445 26.6925L104.209 24.9285L97.242 17.9613L103.87 11.3334L102.106 9.56946L95.4781 16.1973L88.3336 9.05281L86.5696 10.8168L93.7141 17.9613Z"
      fill="white"
    />
  </svg>
))

export default PaymentFailedIcon
