import { Portal } from 'react-portal'
import { motion } from 'framer-motion'
import { ReactNode } from 'react'

export type ModalProps = {
  isOpen: boolean
  onCloseRequested: () => void
  children: ReactNode
}

const Overlay: React.FC<ModalProps> = ({ isOpen, onCloseRequested, children }) => {
  return (
    <Portal>
      <div id="travel-search">
        <motion.div
          id="travel-search-overlay"
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{ type: 'spring', bounce: 0, duration: 0.2 }}
          onClick={(e) => e.target == document.getElementById('overlay') && onCloseRequested()}
          className={`${isOpen && 'ts-fixed ts-bottom-0 ts-left-0 ts-right-0 ts-top-0 ts-z-modal ts-flex ts-items-center ts-justify-center ts-bg-black/50'}`}
        >
          {children}
        </motion.div>
      </div>
    </Portal>
  )
}

export default Overlay
