'use client'

import { forwardRef, SVGProps, Ref } from 'react'
const Basket = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" ref={svgRef} {...props}>
    <path d="M20 10l-0.91 9.099c-0.051 0.511-0.481 0.9-0.995 0.9h-12.19c-0.514 0-0.944-0.389-0.995-0.9l-0.91-9.099h16zM11 12h-1v6h1v-6zM14 12h-1v6h1v-6zM17 12h-1v6h1v-6zM8 12h-1v6h1v-6zM11 2h2v5h8v2h-18v-2h8v-5z"></path>
  </svg>
))

export default Basket
