import SvgArrowBack from '../../Icons/ArrowBack'
import Dimensions from './Dimensions'
import VehicleIcon from './VehicleIcon'
import SvgChevronDown from '../../Icons/ChevronDown'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Tooltip from '../Shared/Tooltip'
import { motion } from 'framer-motion'
import { AvailableTrailer, VehicleType, useWindowWidth } from '../../Hooks'
import Typograhy from '../Shared/Typograhy'

interface Props {
  vehicleTypes: VehicleType[]
  selectedVehicle?: string | undefined
  availableTrailers: AvailableTrailer[]
  selectedTrailer?: AvailableTrailer | undefined
  selectedTrailerNoLongerAvailable?: AvailableTrailer | undefined
  onSelectVehicle: (vehicleCode: string) => void
  onSelectTrailer: (trailer: AvailableTrailer | undefined) => void
  onSelectCategory: (categoryCode: string | undefined) => void
}

const Selector: React.FC<Props> = ({
  vehicleTypes,
  selectedVehicle,
  availableTrailers,
  selectedTrailer,
  selectedTrailerNoLongerAvailable,
  onSelectVehicle,
  onSelectTrailer,
  onSelectCategory,
}) => {
  const [showTrailerSelector, setShowTrailerSelector] = useState<boolean>(false)

  const { widthSmallerThan1025 } = useWindowWidth()

  const { t } = useTranslation()

  const trailerDropdownRef = useRef<HTMLDivElement>(null)
  return (
    <>
      {vehicleTypes?.length > 0 && (
        <>
          <ul data-travel-search-child className="ts-p-0 ts-m-0 ts-border-0 ts-border-solid ts-list-none">
            <div
              data-e2e="vehicle-categories-selector"
              className="imgBlue ts-flex ts-cursor-pointer ts-items-center ts-bg-dfds-surface-light ts-p-dfds-s"
              onClick={() => onSelectCategory(undefined)}
            >
              <SvgArrowBack width="24" height="24" className="ts-mr-dfds-xxs" />
              <Typograhy as="labelBold">{t('VEHICLE-CATEGORIES')}</Typograhy>
            </div>
            {vehicleTypes.map((vehicleType) => (
              <li
                data-e2e={`vehicle-type-${vehicleType.code}`}
                key={vehicleType.code}
                className={`ts-p-0 ts-m-0 ts-list-none ts-flex ts-h-[90px] ts-cursor-pointer ts-items-center ts-border-0 ts-border-b ts-border-solid ts-border-dfds-divider-dark ts-px-dfds-s lg:hover:ts-bg-dfds-listitem-hover
                  ${vehicleType.code === selectedVehicle ? 'ts-bg-dfds-listitem-selected' : 'ts-bg-dfds-surface-light'}
                `}
                onClick={() => {
                  onSelectVehicle(vehicleType.code)
                }}
              >
                <div className="imgBlue">
                  <Dimensions height={vehicleType.height} length={vehicleType.length}>
                    <VehicleIcon code={vehicleType.code} width="40" height="40" />
                  </Dimensions>
                </div>
                <div className="ts-ml-dfds-s ts-flex ts-flex-col">
                  <Typograhy as="labelBold">{t(`VEHICLE-NAME-${vehicleType.code}`)}</Typograhy>
                  <Typograhy as="body" className="ts-text-sm">
                    {t(`VEHICLE-DESCRIPTION-${vehicleType.code}`)}
                  </Typograhy>
                </div>
              </li>
            ))}
          </ul>
          <>
            {availableTrailers?.length > 0 && (
              <div className="ts-bg-dfds-surface-light">
                <div className="imgBlue ts-flex ts-cursor-pointer ts-items-center ts-bg-dfds-surface-light ts-p-dfds-s">
                  <Tooltip message={t('TRAILER-CARAVAN-TIP')}>{t('TRAILER-CARAVAN')}</Tooltip>
                </div>
                <div
                  className={`ts-mx-dfds-s ts-mb-dfds-s ts-border ts-border-solid ts-border-dfds-secondary-main ts-bg-dfds-surface-light ${
                    selectedTrailerNoLongerAvailable && 'ts-border-dfds-status-alert'
                  }`}
                >
                  <div
                    data-e2e="trailer-selector"
                    ref={trailerDropdownRef}
                    className="imgBlue ts-flex ts-cursor-pointer ts-items-center ts-bg-dfds-surface-light ts-p-dfds-s"
                    onClick={() => {
                      setShowTrailerSelector(!showTrailerSelector)
                      if (widthSmallerThan1025) {
                        setTimeout(() => {
                          trailerDropdownRef.current?.firstElementChild?.scrollIntoView()
                        }, 200)
                      }
                    }}
                  >
                    <Typograhy as="label">
                      <span data-e2e-sel-trailer={selectedTrailer ? selectedTrailer.code : 'NO-TRAILER'}>
                        {selectedTrailer
                          ? `${t(`TRAILER-NAME-${selectedTrailer.code}`)} < ${selectedTrailer.height}m x ${selectedTrailer.length}m`
                          : t('NO-TRAILER-CARAVAN')}
                      </span>
                    </Typograhy>
                    <motion.div className="ts-ml-auto" animate={{ rotate: showTrailerSelector ? -180 : 0 }}>
                      <SvgChevronDown width="24" height="24" />
                    </motion.div>
                  </div>
                  {showTrailerSelector && (
                    <ul
                      data-travel-search-child
                      className={`${!showTrailerSelector} imgBlue ts-p-0 ts-m-0 ts-border-0 ts-border-solid ts-list-none`}
                    >
                      <>
                        <li
                          data-e2e="no-trailer"
                          className={`ts-p-0 ts-m-0 ts-border-0 ts-list-none ts-cursor-pointer ts-border-b ts-border-t ts-border-solid ts-border-dfds-divider-dark ts-py-dfds-s lg:hover:ts-bg-dfds-listitem-hover
                          ${!selectedTrailer ? 'ts-bg-dfds-listitem-selected' : 'ts-bg-dfds-surface-light'}
                        `}
                          key="no-trailer"
                          onClick={() => {
                            onSelectTrailer(undefined)
                            setShowTrailerSelector(false)
                          }}
                        >
                          <Typograhy className="ts-mx-dfds-s ts-text-sm ts-text-dfds-text-dark-primary" as="body">
                            {t('NO-TRAILER-CARAVAN')}
                          </Typograhy>
                        </li>

                        {availableTrailers.map((trailer) => (
                          <li
                            data-e2e={`trailer-${trailer.code}`}
                            className={`ts-p-0 ts-m-0 ts-border-0 ts-list-none ts-cursor-pointer ts-border-b ts-border-solid ts-border-dfds-divider-dark ts-py-dfds-s lg:hover:ts-bg-dfds-listitem-hover
                            ${selectedTrailer === trailer ? 'ts-bg-dfds-listitem-selected' : 'ts-bg-dfds-surface-light'}
                          `}
                            key={trailer.code}
                            onClick={() => {
                              onSelectTrailer(trailer)
                              setShowTrailerSelector(false)
                            }}
                          >
                            <Typograhy className="ts-mx-dfds-s ts-text-sm ts-text-dfds-text-dark-primary" as="body">
                              {`${t(`TRAILER-NAME-${trailer.code}`)} <`} {trailer.height}m x {trailer.length}m
                            </Typograhy>
                          </li>
                        ))}
                      </>
                    </ul>
                  )}
                </div>
                {selectedTrailerNoLongerAvailable && (
                  <Typograhy className="ts-bg-dfds-surface-light ts-px-dfds-s ts-pb-dfds-s ts-text-dfds-status-alert" as="label">
                    <span data-e2e="incompatible-trailer">
                      {t('INCOMPATIBLE-CAR-TRAILER-ERROR-MESSAGE', {
                        trailerType: `${t(`TRAILER-NAME-${selectedTrailerNoLongerAvailable.code}`)} < ${selectedTrailerNoLongerAvailable.height}m x ${
                          selectedTrailerNoLongerAvailable.length
                        }m`,
                        vehicleType: t(`VEHICLE-NAME-${selectedVehicle}`),
                      })}
                    </span>
                  </Typograhy>
                )}
              </div>
            )}
          </>
        </>
      )}
    </>
  )
}

export default Selector
