import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { delay } from './Utils/common'
import { getClientEnvironment, logger } from '@dfds-pax/common'
import { LogProps } from '@dfds-pax/logging-provider'
import { v4 as uuidv4 } from 'uuid'

const ms = 2000

window.addEventListener('reload-travelsearch', async () => {
  const travelSearchRoot = document.getElementById('travel-search-root')
  let cnt = 1
  while (cnt <= 5) {
    await delay(ms)
    const travelSearch = document.getElementById('travel-search') // only load travel search if it's not already loaded
    cnt++
    if (travelSearch) break
    else {
      if (cnt === 5) {
        const correlationId = uuidv4()
        const payload: LogProps = {
          message: `Loading travel-search failed after ${cnt} attempts and ${(cnt * ms) / 1000} seconds`,
          messageTemplate: 'Error loading travel-search',
          level: 'Error',
          fields: {
            correlationId,
            application: 'travel-search',
            environment: getClientEnvironment(),
            frontend: true,
            path: window.location.pathname,
          },
        }
        logger('Error', getClientEnvironment(), payload)
      }
      !travelSearch &&
        travelSearchRoot &&
        ReactDOM.createRoot(travelSearchRoot).render(
          <React.StrictMode>
            <App />
          </React.StrictMode>
        )
    }
  }
})

ReactDOM.createRoot(document.getElementById('travel-search-root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
