import React from 'react'
import { useWindowWidth2 } from '../../Hooks/useWindowWidth2'
import { Minus, Plus } from '@dfds-pax/ui'

interface Props {
  title?: string
  description?: string
  value: number
  downDisabled: boolean
  upDisabled: boolean
  ageRange?: string
  e2eAttribute: string
  onValueChange: (value: number) => void
}

const Selector: React.FC<Props> = ({ title, value, description, downDisabled, upDisabled, e2eAttribute, onValueChange }) => {
  const { isMobile, isTablet } = useWindowWidth2()

  const [emptyCounterValue, setEmptyCounterValue] = React.useState<boolean>(false)

  const numberUpDownSize = isMobile || isTablet ? '32' : '24'

  return (
    <>
      <div className="ts-mr-auto">
        <div className="ts-dfds-label-bold">{title}</div>
        <div className="ts-dfds-body">{description}</div>
      </div>
      <div className="ts-inline-flex">
        <div className="ts-flex ts-flex-row ts-items-center">
          <button
            data-e2e={`${e2eAttribute}-down`}
            disabled={downDisabled}
            className="imgBlue ts-inline-flex ts-h-dfds-l ts-w-dfds-l ts-items-center ts-justify-center"
            onClick={() => {
              onValueChange(value - 1)
            }}
          >
            <Minus width={numberUpDownSize} height={numberUpDownSize} className={downDisabled ? 'ts-opacity-30' : 'ts-cursor-pointer'} />
          </button>
          <input
            data-e2e={`${e2eAttribute}-input`}
            className="lg:ts-mx-dfds-xs ts-h-[40px] ts-w-[40px] ts-appearance-none ts-border ts-border-dfds-text-dark-primary ts-bg-transparent ts-text-center ts-font-verdana tabletDown:ts-mx-dfds-s"
            type="number"
            value={emptyCounterValue ? '' : value}
            onKeyDown={(e: any) => e?.key === 'Backspace' && setEmptyCounterValue(true)}
            onFocus={() => setEmptyCounterValue(true)}
            onBlur={() => setEmptyCounterValue(false)}
            onChange={(e: any) => {
              if (!isNaN(e.target.value)) {
                onValueChange(e.target.value)
                setEmptyCounterValue(false)
              }
            }}
          />
          <button
            data-e2e={`${e2eAttribute}-up`}
            disabled={upDisabled}
            className="imgBlue ts-inline-flex ts-h-dfds-l ts-w-dfds-l ts-items-center ts-justify-center"
            onClick={() => {
              onValueChange(value + 1)
            }}
          >
            <Plus width={numberUpDownSize} height={numberUpDownSize} className={upDisabled ? 'ts-opacity-30' : 'ts-cursor-pointer'} />
          </button>
        </div>
      </div>
    </>
  )
}

export default Selector
