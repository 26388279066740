'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const Paypal = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg width="48" height="32" viewBox="0 0 48 32" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef} {...props}>
    <g id="paypal-light-large">
      <rect id="card_bg" width="48" height="32" rx="4" fill="#DFE3E8" />
      <g id="paypal">
        <path
          id="Path"
          d="M20.34 25.32L20.69 23.12H19.91H16.23L18.79 6.85999C18.7968 6.80961 18.8217 6.76342 18.86 6.72999C18.9002 6.69893 18.9492 6.68142 19 6.67999H25.21C27.28 6.67999 28.7 7.10999 29.45 7.95999C29.7818 8.31652 30.0131 8.75479 30.12 9.22999C30.2366 9.8075 30.2366 10.4025 30.12 10.98V11.48L30.47 11.68C30.7364 11.8128 30.9769 11.9923 31.18 12.21C31.4856 12.579 31.683 13.0255 31.75 13.5C31.8249 14.1202 31.7979 14.7485 31.67 15.36C31.5381 16.1028 31.2775 16.8168 30.9 17.47C30.5949 17.9936 30.1825 18.4469 29.69 18.8C29.1942 19.138 28.6423 19.3852 28.06 19.53C27.4063 19.6949 26.7342 19.7756 26.06 19.77H25.57C25.2266 19.77 24.894 19.8903 24.63 20.11C24.3646 20.3336 24.1903 20.6466 24.14 20.99V21.19L23.53 25.07V25.22C23.5372 25.2462 23.5372 25.2738 23.53 25.3H23.47L20.34 25.32Z"
          fill="#253D80"
        />
        <path
          id="Path_2"
          d="M30.8 11.08L30.74 11.45C29.92 15.65 27.11 17.11 23.53 17.11H21.71C21.2721 17.1095 20.8989 17.4276 20.83 17.86L19.9 23.78L19.63 25.46C19.6097 25.5945 19.6488 25.7312 19.737 25.8348C19.8252 25.9384 19.954 25.9986 20.09 26H23.33C23.7142 25.9997 24.0409 25.7196 24.1 25.34V25.18L24.71 21.31V21.1C24.7646 20.722 25.088 20.441 25.47 20.44H26C29.13 20.44 31.59 19.17 32.3 15.44C32.6842 14.1651 32.4472 12.7839 31.66 11.71C31.4102 11.4538 31.1196 11.2409 30.8 11.08V11.08Z"
          fill="#189BD7"
        />
        <path
          id="Path_3"
          d="M29.94 10.74L29.56 10.64L29.14 10.56C28.6105 10.4808 28.0754 10.444 27.54 10.45H22.66C22.546 10.4469 22.4329 10.4709 22.33 10.52C22.0981 10.6286 21.9365 10.8466 21.9 11.1L20.9 17.67V17.86C20.9689 17.4276 21.3421 17.1095 21.78 17.11H23.6C27.18 17.11 29.99 15.65 30.81 11.45L30.87 11.08C30.6546 10.9687 30.4306 10.875 30.2 10.8L29.94 10.74Z"
          fill="#242E65"
        />
        <path
          id="Path_4"
          d="M21.9 11.1C21.9365 10.8466 22.0981 10.6286 22.33 10.52C22.4329 10.4709 22.546 10.4469 22.66 10.45H27.54C28.0754 10.444 28.6105 10.4808 29.14 10.56L29.56 10.64L29.94 10.74L30.13 10.8C30.3606 10.8751 30.5846 10.9687 30.8 11.08C31.1251 9.83063 30.8271 8.50118 30 7.51C29 6.45 27.36 6 25.22 6H19C18.5621 5.99947 18.1889 6.31756 18.12 6.75L15.53 23.16C15.5068 23.3148 15.5519 23.472 15.6536 23.5909C15.7553 23.7098 15.9035 23.7788 16.06 23.78H19.9L20.9 17.67L21.9 11.1Z"
          fill="#253D80"
        />
      </g>
    </g>
  </svg>
))

export default Paypal
