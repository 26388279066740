import { useTranslation } from 'react-i18next'
import { AvailableTrailer, VehicleType } from '../../Hooks'
import OverlayFooter from '../Shared/OverlayFooter'
import Typograhy from '../Shared/Typograhy'
import Categories from './Categories'
import Selector from './Selector'

interface Props {
  isVehicleMandatory: boolean
  vehicleTypes: VehicleType[]
  selectedVehicle?: string | undefined
  availableTrailers: AvailableTrailer[]
  selectedCategory: string | undefined
  selectedTrailer?: AvailableTrailer | undefined
  selectedTrailerNoLongerAvailable?: AvailableTrailer | undefined
  isLoading: boolean
  dataAttrList: { name: string; value: string }[]
  onFooterClick: () => void
  onSelectVehicle: (vehicleCode: string) => void
  onSelectCategory: (categoryCode: string | undefined) => void
  onSelectTrailer: (trailer: AvailableTrailer | undefined) => void
}
const Vehicles: React.FC<Props> = ({
  isVehicleMandatory,
  vehicleTypes,
  selectedVehicle,
  availableTrailers,
  selectedTrailer,
  selectedTrailerNoLongerAvailable,
  selectedCategory,
  isLoading,
  dataAttrList,
  onFooterClick,
  onSelectVehicle,
  onSelectCategory,
  onSelectTrailer,
}) => {
  const categories = vehicleTypes?.map((vehicleType) => vehicleType.categoryCode)
  const categoriesUnique = [...new Set(categories)]

  const { t } = useTranslation()

  let dataAttrProps = {} as {
    dataAttrName: string
    dataAttrValue: string
  }
  dataAttrList.map((dataAttr) => {
    const key = `data-e2e-${dataAttr?.name}`
    const dataAttrKey = key as keyof {
      dataAttrName: string
      dataAttrValue: string
    }
    dataAttrProps[dataAttrKey] = dataAttr.value?.toString() ?? ''
  })
  const keyLoading = `data-e2e-vehicle-loading`
  const dataAttrKeyLoading = keyLoading as keyof {
    dataAttrName: string
    dataAttrValue: string
  }
  dataAttrProps[dataAttrKeyLoading] = isLoading.toString()

  return (
    <div
      className={`ts-bg-dfds-surface-secondary ts-text-dfds-text-dark-primary lg:ts-max-h-[calc(100dvh-160px)] lg:ts-w-96 lg:ts-overflow-auto lg:ts-bg-dfds-surface-light`}
    >
      <Categories
        walkonIsSelected={selectedVehicle === 'NCAR'}
        isVehicleMandatory={isVehicleMandatory}
        categories={categoriesUnique}
        selectedCategoryCode={selectedCategory}
        onSelectCategory={onSelectCategory}
      />
      <Selector
        vehicleTypes={vehicleTypes?.filter((vehicleType) => vehicleType.categoryCode === selectedCategory)}
        selectedVehicle={selectedVehicle}
        availableTrailers={availableTrailers}
        selectedTrailer={selectedTrailer}
        selectedTrailerNoLongerAvailable={selectedTrailerNoLongerAvailable}
        onSelectVehicle={onSelectVehicle}
        onSelectTrailer={onSelectTrailer}
        onSelectCategory={onSelectCategory}
      />
      <OverlayFooter onClick={onFooterClick}>
        <div className="ts-my-dfds-xs">
          <Typograhy as="label" className="ts-overflow-hidden ts-text-ellipsis ts-whitespace-nowrap">
            <span {...dataAttrProps}>{`${t('VEHICLE').trim()}: ${isLoading ? '...' : t(`VEHICLE-NAME-${selectedVehicle}`)}`}</span>
          </Typograhy>
          {selectedTrailer && (
            <Typograhy as="label" className="ts-my-dfds-xxs ts-overflow-hidden ts-text-ellipsis ts-whitespace-nowrap">
              <span>{`${t('TRAILER').trim()}: ${isLoading ? '...' : `${t(`TRAILER-NAME-${selectedTrailer.code}`)}`}`}</span>
            </Typograhy>
          )}
        </div>
      </OverlayFooter>
    </div>
  )
}

export default Vehicles
