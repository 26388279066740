import {Button} from '@dfds-pax/ui'
import { useTranslation } from 'react-i18next'

interface Props {
  onClick: () => void
  children?: React.ReactNode
}

const OverlayFooter: React.FC<Props> = ({ children, onClick }) => {
  const { t } = useTranslation()

  return (
    <div
      id="footer-content"
      className="ts-fixed ts-bottom-0 ts-left-0 ts-right-0 ts-mt-auto ts-flex ts-max-h-[150px] ts-min-h-[100px] ts-justify-center ts-bg-white ts-px-dfds-s ts-pb-dfds-s ts-shadow-2xl ts-shadow-black lg:ts-hidden"
    >
      <div className="ts-flex ts-w-full ts-flex-col">
        <div className="ts-flex ts-grow ts-flex-row ts-items-center">
          <div className="ts-flex ts-h-full ts-w-[100%] ts-flex-none ts-flex-col ts-justify-center ts-overflow-hidden">{children}</div>
        </div>
        <Button dataAttr="overlay-apply" className="mobile:ts-mt-auto tablet:ts-w-full" variation="Secondary" onClick={() => onClick()}>
          {t('APPLY')}
        </Button>
      </div>
    </div>
  )
}

export default OverlayFooter
