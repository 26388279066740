'use client'

import { forwardRef, SVGProps, Ref } from 'react'
const StatusError = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" ref={svgRef} {...props}>
    <path d="M12 2c-5.5 0-10 4.5-10 10s4.5 10 10 10c5.5 0 10-4.5 10-10s-4.5-10-10-10zM4 12c0-4.4 3.6-8 8-8 1.8 0 3.5 0.6 4.9 1.7l-11.2 11.2c-1.1-1.4-1.7-3.1-1.7-4.9zM12 20c-1.8 0-3.5-0.6-4.9-1.7l11.2-11.2c1.1 1.4 1.7 3.1 1.7 4.9 0 4.4-3.6 8-8 8z"></path>
  </svg>
))

export default StatusError
