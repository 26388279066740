import { TFunction } from 'i18next'

export const isFrsRoute = (routeCode: string): boolean => {
  const frsRoutes = ['TATV', 'TVTA', 'ALTM', 'ALCE', 'CEAL', 'TMAL', 'TMGI', 'GITM']
  return frsRoutes.includes(routeCode)
}

export const mapToCurrencyToContentfulResource = (currencyId: string, t: TFunction<'translation', undefined>): string => {
  if (currencyId === 'EUR') return t('EURO')
  else if (currencyId === 'MAD') return t('DIRHAM')
  else return t('EURO')
}
