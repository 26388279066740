import React from 'react'
import Height from '../../Icons/Height'
import Length from '../../Icons/Length'
import Typograhy from '../Shared/Typograhy'

interface Props {
  height?: number
  length?: number
  children?: React.ReactNode
}

const Dimensions: React.FC<Props> = ({ height, length, children }) => {
  return (
    <div className="">
      <div className="imgBlue ts-flex ts-items-center">
        <Height />
        <Typograhy className="ts-text-dfds-text-dark-secondary" as="labelSmall">
          Max {height}m
        </Typograhy>
      </div>
      <div className="ts-w-[5rem]">{children}</div>
      <div className="imgBlue ts-flex ts-items-center">
        <Length />
        <Typograhy className="ts-text-dfds-text-dark-secondary" as="labelSmall">
          Max {length}m
        </Typograhy>
      </div>
    </div>
  )
}

export default Dimensions
