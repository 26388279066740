/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SvgClose = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M4.929 17.657l5.658-5.656-5.658-5.658 1.414-1.414 5.658 5.658 5.656-5.658 1.414 1.414-5.656 5.658 5.656 5.656-1.414 1.414-5.656-5.656-5.658 5.656-1.414-1.414z"
    />
  </svg>
))
export default SvgClose
