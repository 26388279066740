'use client'

import { forwardRef, SVGProps, Ref } from 'react'
const Calendar = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" ref={svgRef} {...props}>
    <path d="M17.5 2c0.552 0 1 0.448 1 1v2h0.5c1.105 0 2 0.895 2 2v15.5h-18v-15.5c0-1.105 0.895-2 2-2h0.5v-2c0-0.552 0.448-1 1-1s1 0.448 1 1v2h9v-2c0-0.552 0.448-1 1-1zM19.5 9h-15v12h15v-12zM11.5 17v2h-2v-2h2zM8.5 17v2h-2v-2h2zM14.5 17v2h-2v-2h2zM17.5 17v2h-2v-2h2zM11.5 14v2h-2v-2h2zM8.5 14v2h-2v-2h2zM14.5 14v2h-2v-2h2zM17.5 14v2h-2v-2h2zM14.5 11v2h-2v-2h2zM11.5 11v2h-2v-2h2zM17.5 11v2h-2v-2h2zM8.5 11v2h-2v-2h2z"></path>
  </svg>
))

export default Calendar
