'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SvgCarOnboard = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M23.603 15.228v-.598a.308.308 0 00-.303-.314h-.322a.308.308 0 00-.302.314v.598c0 .129.074.239.18.287a.29.29 0 00.122.027h.322a.29.29 0 00.122-.027l.001-.001a.316.316 0 00.179-.287zm-2.524 0v-.598a.308.308 0 00-.302-.314h-.322a.308.308 0 00-.302.314v.598c0 .129.074.239.18.287a.29.29 0 00.122.027h.322c.043 0 .084-.01.122-.027l.002-.001a.316.316 0 00.179-.287zm-2.524 0v-.598a.308.308 0 00-.302-.314h-.322a.308.308 0 00-.302.314v.598c0 .129.074.239.18.287a.29.29 0 00.122.027h.322c.043 0 .084-.01.122-.027l.001-.001a.316.316 0 00.179-.287zm-2.524 0v-.598a.308.308 0 00-.302-.314h-.322a.308.308 0 00-.302.314v.598c0 .129.074.239.18.287a.29.29 0 00.122.027h.322c.044 0 .084-.01.122-.027l.001-.001a.316.316 0 00.179-.287zm11.627.458l.247.416H14.762a5.406 5.406 0 00-.515-.057c-1.015-1.105-2.299-2.021-4.07-2.491h4.388l1.853-3.308h1.515l.863 3.308h7.599l.451.762h-1.987s-.16.006-.16.129.011.23.031.363c.02.132.084.271.335.33.214.05 2.051.435 2.594.549zm-11.552.892l15.744-.012-1.527 2.664a5.78 5.78 0 00-.228-.004c-2.336 0-2.344 1.402-4.701 1.402-2.107 0-5.393-1.116-7.489-1.353-.027-.631-.66-2.301-1.799-2.698z"
    />
    <path
      fill="inherit"
      d="M4.515 20.434a.749.749 0 100-1.497.749.749 0 000 1.497zm0-2.187a1.439 1.439 0 110 2.877 1.439 1.439 0 010-2.877zm9.097 2.231a.745.745 0 100-1.49.745.745 0 000 1.49zm0-2.178a1.432 1.432 0 110 2.864 1.432 1.432 0 010-2.864zm.086-1.878c.018 0 .035.002.053.003-1.613-1.115-3.22-2.186-5.921-2.186-.367 0-.73.013-1.086.039.007.085.13 1.572.174 1.796.045.23-.013.318.542.326 1.663.023 4.621.023 6.238.023zm-10.067 0h2.605s-.008-2.055-.005-2.068c.005-.034-.02-.034-.066-.025-2.097.21-3.192 2.093-2.533 2.093zm10.26.011c2.751-.023 3.501 2.209 3.501 3.48l-1.838.022a1.953 1.953 0 10-3.883.01l-5.132-.01a1.953 1.953 0 10-3.884.006H1.07c-1.902 0-.691-3.031.062-3.713 1.85-1.582 3.715-2.612 5.924-2.612 3.307 0 5.357 1.207 6.835 2.817z"
    />
  </svg>
))
export default SvgCarOnboard
