'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SvgCarFamily = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path
      fill="inherit"
      d="M2.187 9.047a1.235 1.235 0 110 2.47 1.235 1.235 0 010-2.47zm7.025 0a1.235 1.235 0 110 2.47 1.235 1.235 0 010-2.47zm1.364 3.881l1.532 5.76a.211.211 0 01-.211.211h-1.344l-.422 3.026c-.053.107-.107.16-.214.16h-.22l-.146.001c-.033 0-.068-.006-.085-.019s-.033-.035-.051-.068l-.135-.238-.135.238c-.018.032-.035.055-.051.068s-.041.019-.073.019l-.205-.001h-.18c-.107 0-.214-.053-.214-.16l-.12-.862-.441-4.125-.785.66-.365 1.372-.409 2.968c-.031.063-.063.094-.125.094h-.215c-.019 0-.04-.004-.05-.011s-.02-.021-.03-.04l-.079-.14-.079.14c-.011.019-.021.032-.03.04s-.024.011-.043.011H5.425c-.063 0-.125-.031-.125-.094l-.431-3.058-.485-1.806c-.018.013-.035.028-.055.038l-.481.214h-.053l-.642 4.599c-.053.107-.107.16-.214.16h-.22l-.146.001c-.033 0-.068-.006-.085-.019s-.033-.035-.051-.068l-.135-.238-.135.238c-.018.032-.035.055-.051.068s-.041.019-.073.019l-.206-.001h-.18c-.107 0-.214-.053-.214-.16l-.642-4.599H.748l-.481-.214A.426.426 0 010 16.684l.107-3.369a1.444 1.444 0 011.444-1.337h1.39a1.445 1.445 0 011.444 1.337l.177 2.792h2.643l.84-3.127c.16-.574.672-.98 1.268-1.004.587 0 1.104.388 1.268.951zm-4.777 1.321a.793.793 0 110 1.586.793.793 0 010-1.586z"
    />
    <path
      fill="currentColor"
      d="M26.226 21.078a1.128 1.128 0 100-2.256 1.128 1.128 0 000 2.256zm0-3.294a2.166 2.166 0 110 4.333 2.166 2.166 0 010-4.333zm.129-2.783l.08.004c-2.44-1.687-4.872-3.308-8.958-3.308-.556 0-1.104.02-1.642.058.011.128.197 2.379.263 2.717.068.348-.019.481.82.493 2.517.035 6.991.035 9.438.035zm5.59 5.281l-2.78.034a2.955 2.955 0 10-5.876.015l-7.765-.015a2.955 2.955 0 00-3.267-3.243l-.551-2.072h3.36s-.011-3.11-.008-3.129c.008-.052-.03-.052-.101-.038-1.69.169-2.944 1.056-3.601 1.849l-.239-.899-.003-.013a1.856 1.856 0 00-.262-.545c1.706-.931 3.491-1.473 5.456-1.473 5.004 0 8.105 1.826 10.342 4.263 4.162-.035 5.297 3.342 5.297 5.265zm-19.483-2.521a2.177 2.177 0 11-1.683 3.557l.26-1.86h.4c-.069.146-.11.307-.11.479a1.133 1.133 0 101.324-1.114.754.754 0 00.014-.135v-.073l-.227-.853.022-.001z"
    />
  </svg>
))
export default SvgCarFamily
