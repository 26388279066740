import Close from '../../../Icons/Close'
import Typograhy from '../Typograhy'

export type ModalHeaderProps = {
  children: string
  closeAble: boolean
  onCloseRequested: () => void
}

const ModalHeader: React.FC<ModalHeaderProps> = ({ children, closeAble, onCloseRequested }) => {
  return (
    <div data-e2e="modal-header" className="ts-flex ts-flex-row ts-items-center ts-border-b ts-border-gray-300 ts-bg-dfds-surface-light ts-py-dfds-xs ts-pl-dfds-s">
      <Typograhy className="ts-max-w-[350px] ts-truncate ts-text-dfds-text-primary-primary " as="smallHeadline">
        {children}
      </Typograhy>
      {closeAble && (
        <div
          className="ts-fill-bg-500 ts-ml-auto ts-flex ts-h-full ts-w-12 ts-cursor-pointer ts-items-center ts-justify-center hover:ts-text-dfds-secondary-main"
          onClick={() => onCloseRequested()}
        >
          <Close width="25" height="25" />
        </div>
      )}
    </div>
  )
}

export default ModalHeader
