import Information from '../../Icons/Information'
import Typograhy from './Typograhy'

interface Props {
  message: string
  children: React.ReactNode
}

const Tooltip: React.FC<Props> = ({ message, children }) => {
  return (
    <div className="ts-group ts-relative ts-flex ts-w-full ts-cursor-pointer ts-items-center">
      <Typograhy as="labelBold" className="ts-text-dfds-text-dark-primary">
        {children}
      </Typograhy>
      <Information data-tooltip-target="tooltip" className="ts-ml-dfds-xxs" width="24" height="24" />
      <span className="ts-absolute ts-bottom-dfds-l ts-left-0 ts-hidden ts-w-fit ts-rounded-sm ts-bg-dfds-primary-main ts-p-dfds-xxs ts-font-verdana ts-text-xs ts-text-dfds-text-light-primary group-hover:ts-inline-block">
        {message}
      </span>
    </div>
  )
}

export default Tooltip
