import { useTranslation } from 'react-i18next'
import Typograhy from '../Shared/Typograhy'
import SvgChevronLeft from '../../Icons/ChevronLeft'
import { Theme } from '../../Utils/theme'
import clsx from 'clsx'

interface Props {
  isOneway: boolean
  isLoading: boolean
  theme: Theme | null
  onSelected: (oneway: boolean) => void
  isMultiOffer: boolean
  onMultiOfferClicked: () => void
  oneWayDisabled: boolean
}

const getSelectedStyles = (isSelected: boolean, isLoading: boolean) => {
  if (isLoading) return `ts-bg-dfds-surface-light/60 ts-text-transparent ts-font-DFDS`
  if (isSelected) return `ts-bg-dfds-surface-light/60 ts-text-dfds-surface-light ts-font-DFDS`
  return `ts-bg-dfds-surface-light/25 ts-text-dfds-text-light-disabled ts-font-DFDS`
}

const OnewayReturnDesktop: React.FC<Props> = ({
  isOneway,
  theme,
  isLoading,
  onSelected,
  isMultiOffer,
  onMultiOfferClicked,
  oneWayDisabled,
}) => {
  const { t } = useTranslation()
  return (
    <div className="ts-flex ts-flex-row">
      <div
        style={{ color: `${theme && theme.textColor}` }}
        data-e2e="returnTab"
        data-e2e-selected={!isOneway}
        onClick={() => onSelected(false)}
        className={clsx(
          'ts-flex ts-min-w-[80px] ts-flex-none ts-items-center ts-justify-center ts-p-dfds-xs ts-cursor-pointer',
          getSelectedStyles(!isOneway, isLoading)
        )}
      >
        <Typograhy as="label" className="ts-text-sm">
          {isLoading ? 'Return' : t('RETURN')}
        </Typograhy>
      </div>
      <div
        style={{ color: `${theme && theme.textColor}` }}
        data-e2e="oneWayTab"
        data-e2e-selected={isOneway}
        onClick={() => !oneWayDisabled && onSelected(true)}
        className={clsx(
          'ts-flex ts-flex-none ts-items-center ts-justify-center ts-p-dfds-xs min-w-[80px]',
          getSelectedStyles(isOneway, isLoading),
          oneWayDisabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'
        )}
      >
        <label
          className={clsx(
            'ts-dfds-label ts-text-sm',
            { 'ts-text-dfds-text-light-disabled': oneWayDisabled },
            oneWayDisabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'
          )}
        >
          {isLoading ? 'One way' : t('ONE-WAY')}
        </label>
      </div>
      {isMultiOffer && (
        <div
          style={{ color: `${theme && theme.textColor}` }}
          data-e2e="multiOfferTab"
          onClick={() => onMultiOfferClicked()}
          className={`ts-ml-dfds-s ts-flex ts-min-w-[80px] ts-flex-none ts-items-center ts-justify-center ts-bg-dfds-surface-light/25 ts-p-dfds-xs ts-font-DFDS ts-text-dfds-text-light-disabled`}
        >
          <SvgChevronLeft width="20" height="20" className="ts-mr-dfds-xxs" />

          <Typograhy as="label" className="ts-text-sm">
            {isLoading ? 'Back to all offers' : t('GENERAL-BACK-TO-OFFERS')}
          </Typograhy>
        </div>
      )}
    </div>
  )
}

export default OnewayReturnDesktop
