'use client'

import { forwardRef, SVGProps, Ref } from 'react'
const Yes = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M18.586 6.586L20 8 9.657 18.314 8.243 16.9 18.586 6.586zM5.414 11.243l4.243 4.243L8.243 16.9 4 12.657l1.414-1.414z"
    />
  </svg>
))
export default Yes
