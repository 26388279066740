'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const Waves = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 146 20" version="1.1" ref={svgRef} {...props}>
    <g id="Loader" stroke="none">
      <g id="Artboard-Copy">
        <g id="Group-Copy">
          <path
            d="M0,5 C7.57597216,5 7.24715338,-9.32587341e-15 14.6,-9.32587341e-15 C21.9528466,-9.32587341e-15 21.9335777,5 29.2,5 C36.4664223,5 36.5256034,-9.04831765e-15 43.8,-9.32587341e-15 C51.0743966,-9.32587341e-15 51.2650965,5 58.4,5 C65.5349035,5 65.6270419,-9.04831765e-15 73,-9.32587341e-15 C80.3729581,-9.32587341e-15 80.2196466,5 87.6,5 C94.9803534,5 94.9224659,-9.32587341e-15 102.2,-9.32587341e-15 C109.477534,-9.32587341e-15 109.321471,5 116.8,5 C124.278529,5 124.275217,-9.32587341e-15 131.4,-9.32587341e-15 C138.524783,-9.32587341e-15 138.613807,5 146,5 L146,37 L0,37 L0,5 Z"
            id="Combined-Shape"
          ></path>
        </g>
      </g>
    </g>
  </svg>
))
export default Waves
