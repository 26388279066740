/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const Bicycle = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M25.487 24.477c-2.759 0-5.003-2.237-5.003-4.987a4.98 4.98 0 012.232-4.149l2.087 4.467a.755.755 0 001.368-.636l-2.088-4.468c.446-.13.916-.201 1.403-.201 2.759 0 5.003 2.237 5.003 4.986s-2.244 4.987-5.003 4.987zM16.06 18.14l-3.326-7.118h7.15L16.06 18.14zm-9.547 6.431c-2.758 0-5.003-2.237-5.003-4.987s2.245-4.987 5.003-4.987c.592 0 1.161.104 1.688.293l-2.26 4.247a.755.755 0 00.659 1.105l4.865.049a5.005 5.005 0 01-4.952 4.28zm4.938-5.786l-3.595-.035 1.673-3.142a4.997 4.997 0 011.922 3.177zm.039-6.861l3.221 6.893-1.733-.016a6.491 6.491 0 00-2.735-4.534l1.248-2.343zm13.997 1.074c-.715 0-1.402.117-2.047.33L19.549 5h-2.37a.753.753 0 100 1.506h1.407l1.407 3.011h-7.962l-1.407-3.011h1.458a.754.754 0 100-1.506H8.117a.754.754 0 100 1.506h.84l1.733 3.708-1.776 3.338a6.473 6.473 0 00-2.401-.459C2.921 13.093 0 16.005 0 19.585s2.922 6.492 6.513 6.492c3.347 0 6.111-2.529 6.471-5.771l3.157.031a.757.757 0 00.666-.398l4.308-8.022.956 2.047a6.488 6.488 0 00-3.098 5.525c0 3.58 2.922 6.492 6.513 6.492s6.513-2.912 6.513-6.492-2.922-6.492-6.513-6.492z"
    />
  </svg>
))
export default Bicycle
