'use client'

import { forwardRef, SVGProps, Ref } from 'react'
const Logout = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef} {...props}>
    <path d="M4.5 2a2 2 0 00-2 2v16a2 2 0 002 2H12v-2H4.5V4H12V2H4.5z" fill="#4D4E4C" />
    <path
      d="M14 16.561L17.847 13H8v-2h9.887l-3.766-3.502L15.522 6l5.413 5H21v.06l1 .924-1 .907V13h-.12l-5.513 5L14 16.561z"
      fill="#4D4E4C"
    />
  </svg>
))

export default Logout
