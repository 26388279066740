'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const SvgCar1 = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M23.703 17.64a2.645 2.645 0 11-.01 5.288 2.645 2.645 0 01.01-5.287zm0 4.02a1.385 1.385 0 100-2.77 1.385 1.385 0 000 2.77zM9.382 17.64a2.645 2.645 0 11-.01 5.288 2.645 2.645 0 01.01-5.287zm0 4.02a1.385 1.385 0 100-2.77 1.385 1.385 0 000 2.77zm15.115-7.463c5.077-.042 6.18 4.075 6.217 6.42l-3.388.042v-.374a3.605 3.605 0 10-7.205 0 3.661 3.661 0 000 .392h-7.135a3.661 3.661 0 000-.392 3.605 3.605 0 10-7.21 0 3.5 3.5 0 00.023.383H2.873c-3.508 0-1.269-5.589.115-6.849 3.415-2.917 6.854-4.818 10.934-4.818 6.097 0 7.846 2.225 10.574 5.197zm-16.897 0h4.795s-.009-3.771 0-3.817-.037-.046-.125-.046c-3.868.388-5.885 3.863-4.671 3.863zm6 0l-.009-.018s7.597-.005 9.96.023c-2.308-2.566-4.689-3.988-8.769-3.988l-1.431.028c-.005.355-.102 3.955.249 3.955z"
    />
  </svg>
))
export default SvgCar1
