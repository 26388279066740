'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const Motorcycle = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M5.026 23.248c1.47 0 2.75-1.192 2.75-2.662a2.662 2.662 0 00-5.324 0c0 1.47 1.104 2.662 2.574 2.662zm.088-7.776a5.114 5.114 0 11-.001 10.229 5.114 5.114 0 01.001-10.229zm21.772 0a5.114 5.114 0 11-2.57.692l-1.215-2.554-5.037 9.176a.164.164 0 01-.142.083h-5.098a.163.163 0 01-.155-.113l-1.966-5.899a.165.165 0 00-.054-.078l-4.042-3.441a.164.164 0 00-.106-.039L.359 13.3a.162.162 0 01-.162-.163v-1.562c0-.09.072-.162.162-.162l7.866-.001c.033 0 .062.01.088.026l2.333 1.485a.17.17 0 00.089.027l2.909-.001a.161.161 0 00.122-.055l2.671-2.666a.161.161 0 01.112-.045h4.922L20.5 8.142h-2.91a.163.163 0 01-.163-.163v-.816c0-.09.073-.162.163-.162h3.641c.065 0 .12.037.147.092 0 0 2.677 5.604 4.133 8.567a5.119 5.119 0 011.376-.188zm0 7.776a2.662 2.662 0 100-5.324 2.662 2.662 0 000 5.324z"
    />
  </svg>
))
export default Motorcycle
