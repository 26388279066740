import { SelectedPassengers, Route, UseVehiclesResult, selectedDates } from '../Hooks'
import { TravelSearchInitialize, TravelSearchOptions, TravelSearchReset, TravelSearchSubmit, track } from '@dfds-frontend/tracking'
import { differenceInDays, format } from 'date-fns'
import { isFrsRoute } from './frs-helpers'

function setTrackingSession() {
  sessionStorage.setItem('TravelSearchTracking', crypto.randomUUID())
}

export function getTrackingSession() {
  const id = sessionStorage.getItem('TravelSearchTracking')
  return id === null ? undefined : id
}

export function TrackRoute(selectedRoute: Route, vehicleData: UseVehiclesResult) {
  const payload: IRouteUpdate = {
    route_name: selectedRoute.name,
    route_code: selectedRoute.routeCode,
    route_owner: selectedRoute.salesOwner,
    is_vehicle_mandatory: vehicleData?.vehicleApiData?.isVehicleMandatory,
    is_pets_allowed_for_foot_passenger: vehicleData?.vehicleApiData?.isPetsAllowedForFootPassenger,
    max_number_of_pets: vehicleData?.vehicleApiData?.maxNumberOfPets,
  }
  track<TravelSearchOptions>({
    event: 'travel_search_options_route',
    travel_search_session: getTrackingSession(),
    ...payload,
  })
}

export function TrackVehicle(vehicleData: UseVehiclesResult) {
  const payload: IVehicleUpdate = {
    vehicle_code: vehicleData?.selectedVehicle?.code,
    vehicle_category_code:
      vehicleData?.vehicleApiData?.vehicleTypes.find((vt) => vt.code === vehicleData?.selectedVehicle?.code)?.categoryCode || 'unknown',
    vehicle_is_pets_allowed: vehicleData?.vehicleApiData?.isPetsAllowedForFootPassenger,
    vehicle_trailer_code: vehicleData?.selectedVehicle?.selectedTrailer?.code ?? '',
  }
  track<TravelSearchOptions>({
    event: 'travel_search_options_vehicle',
    travel_search_session: getTrackingSession(),
    ...payload,
  })
}

export function TrackPassengers(selectedPassengers: SelectedPassengers) {
  const payload: IPassengerUpdate = {
    passenger_adults: selectedPassengers.adults,
    passenger_children: selectedPassengers.children,
    passenger_pets: selectedPassengers.pets,
    passenger_total: selectedPassengers.adults + selectedPassengers.children,
  }
  track<TravelSearchOptions>({
    event: 'travel_search_options_passenger',
    travel_search_session: getTrackingSession(),
    ...payload,
  })
}

export function TrackDates(selectedDates: selectedDates, oneWay: boolean) {
  if (selectedDates.outboundDate) {
    const payload: IDateUpdate = {
      departure_date: format(selectedDates.outboundDate, 'yyyy-MM-dd'),
      return_date: !oneWay && selectedDates.homeboundDate ? format(selectedDates.homeboundDate, 'yyyy-MM-dd') : '',
      trip_duration:
        !oneWay && selectedDates.outboundDate && selectedDates.homeboundDate
          ? differenceInDays(selectedDates.outboundDate, selectedDates.homeboundDate)
          : 0,
    }
    track<TravelSearchOptions>({
      event: 'travel_search_options_date',
      travel_search_session: getTrackingSession(),
      ...payload,
    })
  }
}

export function TrackOneWay(oneWay: boolean) {
  const payload: ITripType = {
    trip_type: oneWay ? 'oneway' : 'return',
  }
  track<TravelSearchOptions>({
    event: 'travel_search_options_trip_type',
    travel_search_session: getTrackingSession(),
    ...payload,
  })
}

function TravelSearchPayload(
  selectedRoute: Route,
  selectedDates: selectedDates,
  vehicleData: UseVehiclesResult,
  selectedPassengers: SelectedPassengers,
  oneWay: boolean
) {
  const payload: ITravelSearchTracking = {
    route_name: selectedRoute.name,
    route_code: selectedRoute.routeCode,
    route_owner: selectedRoute.salesOwner,
    is_vehicle_mandatory: vehicleData?.vehicleApiData?.isVehicleMandatory,
    is_pets_allowed_for_foot_passenger: vehicleData?.vehicleApiData?.isPetsAllowedForFootPassenger,
    max_number_of_pets: vehicleData?.vehicleApiData?.maxNumberOfPets,
    departure_date: selectedDates.outboundDate ? format(selectedDates.outboundDate, 'yyyy-MM-dd') : '',
    return_date: !oneWay && selectedDates.homeboundDate ? format(selectedDates.homeboundDate, 'yyyy-MM-dd') : '',
    trip_duration:
      !oneWay && selectedDates.outboundDate && selectedDates.homeboundDate
        ? differenceInDays(selectedDates.outboundDate, selectedDates.homeboundDate)
        : 0,
    vehicle_code: vehicleData?.selectedVehicle.code,
    vehicle_category_code: 'unknown',
    vehicle_is_pets_allowed: vehicleData?.vehicleApiData?.isPetsAllowedForFootPassenger,
    vehicle_trailer_code: vehicleData?.selectedVehicle.selectedTrailer?.code ?? '',
    passenger_adults: selectedPassengers.adults,
    passenger_children: selectedPassengers.children,
    passenger_pets: selectedPassengers.pets,
    passenger_total: selectedPassengers.adults + selectedPassengers.children,
    trip_type: oneWay ? 'oneway' : 'return',
  }
  return payload
}

function TravelSearchFRSPayload(selectedRoute: Route, selectedDates: selectedDates, oneWay: boolean, selectedCurrency?: string) {
  const payload: ITravelSearchFRSTracking = {
    route_name: selectedRoute.name,
    route_code: selectedRoute.routeCode,
    route_owner: selectedRoute.salesOwner,
    departure_date: selectedDates.outboundDate ? format(selectedDates.outboundDate, 'yyyy-MM-dd') : '',
    return_date: !oneWay && selectedDates.homeboundDate ? format(selectedDates.homeboundDate, 'yyyy-MM-dd') : '',
    trip_duration:
      !oneWay && selectedDates.outboundDate && selectedDates.homeboundDate
        ? differenceInDays(selectedDates.outboundDate, selectedDates.homeboundDate)
        : 0,
    trip_type: oneWay ? 'oneway' : 'return',
    currency: selectedCurrency || '',
  }
  return payload
}
export function TrackingCommand(
  event: TravelSearchTrackingTypes,
  selectedRoute?: Route,
  selectedDates?: selectedDates,
  vehicleData?: UseVehiclesResult,
  selectedPassengers?: SelectedPassengers,
  selectedCurrency?: string,
  oneWay?: boolean
) {
  var payload: ITravelSearchTracking | ITravelSearchFRSTracking | undefined

  if (selectedRoute && isFrsRoute(selectedRoute.routeCode) && selectedDates && oneWay !== undefined && selectedCurrency) {
    payload = TravelSearchFRSPayload(selectedRoute, selectedDates, oneWay, selectedCurrency)
  } else if (
    selectedRoute &&
    !isFrsRoute(selectedRoute.routeCode) &&
    selectedDates &&
    vehicleData &&
    selectedPassengers &&
    oneWay !== undefined
  ) {
    payload = TravelSearchPayload(selectedRoute, selectedDates, vehicleData, selectedPassengers, oneWay)
  } else return

  const travelSearchSession = getTrackingSession()
  if (event === TravelSearchTrackingTypes.INITIALIZE && !travelSearchSession) {
    setTrackingSession()
    track<TravelSearchInitialize>({
      event: 'travel_search_initialize',
      travel_search_session: getTrackingSession(),
      ...payload,
    })
  } else if (event === TravelSearchTrackingTypes.SUBMIT && travelSearchSession) {
    track<TravelSearchSubmit>({
      event: 'travel_search_submit',
      travel_search_session: travelSearchSession,
      ...payload,
    })
  } else if (event === TravelSearchTrackingTypes.RESET && travelSearchSession) {
    track<TravelSearchReset>({
      event: 'travel_search_reset',
      travel_search_session: travelSearchSession,
      ...payload,
    })
  }
}

export enum TravelSearchTrackingTypes {
  INITIALIZE = 'init',
  SUBMIT = 'submit',
  RESET = 'reset',
}
interface ITravelSearchTracking {
  trip_type: 'oneway' | 'return'
  route_name: string
  route_code: string
  route_owner: number
  is_vehicle_mandatory: boolean
  is_pets_allowed_for_foot_passenger: boolean
  max_number_of_pets: number
  vehicle_code: string
  vehicle_category_code: string
  vehicle_is_pets_allowed: boolean
  vehicle_trailer_code: string
  passenger_adults: number
  passenger_children: number
  passenger_pets: number
  passenger_total: number
  departure_date: string
  return_date: string
  trip_duration: number
}

interface ITravelSearchFRSTracking {
  trip_type: 'oneway' | 'return'
  route_name: string
  route_code: string
  route_owner: number
  departure_date: string
  return_date: string
  trip_duration: number
  currency: string
}

interface ITripType {
  trip_type: 'oneway' | 'return'
}

interface IRouteUpdate {
  route_name: string
  route_code: string
  route_owner: number
  is_vehicle_mandatory: boolean
  is_pets_allowed_for_foot_passenger: boolean
  max_number_of_pets: number
}

interface IVehicleUpdate {
  vehicle_code: string
  vehicle_category_code: string
  vehicle_is_pets_allowed: boolean
  vehicle_trailer_code: string
}

interface IPassengerUpdate {
  passenger_adults: number
  passenger_children: number
  passenger_pets: number
  passenger_total: number
}

interface IDateUpdate {
  departure_date: string
  return_date: string
  trip_duration: number
}
