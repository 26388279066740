'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const CarHighRoofBox = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="1em" height="1em" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M14.306 19.412c-.898-.012-.866-.07-.939-.411-.07-.331-.12-2.694-.132-2.82 5.566-.232 8.26 1.586 10.87 3.235-.028 0-.056-.003-.085-.004-2.618 0-7.022.034-9.714 0zm-10.966 0c-.642 0-.225-2.865.436-3.266.075-.014 3.789-.05 3.78 0-.004.02 0 3.266 0 3.266zm4.842 0c0-.147-.131-2.347 0-3.266.076-.014 4.325-.05 4.316 0-.003.02.164 2.929.057 3.266zm15.984 2.77c1.285 0 2.326 1.002 2.326 2.239s-1.041 2.24-2.326 2.24c-1.284 0-2.326-1.003-2.326-2.24s1.041-2.24 2.326-2.24zm-14.644 0c1.284 0 2.325 1.002 2.325 2.239s-1.041 2.24-2.325 2.24c-1.285 0-2.326-1.003-2.326-2.24s1.041-2.24 2.326-2.24zm14.644 1.073c-.669 0-1.21.522-1.21 1.166 0 .644.541 1.166 1.21 1.166.669 0 1.21-.522 1.21-1.166 0-.644-.541-1.166-1.21-1.166zm-14.644 0c-.67 0-1.21.522-1.21 1.166 0 .644.54 1.166 1.21 1.166.668 0 1.21-.522 1.21-1.166 0-.644-.542-1.166-1.21-1.166zM3.58 15.638c1.442-.561 8.067-.38 9.689-.38 5.353 0 8.671 1.787 11.064 4.17C28.785 19.393 30 22.696 30 24.577l-2.974.032c.01-.098.017-.199.017-.3 0-1.596-1.416-2.89-3.162-2.89s-3.161 1.294-3.161 2.89c0 .107.007.212.02.315l-8.308-.015c.011-.098.017-.199.017-.3 0-1.596-1.415-2.89-3.161-2.89s-3.162 1.294-3.162 2.89c0 .105.007.207.02.31H3.58c-2.445-.01-1.738-8.306 0-8.982zM19 13.5c.5 0 .5.886 0 .886H4c-.5 0-.5-.886 0-.886z"
    />
    <path
      fill="inherit"
      d="M15.354 13.393c-1.12 0-2.033-.85-2.033-1.893 0-.656.361-1.235.907-1.575l.848 1.696a.31.31 0 00.278.165.323.323 0 00.13-.027.278.278 0 00.148-.38l-.848-1.696c.181-.05.372-.076.57-.076 1.12 0 2.033.85 2.033 1.893 0 1.044-.912 1.893-2.033 1.893zm-3.83-2.406l-1.35-2.701h2.904zM7.646 13.43c-1.12 0-2.033-.85-2.033-1.893 0-1.044.912-1.893 2.033-1.893.24 0 .472.04.686.111l-.918 1.612a.268.268 0 00.007.28.31.31 0 00.26.14l1.977.018c-.14.917-.989 1.625-2.012 1.625zm2.006-2.197l-1.46-.013.68-1.193c.407.288.695.716.78 1.206zm.016-2.604l1.308 2.616-.704-.006c-.092-.71-.51-1.329-1.111-1.72zm4.855.533L12.942 6h-.963c-.17 0-.306.128-.306.286 0 .157.137.285.306.285h.572l.571 1.143H9.888l-.572-1.143h.593c.17 0 .306-.128.306-.285 0-.158-.137-.286-.307-.286h-1.61c-.17 0-.307.128-.307.286 0 .157.138.285.307.285h.341l.704 1.408-.721 1.267a2.797 2.797 0 00-.976-.175C6.186 9.071 5 10.177 5 11.536S6.187 14 7.646 14c1.36 0 2.483-.96 2.629-2.19l1.28.011h.003a.311.311 0 00.27-.15l1.75-3.045.389.777c-.755.434-1.259 1.212-1.259 2.097 0 1.359 1.187 2.464 2.646 2.464 1.46 0 2.646-1.105 2.646-2.464s-1.187-2.464-2.646-2.464c-.29 0-.57.044-.831.125z"
    />
  </svg>
))
export default CarHighRoofBox
