import { useEffect, useRef } from 'react'
import Close from '../../Icons/Close'
import Typograhy from './Typograhy'
import { useElementWidth } from '../../Hooks/useElementWidth'
import { useWindowWidth } from '../../Hooks'
import { Theme } from '../../Utils/theme'

interface Props {
  title: string
  isMobile: boolean
  fieldName: 'route' | 'vehicle' | 'passenger' | 'currency'
  isOpen: boolean
  children: React.ReactNode
  value: any
  isLoading: boolean
  icon: React.ReactNode
  disabled?: boolean
  theme: Theme | null
  visibleRows?: 1 | 2
  maxWidth: 'lg:ts-max-w-[200px]' | 'lg:ts-max-w-[140px]' | 'lg:ts-max-w-[80px]'
  dataAttrList: { name: string; value: string }[]
  onClick: (clickedOutSide: boolean) => void
  onClose: () => void
}

const getFieldBorderStyles = (isOpen: boolean) => {
  if (isOpen) return 'ts-border-solid ts-border ts-border-dfds-secondary-main ts-outline ts-outline-2 ts-outline-dfds-secondary-main'
  else return 'ts-border-solid ts-border ts-border-dfds-secondary-main'
}

const SelectField: React.FC<Props> = ({
  title,
  fieldName,
  theme,
  maxWidth,
  children,
  isMobile,
  isOpen,
  value,
  isLoading,
  icon,
  dataAttrList,
  visibleRows,
  disabled,
  onClick,
  onClose,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const selectFieldWidth = useElementWidth(ref)

  const { widthSmallerThan1025 } = useWindowWidth()
  let dataAttrProps = {} as ComponentProps
  dataAttrList.map((dataAttr) => {
    const key = `data-e2e-${dataAttr?.name}`
    const dataAttrKey = key as keyof ComponentProps
    dataAttrProps[dataAttrKey] = dataAttr.value?.toString() ?? ''
  })
  const keyLoading = `data-e2e-${fieldName}-loading`
  const dataAttrKeyLoading = keyLoading as keyof ComponentProps
  dataAttrProps[dataAttrKeyLoading] = isLoading.toString()

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        const target = event.target as HTMLElement
        if (!target.closest('[data-travel-search-child]')) onClick(true)
      }
    }

    if (isOpen && !isMobile && ref) addEventListener('click', handleClickOutside)
    if (!isOpen && ref) window.removeEventListener('click', handleClickOutside)
    return () => window.removeEventListener('click', handleClickOutside)
  }, [isOpen, ref])

  return (
    <div ref={ref} className="ts-relative ts-h-[50px] ts-grow lg:ts-w-full" id={`${fieldName}-field-container`} {...dataAttrProps}>
      <div
        data-e2e={fieldName}
        data-e2e-open={isOpen}
        data-e2e-disbaled={`${fieldName}-${disabled}`}
        className={`ts-relative ts-mb-dfds-xs ts-flex ts-h-full grow ts-flex-row ts-items-center ts-bg-white md:ts-mb-0 ${getFieldBorderStyles(isOpen)} ${
          disabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'
        }`}
        onClick={() => {
          if (!disabled) {
            if (!isOpen) document.getElementById(`${fieldName}-input`)?.focus()
            else onClose()
          }
        }}
      >
        <div id={`${fieldName}-field`} className="ts-z-1 ts-absolute ts-bottom-0 ts-left-0 ts-right-0 ts-top-0 ts-to-transparent"></div>
        <div
          style={{ color: `${theme ? theme.iconColor : '#49a2df'}` }}
          className={`ts-flex ts-h-full ts-w-[48px] ts-flex-none ts-items-center ts-justify-center ${disabled && 'imgDisabled'}`}
        >
          {icon}
        </div>
        <div className={`ts-flex ts-h-full ts-w-full ts-flex-col ts-justify-center ts-pr-dfds-s ${maxWidth}`}>
          <label
            className={`ts-w-full ts-truncate ts-font-DFDS ts-text-xs ts-leading-none ${
              disabled ? 'ts-text-dfds-text-dark-disabled' : 'ts-text-dfds-text-dark-primary'
            }`}
          >
            {title}
          </label>
          <input
            data-e2e={`${fieldName}-input`}
            readOnly
            value={`${isLoading ? '...' : value}`}
            onFocus={() => {
              if (!disabled) {
                if (!isOpen) {
                  onClick(false)
                }
              }
            }}
            id={`${fieldName}-input`}
            className={` ts-text-[100%] ts-m-0 ts-p-0 
            ts-flex-none ts-border-none truncate ts-font-DFDSBold ts-leading-none focus:ts-outline-none ${
              disabled ? 'ts-text-dfds-text-dark-disabled' : 'ts-text-dfds-text-dark-primary'
            }`}
          />
        </div>
      </div>
      {!widthSmallerThan1025 && (
        <div
          style={{ width: `${selectFieldWidth + 4}px` }}
          className={`top:ts-0 ts-invisible ts-absolute ts-z-[500001] ts-ml-[-2px] ts-mt-[-1px] ts-h-[10px] ts-border-x-[3px] ts-border-solid ts-border-dfds-secondary-main ts-bg-white lg:ts-visible lg:ts-left-0 ${
            !isOpen && 'lg:ts-invisible'
          } 
        `}
        />
      )}
      <div
        className={`ts-z-[500000] ts-bg-white lg:ts-absolute lg:ts-left-0 lg:ts-mt-dfds-xs lg:ts-bg-white lg:ts-shadow-xl lg:ts-drop-shadow-2xl tabletDown:ts-fixed tabletDown:ts-bottom-0 tabletDown:ts-left-0 tabletDown:ts-right-0 tabletDown:ts-top-0 tabletDown:ts-w-full ${
          !isOpen && 'ts-invisible'
        }
        ${getFieldBorderStyles(isOpen)}`}
      >
        <div className="ts-flex ts-h-full ts-flex-col">
          <div id="header-content" className="ts-flex ts-h-[48px] ts-min-h-[48px] ts-flex-row ts-items-center ts-bg-dfds-surface-light ts-pl-dfds-s lg:ts-hidden">
            <Typograhy className="ts-max-w-[350px] ts-text-dfds-text-primary-primary" as="smallHeadline">
              {title}
            </Typograhy>
            <div
              className="ts-fill-bg-500 ts-ml-auto ts-flex ts-h-full ts-w-12 ts-cursor-pointer ts-items-center ts-justify-center hover:ts-text-dfds-secondary-main"
              onClick={() => {
                if (!disabled) {
                  onClick(true)
                }
              }}
            >
              <Close width="25" height="25" />
            </div>
          </div>
          <div className="ts-border-b ts-border-solid ts-border-gray-300 lg:ts-hidden"></div>
          <div
            id="main-content"
            className={`ts-bg-dfds-surface-light  tabletDown:ts-overflow-y-scroll 
              ${fieldName !== 'route' && visibleRows === 1 && 'tabletDown:ts-h-[calc(100%-150px)]'} 
              ${fieldName !== 'route' && visibleRows === 2 && 'tabletDown:ts-h-[calc(100%-170px)]'}`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SelectField

interface ComponentProps {
  dataAttrName: string
  dataAttrValue: string
}
