'use client'

import { forwardRef, SVGProps, Ref } from 'react'
const StatusAlert = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" ref={svgRef} {...props}>
    <path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10c-5.523 0-10-4.477-10-10s4.477-10 10-10zM12 4c-4.418 0-8 3.582-8 8s3.582 8 8 8c4.418 0 8-3.582 8-8s-3.582-8-8-8zM12.992 14.25c0.097 0 0.163 0.021 0.202 0.060 0.038 0.041 0.056 0.113 0.056 0.216v1.948c0 0.104-0.019 0.175-0.056 0.216s-0.105 0.060-0.202 0.060h-1.984c-0.097 0-0.164-0.019-0.202-0.060s-0.056-0.112-0.056-0.216v-1.948c0-0.103 0.019-0.175 0.056-0.216 0.038-0.039 0.105-0.060 0.202-0.060h1.984zM12.992 7c0.097 0 0.163 0.017 0.202 0.051 0.037 0.034 0.056 0.094 0.056 0.181l-0.21 5.536c0 0.087-0.018 0.148-0.056 0.181s-0.104 0.051-0.202 0.051h-1.565c-0.096 0-0.164-0.017-0.201-0.051s-0.056-0.094-0.056-0.181l-0.21-5.536c0-0.087 0.018-0.147 0.056-0.181 0.038-0.033 0.104-0.051 0.202-0.051h1.984z"></path>
  </svg>
))

export default StatusAlert
