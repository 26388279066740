import { useState, useEffect, RefObject } from 'react'

export const useElementWidth = (ref: RefObject<HTMLDivElement>) => {
  const [width, setWidth] = useState<number>(0)

  useEffect(() => {
    const updateWidth = () => {
      if (ref.current) {
        setWidth(ref.current.offsetWidth)
      }
    }

    updateWidth()
    const observer = new ResizeObserver(updateWidth)
    if (ref.current) {
      observer.observe(ref.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [ref])

  return width
}
