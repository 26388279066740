import { format } from 'date-fns'

// Locales from date-fns
import { da, enGB, fr, bg, cs, pt, fi, ro, ru, sk, sv, lt, pl, lv, nb, nl, nlBE, de, et, es, it, ja, ko } from 'date-fns/locale'

export function addDays(date: Date, days: number): Date {
  var result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

export function toShortDateString(date?: Date): string {
  if (!date) return '-'
  if (getDatepickerLocale().code === 'lv') return format(date, 'EEEEE dd/MM', { locale: getDatepickerLocale() })
  if (getDatepickerLocale().code === 'et') return format(date, 'EEEEE dd/MM', { locale: getDatepickerLocale() })
  return format(date, 'eee dd/MM', { locale: getDatepickerLocale() })
}

export function toDateString(date?: Date): string {
  if (!date) return '-'
  return format(date, 'eee PP', { locale: getDatepickerLocale() })
}

const getUrlLocale = (): string => {
  // Extract the locale from the URL path
  const localeFromPath = window.location.pathname.split('/')[1]

  // If a locale is found in the path, return it
  if (localeFromPath) return localeFromPath

  // Otherwise, extract the locale from the search parameters which is needed for travel-search used by the app
  const url = new URL(window.location.href)
  const locale = url.searchParams.get('searchConfig')

  // Return the locale if found, otherwise default to 'en'
  return locale || 'en'
}

export function getDatepickerLocale(): Locale {
  const locale: string = getUrlLocale()

  switch (locale) {
    case 'en-gb':
      return enGB
    case 'sv-se':
      return sv
    case 'nb-no':
      return nb
    case 'nl-nl':
      return nl
    case 'fr-fr':
      return fr
    case 'fr-be':
      return fr
    case 'nl-be':
      return nlBE
    case 'de-de':
      return de
    case 'da-dk':
      return da
    case 'et-ee':
      return et
    case 'es-es':
      return es
    case 'it-it':
      return it
    case 'ja-jp':
      return ja
    case 'ko-kr':
      return ko
    case 'lt-lt':
      return lt
    case 'lv-lv':
      return lv
    case 'pl-pl':
      return pl
    case 'ro-ro':
      return ro
    case 'ru-ru':
      return ru
    case 'sk-sk':
      return sk
    case 'fi-fi':
      return fi
    case 'bg-bg':
      return bg
    case 'pt-br':
      return pt
    case 'cs-cz':
      return cs
    default:
      return enGB
  }
}
