'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const Spinner = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" ref={svgRef} {...props}>
    <path
      opacity="0.3"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3729 5.71285C13.7085 5.82565 14.986 6.36141 16.0183 7.24169L18.6605 4.60433C16.8859 3.00262 14.6257 2.05238 12.2697 1.91752L12.3729 5.71285Z"
      fill="currentColor"
    />
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2765 11.6058L22.1228 11.6648C21.9877 9.3132 21.0358 7.05712 19.4311 5.28571L16.7008 8.01098C17.5899 9.02368 18.1417 10.2826 18.2765 11.6058Z"
      fill="currentColor"
    />
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2918 12.588C18.2199 13.9183 17.7222 15.1776 16.8716 16.1814L19.5964 18.9012C21.1472 17.1782 22.0301 14.9503 22.0927 12.6017L18.2918 12.588Z"
      fill="currentColor"
    />
    <path
      opacity="0.6"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.1215 16.8393C15.1159 17.6883 13.8543 18.185 12.5215 18.2569L12.5807 22.0961C14.9336 22.0336 17.1656 21.1523 18.8917 19.6044L16.1215 16.8393Z"
      fill="currentColor"
    />
    <path
      opacity="0.7"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.98165 16.7142L5.29541 19.3955C7.07007 20.9972 9.33029 21.9475 11.6862 22.0823L11.6271 18.243C10.2914 18.1302 9.01394 17.5945 7.98165 16.7142Z"
      fill="currentColor"
    />
    <path
      opacity="0.8"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.67918 12.3943L1.87689 12.2913C2.012 14.6429 2.96397 16.899 4.56863 18.6704L7.25487 15.9891C6.36581 14.9764 5.81397 13.7174 5.67918 12.3943Z"
      fill="currentColor"
    />
    <path
      opacity="0.9"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.66422 11.412C5.73903 10.0609 6.25294 8.78422 7.12844 7.77462L4.40367 5.05483C2.85287 6.77779 1.97001 9.00569 1.90734 11.3543L5.66422 11.412Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.06396 4.39551L7.83415 7.16062C8.83979 6.31163 10.1014 5.81486 11.4341 5.74304L11.375 1.90375C9.02207 1.9663 6.79008 2.84755 5.06396 4.39551Z"
      fill="currentColor"
    />
  </svg>
))
export default Spinner
