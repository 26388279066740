'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const YesIcon = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" ref={svgRef} {...props}>
    <circle cx="12" cy="12" r="10" fill="#4caf50" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.7426 9.87274L10.6716 16.661L6.42893 12.588L7.84315 11.2304L10.6716 13.9447L16.3284 8.51509L17.7426 9.87274Z"
      fill="white"
    />
  </svg>
))

export default YesIcon
