import { RefObject, useEffect, useRef } from 'react'
import Close from '../../Icons/Close'
import CalendarOutbound from '../../Icons/CalendarOutbound'
import CalendarReturn from '../../Icons/CalendarReturn'
import OverlayDateFooter from '../Date/OverlayDateFooter'
import { toShortDateString } from '../../Utils/date'
import { useTranslation } from 'react-i18next'
import Typograhy from '../Shared/Typograhy'
import { useWindowWidth2 } from '../../Hooks/useWindowWidth2'
import { useElementWidth } from '../../Hooks/useElementWidth'
import { useElementHeight } from '../../Hooks/useElementHeight'
import { useWindowWidth } from '../../Hooks'
import { useThreeDots } from '../../Hooks/useThreeDots'
import { Theme } from '../../Utils/theme'

interface Props {
  showFooter: boolean
  isOneway: boolean
  isOpen: boolean
  fieldName: string
  theme: Theme | null
  returnFocused: boolean
  selectedOutboundDate: Date | undefined
  selectedHomeboundDate?: Date | undefined
  isLoading: boolean
  children: React.ReactNode
  dataAttrList: { name: string; value?: string }[]
  containerRef: RefObject<HTMLDivElement>
  disabled?: boolean
  isFrsRoute?: boolean
  onClick: (clickedOutside: boolean, clickedReturnField?: boolean) => void
  onClose: () => void
}

const getFieldBorderStyles = (isOpen: boolean) => {
  if (isOpen) return 'ts-border-solid ts-border ts-border-dfds-secondary-main ts-outline ts-outline-2 ts-outline-dfds-secondary-main'
  else return 'ts-border-solid ts-border ts-border-dfds-secondary-main'
}

const DateField: React.FC<Props> = ({
  isOneway,
  isOpen,
  fieldName,
  theme,
  returnFocused,
  selectedOutboundDate,
  selectedHomeboundDate,
  isLoading,
  children,
  dataAttrList,
  containerRef,
  disabled,
  isFrsRoute,
  onClick,
  onClose,
}) => {
  const { t } = useTranslation()

  const threeDots = useThreeDots()

  const { isMobile, isTablet } = useWindowWidth2()
  const { widthSmallerThan1025 } = useWindowWidth()

  const ref = useRef<HTMLDivElement>(null)
  const dateFieldWidth = useElementWidth(ref)

  const moreThanOneRow = useElementHeight(containerRef) > 100

  let dataAttrProps = {} as ComponentProps
  dataAttrList.map((dataAttr) => {
    const key = `data-e2e-${dataAttr?.name}`
    const dataAttrKey = key as keyof ComponentProps
    dataAttrProps[dataAttrKey] = dataAttr.value?.toString() ?? ''
  })
  const keyLoading = `data-e2e-${fieldName}-loading`
  const dataAttrKeyLoading = keyLoading as keyof ComponentProps
  dataAttrProps[dataAttrKeyLoading] = isLoading.toString()

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (!(event?.target?.name === 'day' && event?.target?.type === 'button')) {
          // This is a hack to prevent the datepicker from closing when clicking on a day in the calendar on offers because a rerender happens when homebound dates are loaded
          onClick(true)
        }
      }
    }

    if (isOpen && !isMobile && !isTablet && ref) addEventListener('click', handleClickOutside)
    if (!isOpen && ref) window.removeEventListener('click', handleClickOutside)
    return () => window.removeEventListener('click', handleClickOutside)
  }, [isOpen, ref])

  return (
    <div ref={ref} className="ts-relative ts-h-[50px]" id={`${fieldName}-field-container`} {...dataAttrProps}>
      <div
        data-e2e={fieldName}
        data-e2e-open={isOpen}
        className={`ts-relative ts-h-full ts-grow ts-items-center lg:ts-mb-0 ${getFieldBorderStyles(isOpen)}`}
      >
        <div className="ts-flex ts-h-full ts-overflow-hidden">
          <div
            className={`ts-flex ts-w-[50%] ${isLoading || disabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'} ts-flex-row ${
              returnFocused && isOpen ? 'ts-bg-gray-200' : 'ts-bg-white'
            }`}
            onClick={() => {
              if (!isOpen && !isLoading && !disabled) {
                onClick(false)
                document.getElementById(`${fieldName}-input`)?.focus()
              } else onClose()
            }}
          >
            <div
              data-e2e="outboundDateIcon"
              className={`ts-flex ts-h-full ts-w-[48px] ts-flex-none ${
                isLoading || disabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'
              } ts-items-center ts-justify-center ${disabled && 'imgDisabled'}`}
            >
              <CalendarOutbound style={{ color: `${theme ? theme.iconColor : disabled ? '' : '#49a2df'}` }} width="30" height="30" />
            </div>
            <div className="ts-flex ts-flex-col ts-justify-center lg:ts-max-w-[90px] grow">
              <label
                className={`ts-w-full ${isLoading || disabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'} ts-font-DFDS ts-text-xs ts-leading-none ${
                  disabled ? 'ts-text-dfds-text-dark-disabled' : 'ts-text-dfds-text-dark-primary'
                }`}
              >
                {t('OUTBOUND')}
              </label>
              <input
                data-e2e="outbound-date-input"
                readOnly
                value={isLoading ? threeDots : toShortDateString(selectedOutboundDate)}
                id={`${fieldName}-input`}
                className={`ts-text-[100%] ts-p-0 ts-m-0  ts-font-DFDSBold ts-leading-none ts-border-none focus:ts-outline-none ${
                  disabled ? 'ts-text-dfds-text-dark-disabled' : 'ts-text-dfds-text-dark-primary'
                } ${isLoading || disabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'} ${
                  returnFocused && isOpen ? 'ts-bg-gray-200' : 'ts-bg-white'
                }`}
              />
            </div>
          </div>
          <div className="ts-border-solid ts-h-full ts-border-l ts-border-dfds-secondary-main"></div>
          <div
            className={`ts-flex ts-w-[50%] ${isLoading || disabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'} ts-flex-row ${
              !returnFocused && isOpen ? 'ts-bg-gray-200' : 'ts-bg-white'
            }`}
            onClick={() => {
              if (!isOpen && !isLoading && !disabled) {
                onClick(false, true)
                document.getElementById(`${fieldName}-input`)?.focus()
              } else if (!isOneway) onClose()
            }}
          >
            <div
              data-e2e="returnDateIcon"
              className={`ts-flex ts-h-full ts-w-[48px] ts-flex-none ${
                isLoading || disabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'
              } ts-items-center ts-justify-center ${(isOneway || disabled) && 'imgDisabled'}`}
            >
              <CalendarReturn
                style={{ color: `${theme ? theme.iconColor : isOneway || disabled ? '' : '#49a2df'}` }}
                width="30"
                height="30"
              />
            </div>
            <div className="ts-flex ts-grow ts-flex-col ts-justify-center lg:ts-max-w-[90px]">
              <label
                className={`ts-w-full ${isLoading || disabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'} ts-font-DFDS ts-text-xs ts-leading-none ${
                  isOneway || disabled ? 'ts-text-dfds-text-dark-disabled' : 'ts-text-dfds-text-dark-primary'
                }`}
              >
                {t('INBOUND')}
              </label>
              <input
                data-e2e="homebound-date-input"
                readOnly
                value={isLoading && !isOneway ? threeDots : toShortDateString(selectedHomeboundDate)}
                id={`${fieldName}-input`}
                className={`ts-text-[100%] ts-p-0 ts-m-0 ts-border-none ts-leading-none focus:ts-outline-none ${
                  isOneway || disabled ? 'ts-text-dfds-text-dark-disabled' : 'ts-text-dfds-text-dark-primary'
                } ${isLoading || disabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'} ts-font-DFDSBold ${
                  !returnFocused && isOpen ? 'ts-bg-gray-200' : 'ts-bg-white'
                }`}
              />
            </div>
          </div>
        </div>
      </div>
      {!widthSmallerThan1025 && (
        <div
          style={{ width: `${dateFieldWidth + 4}px` }}
          className={`ts-border-0 ts-box-border top:ts-0 ts-flex-no-wrap ts-invisible ts-absolute ts-z-[500001] ts-ml-[${dateFieldWidth % 2 === 0 ? '-1px' : '-2px'}]
          ts-mt-[-1px] ts-flex ts-h-[10px] ts-border-x-[3px] ts-border-solid ts-border-dfds-secondary-main ts-bg-white lg:ts-visible lg:ts-left-0 ${
            !isOpen && 'lg:ts-invisible'
          } 
        `}
        >
          <div className={`ts-h-full ts-w-1/2 ${returnFocused && isOpen ? 'ts-bg-gray-200' : 'ts-bg-white'}`} />
          <div className="ts-ml-[-1px] ts-h-full ts-w-[1px] ts-bg-dfds-secondary-main" />
          <div className={`ts-h-full ts-w-1/2 ${!returnFocused && isOpen ? 'ts-bg-gray-200' : 'ts-bg-white'}`} />
        </div>
      )}
      <div
        style={{ width: moreThanOneRow && !widthSmallerThan1025 ? `${dateFieldWidth}px` : widthSmallerThan1025 ? '100%' : 'fit-content' }}
        className={`ts-z-[500000] ts-flex ts-justify-center ts-bg-white 
        lg:ts-absolute lg:ts-bottom-auto
        lg:ts-top-auto lg:ts-mt-dfds-xs lg:ts-bg-white lg:ts-px-dfds-s lg:ts-shadow-xl lg:ts-drop-shadow-2xl 2xl:ts-right-[-75%] tabletDown:ts-fixed tabletDown:ts-bottom-0 tabletDown:ts-left-0 tabletDown:ts-right-0 tabletDown:ts-top-0 tabletDown:ts-w-full ${
          !isOpen && 'ts-invisible'
        }
        ${isFrsRoute ? 'lg:ts-left-[-258px]' : 'lg:ts-left-auto lg:ts-right-0'}
        ${getFieldBorderStyles(isOpen)}
        `}
      >
        <div className="ts-flex ts-h-full ts-flex-col">
          <div id="header-content" className="ts-flex ts-h-[48px] ts-min-h-[48px] ts-flex-row ts-items-center ts-pl-dfds-s lg:ts-hidden">
            <Typograhy className="ts-max-w-[350px] ts-text-dfds-text-primary-primary" as="smallHeadline">
              {t('SELECT-DATE')}
            </Typograhy>
            <div
              className={`ts-fill-bg-500 ts-ml-auto ts-flex ts-h-full ts-w-12 ${
                isLoading || disabled ? 'ts-cursor-not-allowed' : 'ts-cursor-pointer'
              } ts-items-center ts-justify-center hover:ts-text-dfds-secondary-main`}
              onClick={() => {
                if (!disabled) {
                  onClick(true)
                }
              }}
            >
              <Close width="25" height="25" />
            </div>
          </div>
          <div className="ts-border-b ts-border-solid ts-border-gray-300 lg:ts-hidden"></div>
          <div
            id="main-content"
            className="lg:ts-min-h-[403px] lg:ts-bg-white tabletDown:ts-h-[calc(100%-128px)] tabletDown:ts-overflow-y-scroll"
          >
            {children}
          </div>
          {selectedOutboundDate && (
            <OverlayDateFooter
              isLoading={isLoading}
              isOneway={isOneway}
              outboundDate={selectedOutboundDate}
              homeboundDate={selectedHomeboundDate}
              onClick={() => onClick(false)}
            >
              <div className="ts-flex ts-flex-row ts-items-center"></div>
            </OverlayDateFooter>
          )}
        </div>
      </div>
    </div>
  )
}

export default DateField

interface ComponentProps {
  dataAttrName: string
  dataAttrValue: string
}
