import { Category, Route } from '../../Hooks'
import Typograhy from '../Shared/Typograhy'

interface Props {
  routeCategories: Category[] | undefined
  onSelectRoute: (route: Route) => void
}

const Routes: React.FC<Props> = ({ routeCategories, onSelectRoute }) => {
  return (
    <div className="ts-bg-dfds-surface-light lg:ts-w-[calc(100vw_-_144px)] lg:ts-p-dfds-s xl:ts-w-[calc(100vw_-_33px)] xl:ts-max-w-[1137px] ">
      <ul className="ts-border-0 ts-border-solid ts-p-0 ts-m-0 lg:ts-columns-4 mobile:ts-columns-1 tablet:ts-columns-3">
        {routeCategories &&
          routeCategories.map((routeCategory) => (
            <div key={routeCategory?.name} data-e2e="route-category" className="ts-break-inside-avoid">
              <div className="ts-flex ts-h-[70px] ts-items-center ts-border-b ts-border-solid ts-border-dfds-divider-dark ts-border-b-dfds-divider-dark ts-pl-dfds-s md:ts-border-none lg:ts-h-[50px]">
                <Typograhy className="ts-mt-dfds-s ts-font-DFDSBold ts-text-dfds-text-dark-primary lg:ts-mb-0 lg:ts-ml-0" as="label">
                  {routeCategory?.name}
                </Typograhy>
              </div>
              <ul className='ts-p-0 ts-m-0'>
                {routeCategory.routes.map((route) => (
                  <li
                    onClick={() => onSelectRoute(route)}
                    className="ts-border-0 ts-border-solid ts-m-0 ts-list-none ts-p-0 ts-mb-0 ts-ml-0 ts-flex ts-h-[50px] ts-cursor-pointer ts-items-center ts-border-b-[1px] ts-border-dfds-divider-dark ts-pl-dfds-s md:ts-border-none lg:ts-h-[40px]"
                    key={route.routeCode}
                  >
                    <div data-e2e={route.routeCode} data-e2e-name={"route-names"}>
                      <Typograhy className="ts-text-dfds-secondary-main" as="body">
                        {route.name}
                      </Typograhy>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ))}
      </ul>
    </div>
  )
}

export default Routes
