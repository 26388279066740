'use client'

import 'react-day-picker/dist/style.css'

import { DayPicker } from 'react-day-picker'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { DatesData, focusElement, useWindowWidth } from './utils'
import { Locale, differenceInMonths, startOfMonth } from 'date-fns'
import DatePickerLegend from './DatePickerLegend'
import { useTranslation } from 'react-i18next'
import clsx from 'clsx'

interface Props {
  locale: Locale | undefined
  selectedDate: Date | undefined
  showSingleMonth?: boolean
  newStyles?: boolean
  travelDates?: DatesData
  onDateChanged: (value: Date | undefined) => void
}

export const DatePicker: React.FC<Props> = ({ newStyles = false, locale, showSingleMonth, selectedDate, travelDates, onDateChanged }) => {
  const { isMobile, isTablet } = useWindowWidth()
  const [numberOfMonth, setNumberOfMonth] = useState<number>(0)
  const [moreMonthsToLoad, setMoreMonthsToLoad] = useState<boolean>(true)
  const { ref, inView } = useInView({
    threshold: 0,
  })

  const { t } = useTranslation()

  useEffect(() => {
    setNumberOfMonth(isMobile || isTablet ? 6 : showSingleMonth ? 1 : 2)
  }, [isMobile, isTablet])

  useEffect(() => {
    if (travelDates) {
      const difference = differenceInMonths(
        startOfMonth(new Date(travelDates.dates.toDate)),
        startOfMonth(new Date(travelDates.dates.fromDate))
      )
      // Increase number of month, but no more than 1 year and 4 months
      if (inView && (isMobile || isTablet) && numberOfMonth < difference + 2) {
        setNumberOfMonth(numberOfMonth + 2)
      } else {
        if (!(numberOfMonth < difference + 2)) setMoreMonthsToLoad(false)
      }
    }
  }, [inView])

  useEffect(() => {
    if (isMobile || isTablet) {
      focusElement('.rdp-day_selected')
    }
  }, [])

  useEffect(() => {
    if (isMobile || isTablet) {
      // Find id to focus
      const element: HTMLElement | null = document.querySelector('.rdp-day_selected')
      if (element) {
        element.focus()
      }
    }
  }, [])

  const disabledDays = travelDates?.disabledDates.map((date: any) => new Date(date))
  const offerDates = travelDates?.offerDates?.map((x) => new Date(x)) as any

  return (
    <div className="tabletDown:ui-flex-col ui-z-10 ui-flex ui-bg-white">
      {travelDates && (
        <>
          <DayPicker
            locale={locale}
            className={clsx('rdp-single', newStyles ? 'rdp-new' : 'rdp-old')}
            fromDate={new Date(travelDates.dates.fromDate)}
            toDate={new Date(travelDates.dates.toDate)}
            defaultMonth={(!isMobile && selectedDate) || new Date()}
            weekStartsOn={1}
            mode="single"
            numberOfMonths={numberOfMonth}
            disabled={disabledDays}
            modifiers={{ offers: offerDates ? offerDates : false }}
            modifiersClassNames={{
              offers: 'rdp-offer',
            }}
            showOutsideDays={false}
            selected={selectedDate && new Date(selectedDate)}
            onSelect={(value) => onDateChanged(value)}
            fixedWeeks={true}
            showWeekNumber={true}
            styles={{
              caption_start: { paddingTop: travelDates?.offerText && isMobile ? '49px' : '0' },
              months: { paddingTop: travelDates?.offerText && !isMobile ? '48px' : '0' },
            }}
          />
          <DatePickerLegend offerText={travelDates?.offerText} />
        </>
      )}
      {/* Load more month when this div is hit when scrolling */}
      {(isMobile || isTablet) && (
        <div className="ui-dfds-label ui-mt-dfds-s ui-mb-dfds-l ui-flex ui-items-center ui-justify-center" ref={ref}>
          {moreMonthsToLoad ? t('SHOW-MORE-MONTHS') : t('NO-MORE-MONTHS-WITH-CAPACITY')}
        </div>
      )}
    </div>
  )
}
