'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const Car = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="1em" height="1em" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M23.4 17.14a2.5 2.5 0 11-.02 4.99 2.5 2.5 0 01.01-4.99zm-13.5 0a2.5 2.5 0 11-.01 4.99 2.5 2.5 0 010-4.99zm13.5 1.18a1.3 1.3 0 100 2.61 1.3 1.3 0 000-2.61zm-13.5 0a1.3 1.3 0 100 2.61 1.3 1.3 0 000-2.61zM14.18 9c5.74 0 7.4 2.1 9.96 4.9 4.79-.04 5.83 3.84 5.86 6.05l-3.2.04v-.36a3.4 3.4 0 10-6.78 0V20h-6.73v-.37a3.4 3.4 0 10-6.77.36H3.77c-3.31 0-1.2-5.26.1-6.45C7.1 10.8 10.33 9 14.17 9zm.8 1.14l-1.34.03c0 .33-.1 3.73.23 3.73v-.02s7.15 0 9.38.02c-2.17-2.42-4.42-3.76-8.26-3.76zm-2.36.12c-3.64.36-5.54 3.64-4.4 3.64h4.52v-3.6c0-.04-.04-.04-.12-.04z"
    />
  </svg>
))
export default Car
