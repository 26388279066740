'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const Edit = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M8.226 18.488l-3.899 1.324a.2.2 0 01-.254-.254l1.324-3.899 2.828 2.828zm10.04-10.041L8.943 17.77l-2.828-2.828 9.323-9.323 2.828 2.828zm2.727-3.01a.2.2 0 010 .283l-2.01 2.01-2.828-2.828 2.01-2.01a.2.2 0 01.283 0l2.546 2.546z"
    />
  </svg>
))

export default Edit
