'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const MotorHome = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 32 32" ref={svgRef} {...props}>
    <path
      fill="currentColor"
      d="M7.763 21.21a1.218 1.218 0 100-2.435 1.218 1.218 0 000 2.435zm0-3.558a2.34 2.34 0 110 4.68 2.34 2.34 0 010-4.68zm17.175 0a2.34 2.34 0 110 4.68 2.34 2.34 0 010-4.68zm0 3.558a1.218 1.218 0 100-2.435 1.218 1.218 0 000 2.435zm5.743-6.461c.182.04.319.203.319.396v4.289a.406.406 0 01-.405.405l-2.661-.061a2.985 2.985 0 00-5.957.04l-11.244.006c0-1.48-1.418-2.825-2.95-2.825a2.985 2.985 0 00-2.964 3.336l-3.817-.166V7H26.43c.326 0 .612.174.769.435l.104.195.578.942a.898.898 0 01-.778 1.343h-5.689c-.168.043-.397.154-.397.431 0 .437.038.818.109 1.287s.298.961 1.189 1.172c.35.083 8.369 1.945 8.369 1.945zm-12.147 3.686v-8.296a.225.225 0 00-.224-.224h-3.498a.225.225 0 00-.224.224v8.296c0 .123.101.224.224.224h3.498a.225.225 0 00.224-.224zm-6.099-4.689v-3.717a.116.116 0 00-.115-.115H3.626a.116.116 0 00-.115.115v3.717c0 .063.052.115.115.115h8.694a.116.116 0 00.115-.115z"
    />
  </svg>
))
export default MotorHome
