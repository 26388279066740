'use client'

/* THIS IS AN AUTOGENERATED FILE. DO NOT EDIT THIS FILE DIRECTLY. */
import { forwardRef, SVGProps, Ref } from 'react'
const EditIcon = forwardRef((props: SVGProps<SVGSVGElement>, svgRef: Ref<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="1em" height="1em" ref={svgRef} {...props}>
    <circle cx="12" cy="12" r="10" fill="#EEF0F1" />
    <path
      d="M8.21973 13H5.66309V10.3135H8.21973V13ZM13.2783 13H10.7217V10.3135H13.2783V13ZM18.3369 13H15.7803V10.3135H18.3369V13Z"
      fill="#4D4E4C"
      fillOpacity="0.7"
    />
  </svg>
))

export default EditIcon
