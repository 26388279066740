import { useState, useEffect } from 'react'

const getWindowWidth = () => {
  const { innerWidth: width } = window
  return {
    width,
  }
}

export const useWindowWidth = (): {
  widthSmallerThan1280: boolean
  widthSmallerThan1025: boolean
  widthSmallerThan768: boolean
  widthSmallerThan500: boolean
  widthLargerThan1200: boolean
} => {
  const [widthSmallerThan500, setWidthSmallerThan500] = useState<boolean>(getWindowWidth().width < 500)
  const [widthSmallerThan768, setWidthSmallerThan768] = useState<boolean>(getWindowWidth().width < 768)
  const [widthSmallerThan1025, setWidthSmallerThan1025] = useState<boolean>(getWindowWidth().width < 1025)
  const [widthSmallerThan1280, setWidthSmallerThan1280] = useState<boolean>(getWindowWidth().width < 1280)
  const [widthLargerThan1200, setWidthLargerThan1200] = useState<boolean>(getWindowWidth().width > 1200)

  useEffect(() => {
    const handleResize = () => {
      setWidthSmallerThan500(getWindowWidth().width < 500)
      setWidthSmallerThan768(getWindowWidth().width < 768)
      setWidthSmallerThan1025(getWindowWidth().width < 1025)
      setWidthSmallerThan1280(getWindowWidth().width < 1280)
      setWidthLargerThan1200(getWindowWidth().width > 1200)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return { widthSmallerThan500, widthSmallerThan768, widthSmallerThan1025: widthSmallerThan1025, widthSmallerThan1280, widthLargerThan1200 }
}
